import { useEffect, useRef } from "react";
import "../styles/description.css";
import eggGloabl from "../assets/images/frmIcon.svg";
import ecommerse from "../assets/images/eCommerceB.svg";
import howitwork from "../assets/images/howitwork.svg";
import onlineGaming from "../assets/images/onlineGaming1.svg";
import financialService from "../assets/images/financialService.svg";
import frm from "../assets/images/frm.svg";
import realTimeFraud from "../assets/images/realTimeFraud.svg";
import regulatory from "../assets/images/realTimeFraud.svg";
import multiChannel from "../assets/images/multiChannel.svg";
import caseManagment from "../assets/images/caseManagment.svg";
import { Helmet } from "react-helmet";

export const FraudRiskManagement = () => {
  return (
  
    <><Helmet> <meta charSet="utf-8" />
      <title>Fraud Risk Management Solutions That Make a Difference | Fstac</title>
      <meta  name="description" 
      content=" Fstac offers fraud detection and prevention solutions that can help your business comprehensively. Talk to us today!" />
      <link rel="canonical" href="https://www.fstac.tech/fraud-risk-management" /></Helmet>
      <div className="singlePage">
        <div className="container ">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-12 d-flex justify-content-center">
              <div className="container1">
                <div className="outer-circle">
                  <div className="inner-circle">
                    <div className="content">
                      <div>
                        <button>FRAUD WATCH</button>
                      </div>
                      <div className="contentHeading circle_header">
                        Fraud Risk Management
                      </div>
                      <div className="description innerCircleContent">
                        <p>
                          In the era of rising digital threats, safeguarding your
                          platform from fraudulent activities is more critical
                          than ever. Fstac’s Fraud Risk Management solution
                          empowers businesses to identify and mitigate fraud in
                          real time. Our solution leverages data intelligence and
                          behavioral analysis to deliver actionable insights,
                          helping to detect suspicious activities and reduce
                          financial losses.
                        </p>
                      </div>
                    </div>
                    <div className="outerCircleIcon1">
                      <img src={eggGloabl} alt="" />
                    </div>
                    <div className="outerCircleIcon2">
                      <img src={eggGloabl} alt="" />
                    </div>
                  </div>
                  <div className="outerCircleIcon">
                    <img src={eggGloabl} alt="" />
                  </div>
                </div>

                <div className="outerBox"></div>
                <div className="content_mobileView">
                  <div>
                    <button>FRAUD WATCH</button>
                  </div>
                  <div className="contentHeading circle_header">
                    Fraud Risk Management
                  </div>
                  <div className="description innerCircleContent">
                    <p>
                      In the era of rising digital threats, safeguarding your
                      platform from fraudulent activities is more critical than
                      ever. Fstac’s Fraud Risk Management solution empowers
                      businesses to identify and mitigate fraud in real time. Our
                      solution leverages data intelligence and behavioral analysis
                      to deliver actionable insights, helping to detect suspicious
                      activities and reduce financial losses.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row">
  <div className="col-lg-12">
    <div className="NewBox">
      <p>hello</p>
    </div>
  </div>
</div> */}
        </div>
        {/* <div className="container seller_box">
    <div className="row justify-content-center align-items-center">
      <div className="col-lg-10">
        <div className="seller_heading">
          More than 100+ sellers who trust Fstac
        </div>
      </div>
      <div className="col-lg-10 pt-5">
        <div className="sellerImage d-flex justify-content-between align-items-center">
          <div>
            <img className="whoWeTrustImage" src={sysdin} alt="" />
          </div>
          <div>
            <img className="whoWeTrustImage" src={browdstric} alt="" />
          </div>
          <div>
            <img className="whoWeTrustImage" src={aws} alt="" />
          </div>
          <div>
            <img className="whoWeTrustImage" src={dataLogo} alt="" />
          </div>
          <div>
            <img className="whoWeTrustImage" src={elastic} alt="" />
          </div>
        </div>
      </div>
    </div>
  </div> */}
        <div className="container howItWork" style={{ position: "relative", marginTop:"10px" }}>
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-10 d-flex  justify-content-between howitworkContent_">
              <div className="box-content" style={{ width: "45%" }}>
                <div className="contentHowitWork">
                  <div className="itwork">How It Works</div>
                  <div className="howboldHeading">
                    Powerful Fraud Detection & Prevention
                  </div>
                  <div>
                    <p>
                      Get comprehensive insights into user behavior, detect
                      anomalies in real time, and protect your platform with
                      automated threat monitoring
                    </p>
                  </div>
                </div>
              </div>
              <div className="box-image" style={{ width: "45%" }}>
                <div className="howitworkImage">
                  <img src={frm} alt="" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center align-items-center pt-4">
            <div className="col-lg-10 d-flex  justify-content-between subBoxContentRow">
              <div className="subBoxContent" style={{ width: "45%" }}>
                <div className="howDescriptio">
                  <div className="headingHow1">Real-Time Fraud Insights</div>
                  <div>
                    <p>
                      Get instant insights into suspicious user activities,
                      enabling rapid response to threats and enhanced platform
                      security.
                    </p>
                  </div>
                </div>
              </div>
              <div className="subBoxContent" style={{ width: "45%" }}>
                <div className="howDescriptio">
                  <div className="headingHow1">Advanced Data Analysis</div>
                  <div>
                    <p>
                      Our solution continuously scans global IP data and user
                      behaviors to identify abnormal patterns that might indicate
                      potential fraud attempts.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-10 d-flex justify-content-between subBoxContentRow">
              <div className="subBoxContent" style={{ width: "45%" }}>
                <div className="howDescriptio">
                  <div className="headingHow1">Risk-Based Decision Making</div>
                  <div>
                    <p>
                      Using AI-driven behavioral analysis, it identifies sudden
                      changes in user behavior, ensuring proactive fraud detection
                      before any damage occurs.
                    </p>
                  </div>
                </div>
              </div>
              <div className="subBoxContent" style={{ width: "45%" }}>
                <div className="howDescriptio">
                  <div className="headingHow1">
                    Seamless Integration & Automated Protection
                  </div>
                  <div>
                    <p>
                      Integrates effortlessly with existing systems and provides
                      continuous, automated monitoring to safeguard your business
                      operations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-5" id="smartSignal">
          <div className="row justify-content-center align-items-center pb-4">
            <div className="col-lg-10">
              <div className="signalHeading text-center">
                Common Financial Fraud Types
              </div>
              <div>
                <p className="text-center" style={{ fontSize: "20px" }}>
                  Let's understand the various tactics employed by fraudsters to
                  exploit vulnerabilities and compromise financial security.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center align-items-center pt-4">
            <div className="col-lg-10 d-flex  justify-content-between smartSignalRaw">
              <div className="singnalBox" style={{ width: "45%" }}>
                <div className="SingalDescriptio">
                  <div className="signalHeadingBox">Lost or Stolen Card</div>
                  <div>
                    <p>
                      When someone finds or steals your card and uses it for
                      unauthorized purchases, leaving you with the bill.
                    </p>
                  </div>
                </div>
              </div>
              <div className="singnalBox" style={{ width: "45%" }}>
                <div className="SingalDescriptio">
                  <div className="signalHeadingBox">
                    Card-Not-Present (eCommerce)
                  </div>
                  <div>
                    <p>
                      Fraudsters use stolen card details to shop online, by phone,
                      or mail—no physical card needed!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-10 d-flex justify-content-between smartSignalRaw ">
              <div className="singnalBox" style={{ width: "45%" }}>
                <div className="SingalDescriptio">
                  <div className="signalHeadingBox">Counterfeiting</div>
                  <div>
                    <p>
                      Scammers clone your card’s magnetic stripe to create a fake
                      one, making charges without your consent.
                    </p>
                  </div>
                </div>
              </div>
              <div className="singnalBox" style={{ width: "45%" }}>
                <div className="SingalDescriptio">
                  <div className="signalHeadingBox">Identity Theft</div>
                  <div>
                    <p>
                      Thieves steal personal information, like your SSN or account
                      details, to drain your funds or commit crimes in your name.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-10 d-flex justify-content-between smartSignalRaw ">
              <div className="singnalBox" style={{ width: "100%" }}>
                <div className="SingalDescriptio">
                  <div className="signalHeadingBox">Card Not Received</div>
                  <div>
                    <p>
                      Your card gets intercepted before it even reaches you, and
                      fraudsters start spending before you know it’s gone!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="useCaseStudy container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-10">
              <div className="useCaseHeadig">
                <h2 className="text-center">Why FRM?</h2>{" "}
              </div>
            </div>
          </div>
          <div className="row justify-content-center align-items-center pt-5">
            <div className="col-lg-10 pt-5">
              <div className="useCaseStudyPoint d-flex">
                <div className="img">
                  <img src={realTimeFraud} alt="" />
                </div>
                <div className="useBoxDescription">
                  <div className="useBoxHeading">
                    <h6>Real-Time Fraud Detection</h6>
                  </div>
                  <div>
                    <p style={{ paddingTop: "0px" }}>
                      Detects and blocks potentially fraudulent transactions
                      instantly, using advanced machine learning models for
                      immediate protection.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-10 pt-5">
              <div className="useCaseStudyPoint1 d-flex">
                <div className="img">
                  <img src={multiChannel} alt="" />
                </div>
                <div className="useBoxDescription">
                  <div className="useBoxHeading">
                    <h6>Multi-Channel Security</h6>
                  </div>
                  <div>
                    <p style={{ paddingTop: "0px" }}>
                      Ensures secure payments across mobile, ATM, POS, and online
                      channels, offering seamless fraud prevention on all
                      transaction points.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-10 pt-5">
              <div className="useCaseStudyPoint d-flex">
                <div className="img">
                  <img src={regulatory} alt="" />
                </div>
                <div className="useBoxDescription">
                  <div className="useBoxHeading">
                    <h6>Regulatory Compliance</h6>
                  </div>
                  <div>
                    <p style={{ paddingTop: "0px" }}>
                      Adapts to industry regulations and ensures compliance with
                      security standards, reducing risk of non-compliance
                      penalties.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-10 pt-5">
              <div className="useCaseStudyPoint1 d-flex">
                <div className="img">
                  <img src={caseManagment} alt="" />
                </div>
                <div className="useBoxDescription">
                  <div className="useBoxHeading">
                    <h6>Case Management</h6>
                  </div>
                  <div>
                    <p style={{ paddingTop: "0px" }}>
                      Offers an integrated case management tool for quick,
                      effective investigation and resolution of fraud incidents.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
  );
};
