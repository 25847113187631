import "../styles/footer.css";
import logo from "../assets/images/logo.svg";
import linkedin_test from "../assets/images/in.svg";
import linkedinwhite from "../assets/images/linkedinwhite.svg";
import instagram from "../assets/images/instagram.svg";
import twitter from "../assets/images/twitter.svg"; // Add Twitter icon
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer>
      <div id="footer">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-2">
              <div
                className="footerLogo"
                onClick={() => {
                  navigate("/");
                }}
              >
                <img src={logo} alt="Fstac Logo" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="listOfFooterItem">
                <ul>
                  <li
                    onClick={() => {
                      navigate("/device-intelligence");
                    }}
                  >
                    Products
                  </li>
                  <li
                    onClick={() => {
                      navigate("/about-us");
                    }}
                  >
                    Company
                  </li>
                  <li
                    onClick={() => {
                      navigate("/privacyPolicy");
                    }}
                  >
                    Privacy Policy
                  </li>
                  <li
                    onClick={() => {
                      navigate("/termAndCondition");
                    }}
                  >
                    Terms & Conditions
                  </li>
                  <li
                    onClick={() => {
                      window.open(
                        "https://www.google.com/maps/place/Fstac/@12.9792043,77.6411487,17z/data=!4m14!1m7!3m6!1s0x3bae17000d7eb187:0x7be0754b52f1d5c2!2sFstac!8m2!3d12.9792043!4d77.6437236!16s%2Fg%2F11vzy4yxyf!3m5!1s0x3bae17000d7eb187:0x7be0754b52f1d5c2!8m2!3d12.9792043!4d77.6437236!16s%2Fg%2F11vzy4yxyf?entry=ttu&g_ep=EgoyMDI1MDEwOC4wIKXMDSoASAFQAw%3D%3D",
                        "_blank"
                      );
                    }}
                  >
                    Location
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="socialMedia">
                <a
                  href="https://www.linkedin.com/company/fstac/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {/* <img
                    src={linkedin_test} 
                    alt="LinkedIn"
                    className="linkedin-image"
                  />
                  <img
                    src={linkedinwhite} 
                    alt="LinkedIn Hover"
                    className="linkedin-image-hover"
                  /> */}
                  <img src={linkedinwhite} alt="linkedin" />
                </a>
                <a
                  href="https://www.instagram.com/fstac._/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-icon"
                >
                  <img src={instagram} alt="Instagram" />
                </a>
                <a
                  href="https://x.com/Fstac2023" // Update to Twitter
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-icon"
                >
                  <img src={twitter} alt="Twitter" /> 
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
