import { useEffect, useRef } from "react";
import "../styles/description.css";
import eggGloabl from "../assets/images/eggGlobal.png";
import globalIp from "../assets/images/globalIp.svg";

import ipMainImage from "../assets/images/ipIntelligenceMain.svg";
import fraud from "../assets/images/fraudDetection.svg";
import cdn from "../assets/images/contentDelivery.svg";
import cyber from "../assets/images/cyberSecurity.svg";
import complience from "../assets/images/complience.svg";
import ip_image from "../assets/images/ip_image.svg";
import { Helmet } from "react-helmet";
// import { Helmet } from "react-helmet";

export const IpIntelligence = () => {
  return (
    <><Helmet> <meta charSet="utf-8" />
      <title>The Best IP Intelligence Software You Can Get | Fstac</title>
      <meta
      name="description" 
       content="Get access to the best ip intelligence and geolocation softwares and offer improved and more secure online experiences to your users." />
      <link rel="canonical" href="https://www.fstac.tech/ip-intelligence" /></Helmet>
      <div className="singlePage">
        <div className="container ">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-12 d-flex justify-content-center">
              <div className="container1">
                <div className="outer-circle">
                  <div className="inner-circle">
                    <div className="content">
                      <div>
                        <button>FRAUD WATCH</button>
                      </div>
                      <div className="contentHeading circle_header">
                        IP Intelligence
                      </div>
                      <div className="description innerCircleContent">
                        <p>
                          In today's digital era, understanding web traffic's
                          origins and behaviors is paramount for security and
                          operational success. Fstac's IP Intelligence solution
                          delivers deep, actionable insights into the IP addresses
                          accessing your platform, enabling you to detect fraud,
                          enhance user experiences, and optimize business
                          operations. Our solution thoroughly analyzes IP data,
                          powered by advanced technology, providing the
                          intelligence necessary to outmaneuver threats and make
                          informed strategic decisions.
                        </p>
                      </div>
                    </div>
                    <div className="outerCircleIcon1">
                      <img src={globalIp} alt="" />
                    </div>
                    <div className="outerCircleIcon2">
                      <img src={globalIp} alt="" />
                    </div>
                  </div>
                  <div className="outerCircleIcon">
                    <img src={globalIp} alt="" />
                  </div>
                </div>
                <div className="outerBox"></div>
                <div className="content_mobileView">
                  <div>
                    <button>FRAUD WATCH</button>
                  </div>
                  <div className="contentHeading circle_header">
                    IP Intelligence
                  </div>
                  <div className="description innerCircleContent">
                    <p>
                      In today's digital era, understanding web traffic's origins
                      and behaviors is paramount for security and operational
                      success. Fstac's IP Intelligence solution delivers deep,
                      actionable insights into the IP addresses accessing your
                      platform, enabling you to detect fraud, enhance user
                      experiences, and optimize business operations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container howItWork" style={{ position: "relative", marginTop:"10px" }}>
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-10 d-flex  justify-content-between howitworkContent_">
              <div className="box-content" style={{ width: "45%" }}>
                <div className="contentHowitWork">
                  <div className="itwork">How It Works</div>
                  <div className="howboldHeading">
                    Real-Time IP
                    <br /> Insights
                  </div>
                  <div>
                    <p>
                      Fstac’s IP Intelligence provides real-time insights into IP
                      data, detecting threats and identifying suspicious patterns
                      to protect your platform from potential fraud.
                    </p>
                  </div>
                </div>
              </div>
              <div className="box-image" style={{ width: "45%" }}>
                <div className="howitworkImage">
                  <img src={ipMainImage} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center align-items-center pt-4">
            <div className="col-lg-10 d-flex  justify-content-between subBoxContentRow">
              <div className="subBoxContent" style={{ width: "45%" }}>
                <div className="howDescriptio">
                  <div className="headingHow1">
                    Data Collection & Global Analysis
                  </div>
                  <div>
                    <p>
                      Fstac’s IP Intelligence collects IP data globally, including
                      geolocation and ISP details, and analyzes it in real-time to
                      detect threats.
                    </p>
                  </div>
                </div>
              </div>
              <div className="subBoxContent" style={{ width: "45%" }}>
                <div className="howDescriptio">
                  <div className="headingHow1">
                    Behavioral Pattern Recognition
                  </div>
                  <div>
                    <p>
                      Fstac goes beyond basic IP data, analyzing user behavior to
                      spot unusual patterns like sudden location changes or rapid
                      IP switching. This helps prevent fraud before it even
                      happens.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-10 d-flex justify-content-between subBoxContentRow">
              <div className="subBoxContent" style={{ width: "45%" }}>
                <div className="howDescriptio">
                  <div className="headingHow1">
                    Seamless Integration & Instant Deployment
                  </div>
                  <div>
                    <p>
                      Our real-time IP intelligence is built for easy integration
                      with your systems. It helps you strengthen security
                      instantly without any disruptions to your business flow.
                    </p>
                  </div>
                </div>
              </div>
              <div className="subBoxContent" style={{ width: "45%" }}>
                <div className="howDescriptio">
                  <div className="headingHow1">
                    Continuous Learning & Adaptive Security
                  </div>
                  <div>
                    <p>
                      It adapts to new data and threats, keeping your platform
                      secure as fraud tactics evolve. It minimizes false positives
                      and boosts overall security.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-5 mb-5" id="smartSignal">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-10 d-flex  justify-content-between howitworkContent_1">
              <div className="box-content" style={{ width: "45%" }}>
                <div className="contentHowitWork">
                  <div className="howboldHeading">
                    Comprehensive IP Detection & Geolocation
                  </div>
                  <div>
                    <p>
                      Fstac's IP Intelligence detects methods used to conceal IP
                      addresses, such as VPNs, proxies, Tor, private relays, or
                      hosting providers that may tunnel traffic and mask true
                      locations. As anonymizing tools become more prevalent,
                      businesses must differentiate between legitimate and
                      potentially malicious users.
                    </p>
                    <p>
                      With precise geolocation insights, Fstac’s IP Intelligence
                      helps businesses strengthen security, customize user
                      experiences, and improve overall operational efficiency—all
                      in one cohesive solution.
                    </p>
                  </div>
                </div>
              </div>
              <div className="box-image ip_image" style={{ width: "45%" }}>
                <div className="howitworkImage1">
                  <img src={ip_image} alt="" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="useCaseStudy container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-10">
              <div className="useCaseHeadig ipIntelligencePadding" style={{ marginTop: "100px" }}>
                <h2 className="text-center">Use Cases by Industry</h2>{" "}
              </div>
            </div>
          </div>
          <div className="row justify-content-center align-items-center pt-5">
            <div className="col-lg-10 pt-5">
              <div className="useCaseStudyPoint d-flex">
                <div className="img">
                  <img src={fraud} alt="" />
                </div>
                <div className="useBoxDescription">
                  <div className="useBoxHeading">
                    <h6>Fraud Prevention</h6>
                  </div>
                  <div>
                    <p style={{ paddingTop: "0px" }}>
                      Detecting connections from VPNs and proxies is a powerful
                      tool in the fight against fraud. Financial institutions,
                      e-commerce sites, and online services can flag potentially
                      fraudulent activities for deeper scrutiny, protecting
                      against unauthorized access and suspicious behavior.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-10 pt-5 ">
              <div className="useCaseStudyPoint1 d-flex">
                <div className="img">
                  <img src={cdn} alt="" />
                </div>
                <div className="useBoxDescription">
                  <div className="useBoxHeading">
                    <h6>Content Delivery</h6>
                  </div>
                  <div>
                    <p style={{ paddingTop: "0px" }}>
                      Content providers and CDNs can optimize their services by
                      accurately detecting user locations, even when they’re
                      masked. This allows for better content localization,
                      improved language targeting, and efficient technical
                      delivery based on true user location.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-10 pt-5">
              <div className="useCaseStudyPoint d-flex">
                <div className="img">
                  <img src={cyber} alt="" />
                </div>
                <div className="useBoxDescription">
                  <div className="useBoxHeading">
                    <h6>Cybersecurity</h6>
                  </div>
                  <div>
                    <p style={{ paddingTop: "0px" }}>
                      Strengthen your threat intelligence by identifying
                      connections associated with VPNs, proxies, or hosting
                      providers. Integrating this data into SIEM systems helps
                      security teams assess risk levels and prioritise alerts,
                      enhancing overall security posture.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-10 pt-5">
              <div className="useCaseStudyPoint1 d-flex">
                <div className="img">
                  <img src={complience} alt="" />
                </div>
                <div className="useBoxDescription">
                  <div className="useBoxHeading">
                    <h6>Compliance</h6>
                  </div>
                  <div>
                    <p style={{ paddingTop: "0px" }}>
                      Maintain regulatory compliance and enforce geographic
                      restrictions with accurate user location detection. Whether
                      it’s for ensuring data access controls or managing service
                      availability, detecting VPNs and proxies helps meet legal
                      requirements and mitigate risks.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
