import React, { useState, FC } from "react";
import "../styles/promoabuse.css";

type ProductName = "Nike Sport" | "Nike Air";

interface ProductQuantities {
  "Nike Sport": number;
  "Nike Air": number;
}

interface ProductPrices {
  "Nike Sport": number;
  "Nike Air": number;
}

const PromoAbuse: FC = () => {
    const [quantities, setQuantities] = useState<ProductQuantities>({
      "Nike Sport": 1,
      "Nike Air": 2,
    });
    const [promoCode, setPromoCode] = useState<string>("");
    const [usedCodes, setUsedCodes] = useState<Set<string>>(new Set());
    const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

  
  
    const prices: ProductPrices = {
      "Nike Sport": 19.99,
      "Nike Air": 39.99,
    };
  
    const productImages: Record<ProductName, string> = {
      "Nike Sport": `https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco/79d2cede-230e-4742-8254-f39d80b9cfe5/GSW+MNK+DF+SWGMN+JSY+HWC+24.png`, // Replace with the correct Nike Sport image URL
      "Nike Air": `https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco/a0694e6b-b6b3-479c-851d-d64af01d1784/MEM+MNK+DF+SWGMN+JSY+HWC+24.png`, // Replace with the correct Nike Air image URL
    };
  
    const handleQuantityChange = (product: ProductName, delta: number): void => {
      setQuantities((prev) => ({
        ...prev,
        [product]: Math.max(0, prev[product] + delta),
      }));
    };
  
    const subtotal: number = Object.entries(quantities).reduce(
      (sum, [product, quantity]) =>
        sum + prices[product as ProductName] * quantity,
      0
    );
    const tax: number = subtotal * 0.1;
    const total: number = subtotal + tax;
  
    const handleApplyPromo = (): void => {
      if (!promoCode) return;
  
      if (usedCodes.has(promoCode)) {
        alert("This code has already been used!");
      } else {
        const newUsedCodes = new Set(usedCodes);
        newUsedCodes.add(promoCode);
        setUsedCodes(newUsedCodes);
        alert("Promo code applied successfully!");
        setPromoCode(""); // Clear the promo code field
      }
    };
  
    const handleRefresh = (): void => {
      setIsRefreshing(true);
      setTimeout(() => {
        setQuantities({
          "Nike Sport": 1,
          "Nike Air": 2,
        });
        setPromoCode("");
        setUsedCodes(new Set());
        setIsRefreshing(false);
      }, 1000); // Simulate a refresh delay
    };
  
    return (
      <div className="pro-container">
        <div className="fingerprint-container">
    <div className="fingerprint-ID">
      fingerprintId : k5pL1JnOOCo8THVwXDZIi0yOL7QU0C4dHwdWn6Rz1EA=
    </div>
  </div>
        <div className="section1">
          <div className="header">
            <div>
              <h1 className="H-title">Promo Abuse</h1>
              <p className="subtitle">
                Use the demo below to see how we help identify fraudsters engaging
                in promo abuse, using different tactics and multiple accounts to
                exploit promotions and gain unauthorized benefits.
              </p>
            </div>
            <button
              className="refresh-btn"
              onClick={handleRefresh}
              type="button"
              disabled={isRefreshing}
            >
              {isRefreshing ? (
                <div className="spinner"></div> // Spinner element
              ) : (
                "Refresh"
              )}
            </button>
          </div>
  
          <div className="instructions">
            <h2>How to use this demo</h2>
            <ul>
              <li>Apply the sample code FIRST1000 and click Apply.</li>
              <li>Try using FIRST1000 again.</li>
              <li>Now use a different sample code, BigBillion, for the same item.</li>
              <li>
                You can reset the scenario by clicking the Restart button at the
                top right.
              </li>
            </ul>
          </div>
  
          <div className="product-list">
            {(Object.entries(quantities) as [ProductName, number][]).map(
              ([product, quantity]) => (
                <div key={product} className="product-item">
                  <div className="product-info">
                    <div className="product-image">
                      <img
                        src={productImages[product]}
                        alt={product}
                      />
                    </div>
                    <div>
                      <h3>{product}</h3>
                      <p>Price: £{prices[product].toFixed(2)}</p>
                    </div>
                  </div>
                  <div className="quantity-controls">
                    <button
                      className="quantity-btn"
                      onClick={() => handleQuantityChange(product, -1)}
                      type="button"
                      aria-label="Decrease quantity"
                    >
                      -
                    </button>
                    <span>{quantity}</span>
                    <button
                      className="quantity-btn"
                      onClick={() => handleQuantityChange(product, 1)}
                      type="button"
                      aria-label="Increase quantity"
                    >
                      +
                    </button>
                  </div>
                </div>
              )
            )}
          </div>
  
          <div className="totals">
            <p>Subtotal: £{subtotal.toFixed(2)}</p>
            <p>Tax (10%): £{tax.toFixed(2)}</p>
            <p className="total">Total: £{total.toFixed(2)}</p>
          </div>
        </div>
        <div className="section1">
          <div className="promo-section">
            <h3 className="promo-title">
              Do you have a coupon? Apply to get a discount!
            </h3>
            <div className="promo-form">
              <input
                type="text"
                className="promo-input"
                placeholder="Enter promo code"
                value={promoCode}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setPromoCode(e.target.value)
                }
                aria-label="Promo code input"
              />
              <button
                className="apply-btn"
                onClick={handleApplyPromo}
                type="button"
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default PromoAbuse;
  