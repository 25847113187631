// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.promo-section {
    background-color: #f9f9f9;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 20px;
    margin: 20px 0;
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  .promo-title {
    font-size: 18px;
    color: #333;
    margin-bottom: 16px;
  }
  
  .country-flag {
    font-weight: bold;
    color: #000;
  }
  
  .activate-btn {
    background-color: #f1f5f3;
    color: #228c22;
    border: 1px solid #228c22;
    border-radius: 5px;
    padding: 12px 20px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .activate-btn:hover {
    background-color: #d8f5da;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/vpnDetection.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,cAAc;IACd,kBAAkB;IAClB,8BAA8B;EAChC;;EAEA;IACE,eAAe;IACf,WAAW;IACX,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,WAAW;EACb;;EAEA;IACE,yBAAyB;IACzB,cAAc;IACd,yBAAyB;IACzB,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,iBAAiB;IACjB,eAAe;IACf,sCAAsC;EACxC;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".promo-section {\n    background-color: #f9f9f9;\n    border: 1px solid #e0e0e0;\n    border-radius: 8px;\n    padding: 20px;\n    margin: 20px 0;\n    text-align: center;\n    font-family: Arial, sans-serif;\n  }\n  \n  .promo-title {\n    font-size: 18px;\n    color: #333;\n    margin-bottom: 16px;\n  }\n  \n  .country-flag {\n    font-weight: bold;\n    color: #000;\n  }\n  \n  .activate-btn {\n    background-color: #f1f5f3;\n    color: #228c22;\n    border: 1px solid #228c22;\n    border-radius: 5px;\n    padding: 12px 20px;\n    font-size: 16px;\n    font-weight: bold;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n  }\n  \n  .activate-btn:hover {\n    background-color: #d8f5da;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
