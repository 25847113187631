import React from "react";

import "../styles/contactus.css";

interface ThankYouModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ThankYouModal: React.FC<ThankYouModalProps> = ({
  isOpen,
  onClose,
}) => {
  if (!isOpen) {
    return null; // Don't render anything if the modal is closed
  }

  return (
    <>
      {/* Modal Backdrop */}
      <div className="modal-backdrop show"></div>

      {/* Modal */}
      <div className="modal show d-block " tabIndex={-1}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              {/* <button type="button" className="close closeX" onClick={onClose}> */}
                {/* <span>&times;</span> */}
              {/* </button> */}
            </div>
            <div className="modal-body">
              <h2 className="modal-title">Thank You!</h2>
              <p>
                Thank you for submitting the form. We will get back to you
                shortly.
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={onClose}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
