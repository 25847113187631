import { useEffect, useRef } from "react";
import "../styles/description.css";
import eggGloabl from "../assets/images/eggGlobal.png";
import seemless from "../assets/images/seemlessIntegration.svg";
import enhanceSec from "../assets/images/enhanceSecurity.svg";
import optimizing from "../assets/images/optimizing.svg";
import simplified from "../assets/images/simplified.svg";
import wideAdoption from "../assets/images/wideAdoption.svg";
import instantInsight from "../assets/images/instantInsight.svg";
import crossDevice from "../assets/images/crossDevice.svg";
import polymorphic from "../assets/images/file_4880396.png";
import llm from "../assets/images/planning_4448900.png";
import token from "../assets/images/poker-chip_1393001.png";
import streamingB from "../assets/images/streamingServiceB.svg";
import travelB from "../assets/images/travelB.svg";
import securePII from "../assets/images/securePII.png";
import fineGrained from "../assets/images/fineGrained.png";
import encryptedAnalitics from "../assets/images/encrypted.png";
import dataresidency from "../assets/images/dataresidency.png";
import { MultipleItems } from "../pages/slider";

export const DataPrivacyVault = () => {
    return (
        <div className="singlePage">
            <div className="container ">
                <div className="row justify-content-center align-items-center">
                    <div className="col-lg-12 d-flex justify-content-center">
                        <div className="container1">
                            <div className="outer-circle">
                                <div className="inner-circle">
                                    <div className="content">
                                        <div>
                                            <button>Data Privacy Vault</button>
                                        </div>
                                        <div className="contentHeading circle_header">
                                            Data Privacy Vault
                                        </div>
                                        <div className="description">
                                            <p>
                                                FSTAC's Data Privacy Vault provides a specialized solution to securely manage and access Personally Identifiable Information (PII). By isolating and safeguarding critical data such as credit scores and PII, it ensures robust protection, even in the event of a system breach. This innovative vault is designed to maintain compliance with global data privacy regulations, offering businesses peace of mind by enhancing privacy protection and minimizing the risks of unauthorized access to sensitive information. Safeguard your data with FSTAC’s cutting-edge security features.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="outerCircleIcon1">
                                        <img src={eggGloabl} alt="" />
                                    </div>
                                    <div className="outerCircleIcon2">
                                        <img src={eggGloabl} alt="" />
                                    </div>
                                </div>
                                <div className="outerCircleIcon">
                                    <img src={eggGloabl} alt="" />

                                </div>
                            </div>
                            <div className="outerBox"></div>
                            <div className="content_mobileView">
                                <div>
                                    <button>Data Privacy Vault</button>
                                </div>
                                <div className="contentHeading circle_header">
                                    Data Privacy Vault
                                </div>
                                <div className="description">
                                    <p>
                                        FSTAC's Data Privacy Vault provides a specialized solution to securely manage and access Personally Identifiable Information (PII). By isolating and safeguarding critical data such as credit scores and PII, it ensures robust protection, even in the event of a system breach. This innovative vault is designed to maintain compliance with global data privacy regulations, offering businesses peace of mind by enhancing privacy protection and minimizing the risks of unauthorized access to sensitive information. Safeguard your data with FSTAC’s cutting-edge security features.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mx-auto mt-16 px-6">
                <div className="text-center mb-12">
                    <h5 className="text-lg font-semibold text-gray-600">Key Features</h5>
                    <h2 className="text-3xl font-bold text-gray-800">Safe, Compliant, Resilient</h2>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {/* Card 1 */}
                    <div className="bg-white rounded-lg shadow-lg p-6 text-center">
                        <img
                            src={securePII}
                            alt="Seamless Integration"
                            className="seemlessimg"
                        />
                        <h6 className="text-lg font-semibold text-gray-800 mb-2">Secure PII Across Your Stack</h6>
                        <p className="text-gray-600">
                            Rest assured, your PII is de-identified, anonymized, and securely stored in a data privacy vault. This industry-leading practice, pioneered by companies like Apple and Netflix, ensures robust protection, enhances privacy, and aligns with global standards for safeguarding sensitive information.
                        </p>
                    </div>

                    {/* Card 2 */}
                    <div className="bg-white rounded-lg shadow-lg p-6 text-center">
                        <img
                            src={fineGrained}
                            alt="Enhanced Security"
                            className="seemlessimg"
                        />
                        <h6 className="text-lg font-semibold text-gray-800 mb-2">Fine-grained Access Control</h6>
                        <p className="text-gray-600">
                            Provide any team with the PII they need to serve customers without breaking data privacy. With FSTAC, you can grant column- and row-level access in privacy-safe formats, restrict access by IP address, and more. Failure to adhere to access control policies can result in significant penalties, including fines up to ₹250 Crore.

                        </p>
                    </div>

                    {/* Card 3 */}
                    <div className="bg-white rounded-lg shadow-lg p-6 text-center">
                        <img
                            src={encryptedAnalitics}
                            alt="Instant Insights"
                            className="encryptedAnalitics"
                        />
                        <h6 className="text-lg font-semibold text-gray-800 mb-2">Always Encrypted Analytics</h6>
                        <p className="text-gray-600">
                            Whether analyzing customer behavior or processing clinical trial data, FSTAC’s Data Privacy Vault lets you query PII without decryption. Generate masked datasets effortlessly, ensuring privacy protection while accessing the insights you need for informed decision-making, all without compromising sensitive information.
                        </p>
                    </div>

                    {/* Card 4 */}
                    <div className="bg-white rounded-lg shadow-lg p-6 text-center">
                        <img
                            src={dataresidency}
                            alt="Cross Device Experience"
                            className="seemlessimg"
                        />
                        <h6 className="text-lg font-semibold text-gray-800 mb-2">Data Residency Made Easy</h6>
                        <p className="text-gray-600">
                            FSTAC streamlines compliance, enabling your organization to meet evolving data residency standards like DPDP effortlessly. By reducing barriers to entering new markets, it ensures swift adaptation to regulatory changes, empowering your business to operate confidently while safeguarding sensitive data and maintaining global compliance.
                        </p>
                    </div>
                </div>
            </div>


            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-lg-6">
                        <div className="useCases text-center p-4">
                            <h5>Features</h5>
                            <p>
                                The features of a data privacy vault play a critical role in safeguarding sensitive information and ensuring regulatory compliance.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center align-items-center mb-4">
                    <div className="col-lg-10">
                        <div className="industryCards">
                            {/* E-Commerce Card */}
                            <div className="card ecommerceCard">
                                <img src={polymorphic} alt="E-Commerce" className="iconImage" />
                                <h3 className="cardHeading">Polymorphic Data Encryption</h3>
                                <p className="cardDescription">
                                    Our unique approach to data security leverages advanced encryption and tokenization techniques, ensuring unparalleled protection for sensitive information. By combining these methods, we deliver optimal security while maintaining seamless data accessibility for analysis and operations. This approach guarantees fast performance, compliance with stringent regulations, and enhanced usability without compromising data integrity.
                                </p>
                            </div>
                            {/* Banking & Financial Services Card  */}
                            <div className="card bankingCard">
                                <img src={llm} alt="Banking" className="iconImage" />
                                <h3 className="cardHeading">LLM model</h3>
                                <p className="cardDescription">
                                    FSTAC enhances LLM (Large Language Model) operations by integrating privacy-preserving data vaults, ensuring that sensitive information remains secure throughout training and deployment. This advanced solution minimizes exposure risks, supports adherence to regulatory requirements, and enables organizations to leverage LLM capabilities confidently. It safeguards data integrity while promoting secure innovation and scalability.
                                </p>
                            </div>
                            {/* Online Gaming Card  */}
                            <div className="card gamingCard">
                                <img src={token} alt="Online Gaming" className="iconImage" />
                                <h3 className="cardHeading">Tokenization</h3>
                                <p className="cardDescription">
                                    FSTAC's tokenization replaces sensitive data with unique identifiers or "tokens," ensuring that original data remains secure and inaccessible to unauthorized parties. This advanced technique supports compliance with stringent data privacy regulations by minimizing exposure risks. By preserving data usability for analysis and operations, it offers robust protection without compromising functionality.
                                </p>
                            </div>

                            {/* <div className="card streamingCard">
                                <img src={streamingB} alt="Streaming Services" className="iconImage" />
                                <h3 className="cardHeading">Streaming Services</h3>
                                <p className="cardDescription">
                                    Streaming services can use IP and fingerprinting data to identify and restrict unauthorized account sharing across different locations and devices.
                                </p>
                            </div>
                          
                            <div className="card travelCard">
                                <img src={travelB} alt="Travel & Hospitality" className="iconImage" />
                                <h3 className="cardHeading">Travel & Hospitality</h3>
                                <p className="cardDescription">
                                    Travel companies can use these technologies to detect and prevent the abuse of loyalty programs by tracking unusual access patterns across IPs and devices.
                                </p>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};
