import { Link } from "react-router-dom";
// import rightWhiteIcon from "../../assets/images/whiteRightIcon.svg";
import "../styles/sandbox.css";
import { useNavigate } from "react-router-dom";

export const Sandbox = () => {
  const navigate = useNavigate();
  return (
    <div className="location_details" style={{ marginTop: "10px" }}>
      <div className="row gy-3">
        <div className="col-lg-4">
          <div className="finger_location-card cartBox_useCase promo-abuse-card">
            <div
              className="location_card_text-content"
              style={{ textAlign: "center" }}
            >
              <div className="location_card_text-box">
                <div className="location_ttl" style={{ fontSize: "1.2rem" }}>
                  Promo Abuse
                </div>
              </div>
              <div className="location_card_text-box">
                <div className="location_desc usecase-desc">
                  Use the demo below to see how we helps identify fraudsters
                  engaging in promo abuse, using different tactics and multiple
                  accounts to exploit promotions and gain unauthorized benefits.
                </div>
              </div>
              <div className="location_card_text-box">
                <div className="location_ttl">
                  <button
                    className="beny_right-btn1"
                    onClick={() => {
                      navigate("/promoAbuse");
                    }}
                  >
                    <span>Click here for demo</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="finger_location-card cartBox_useCase account-takeover-card">
            <div
              className="location_card_text-content"
              style={{ textAlign: "center" }}
            >
              <div className="location_card_text-box">
                <div className="location_ttl" style={{ fontSize: "1.2rem" }}>
                  Account Takeover
                </div>
              </div>
              <div className="location_card_text-box">
                <div className="location_desc usecase-desc">
                  Use the demo below to see how we safeguards your login page
                  from account takeover attempts, even if credentials are
                  compromised.
                </div>
              </div>
              <div className="location_card_text-box">
                <div className="location_ttl">
                  <button
                    className="beny_right-btn1"
                    onClick={() => {
                      navigate("/accountTakeover");
                    }}
                  >
                    <span>Click here for demo</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="finger_location-card cartBox_useCase vpn-detection-card">
            <div
              className="location_card_text-content"
              style={{ textAlign: "center" }}
            >
              <div className="location_card_text-box">
                <div className="location_ttl" style={{ fontSize: "1.2rem" }}>
                  VPN Detection
                </div>
              </div>
              <div className="location_card_text-box">
                <div className="location_desc usecase-desc">
                  VPN Detection identifies users spoofing their location with
                  VPNs, allowing you to block access to regional discounts or
                  restricted content and prevent location-based fraud.
                </div>
              </div>
              <div className="location_card_text-box">
                <div className="location_ttl">
                  <button
                    className="beny_right-btn1"
                    onClick={() => {
                      navigate("/vpnDetection");
                    }}
                  >
                    <span>Click here for demo</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="finger_location-card cartBox_useCase card-fraud-detection-card">
            <div
              className="location_card_text-content"
              style={{ textAlign: "center" }}
            >
              <div className="location_card_text-box">
                <div className="location_ttl" style={{ fontSize: "1.2rem" }}>
                  Card Transactions Fraud
                </div>
              </div>
              <div className="location_card_text-box">
                <div className="location_desc usecase-desc">
                  Use the demo to see how we secure transactions from card
                  cracking attempts, protect against stolen credit cards, and
                  help reduce chargebacks.
                </div>
              </div>
              <div className="location_card_text-box">
                <div className="location_ttl">
                  <button
                    className="beny_right-btn1"
                    onClick={() => {
                      navigate("/cardDetection");
                    }}
                  >
                    <span>Click here for demo</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
