
import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

interface StatusCode {
    code: number;
    title: string;
    description: string | number; // Allow both strings and numbers
    category: string;
}

const apiStatusCodes = new Map<string, StatusCode[]>([
    [
        "GST search by PAN",
        [
            {
                code: 200,
                title: "OK",
                description: `{
    "status": 1,
    "data": {
        "applicationStatus": "Pending",
        "authStatus": "Inactive",
        "emailId": "dummy@example.com",
        "gstinId": "09DUMMY1234A1Z5",
        "gstinRefId": "DUMMY_REF_12345",
        "merchantId": "org_DUMMY987654",
        "mobNum": "9876543210",
        "pan": "DUMMY1234A",
        "regType": "Regular",
        "registrationName": "Dummy Registration Name",
        "requestId": "12345678-90ab-cdef-1234-567890abcdef",
        "result": [
            {
                "emailId": "dummy@example.com",
                "applicationStatus": "Pending",
                "mobNum": "9876543210",
                "pan": "DUMMY1234A",
                "gstinRefId": "DUMMY_REF_12345",
                "regType": "Regular",
                "authStatus": "Inactive",
                "gstinId": "09DUMMY1234A1Z5",
                "registrationName": "Dummy Registration Name",
                "tinNumber": "DUMMYTIN12345",
                "state": "Dummy State"
            }
        ],
        "state": "Dummy State",
        "statusCode": 200,
        "timestamp": 1736940049846,
        "tinNumber": "DUMMYTIN12345"
    },
    "message": "success"
}
`,
                category: "Success",
            },
            {
                code: 400,
                title: "Bad Request",
                description: `{
    "status": 0,
    "message": "error",
    "data": {
        "message": "Pan is invalid",
        "referenceId": "",
        "merchantId": "org_DElCN34UEIrU78Y9",
        "requestId": "61576580-c7a2-4f93-a963-f12f053c5e5d"
    }
}`,
                category: "Client Error",
            },
            {
                code: 401,
                title: "Unauthorized",
                description: `{
    "message": "Request is unAuthorized"
}`,
                category: "Client Error",
            },
//             {
//                 code: 404,
//                 title: "Not Found",
//                 description: `{
//     Error!
//     Cannot POST /id/ky/gst-auth-advance-ty
//     }`,
//                 category: "Client Error",
//             },
//             {
//                 code: 422,
//                 title: "Unprocessable Entity",
//                 description: `{
//   "error": {
//     "code": 422,
//     "description": "",
//     "message": "Record Not Found",
//     "metadata": "",
//     "referenceId": "Rand0m-0369-0619-0305-str1nG",
//     "type": "NO_RECORD_FOUND"
//   },
//   "merchantId": "org_qMOPL369km74RKOz",
//   "requestId": "RNlVKFM0BcwEDGA=",
//   "statusCode": 422,
//   "timestamp": 1705049954335
// }`,
            //     category: "Client Error",
            // },
            {
                code: 500,
                title: "Internal Server Error",
                description: `{
    "status": 0,
    "message": "server error",
    "data": "Unexpected token "
}`,
                category: "Server Error",
            },
            {
                code: 503,
                title: "Service Unavailable",
                description: `{
            "message": "Service Unavailable"
            }`,
                category: "Server Error",
            },
            {
                code: 504,
                title: "Gateway Timeout",
                description: `{
            "message": "Gateway Timeout"
            }`,
                category: "Server Error",
            },
        ],
    ],
    [
        "GST Auth Advance",
        [
            {
                code: 200,
                title: "OK",
                description: `{
          "status": 1,
          "message": "success",
          "data": {
            "basicDetails": {
              "registrationName": "DUMMY COMPANY PRIVATE LIMITED",
              "gstin": "29ABCDE1234F1Z5",
              "constitutionOfBusiness": "PRIVATE LIMITED COMPANY",
              "natureOfBusiness": "MANUFACTURING",
              "annualTurnover": "Slab: Rs. 1 to 5 crores",
              "status": "ACTIVE",
              "registrationDate": "2023-01-01"
            },
            "principalAddress": {
              "address": "123, Dummy Tower, Street 45, Dummy City, Dummy State, 123456",
              "email": "dummycompany@gmail.com",
              "mobile": "9876543210"
            },
            "directors": [
              "JOHN DOE",
              "JANE DOE"
            ],
            "filingDetails": [
              {
                "filingYear": "2024-2025",
                "month": "November",
                "type": "GSTR1",
                "filingDate": "18/11/2024",
                "status": "Filed"
              }
            ],
            "jurisdiction": {
              "state": "DUMMY STATE",
              "zone": "DUMMY ZONE",
              "division": "DUMMY DIVISION",
              "charge": "DUMMY CHARGE OFFICE"
            }
          }
        }`,
                category: "Success",
            },
            {
                code: 400,
                title: "Bad Request",
                description: `{
    "status": 0,
    "message": "error",
    "data": {
        "message": "Invalid GST",
        "referenceId": "",
        "merchantId": "org_DElCN34UEIrU78Y0",
        "requestId": "b820f079-e4da-4f7c-b61d-23355e5bd6fb"
    }
}`,
                category: "Client Error",
            },
            {
                code: 401,
                title: "Unauthorized",
                description: `{
    "message": "Request is unAuthorized"
}`,
                category: "Client Error",
            },
            //   {
            //     code: 404,
            //     title: "Not found",
            //     description: "The PAN verification service refused the request due to insufficient permissions.",
            //     category: "Client Error",
            //   },
            //   {
            //     code: 422,
            //     title: "Unprocessable Entity",
            //     description: "The PAN verification service refused the request due to insufficient permissions.",
            //     category: "Client Error",
            //   },
            {
                code: 500,
                title: "Internal Server Error",
                description: `{
    "status": 0,
    "message": "server error",
    "data": "Expected double-quoted property name in JSON at position 24 (line 4 column 1)"
}`,
                category: "Server Error",
            },
            {
                code: 503,
                title: "Service Unavailable",
                description: `{
            "message": "Service Unavailable"
            }`,
                category: "Server Error",
            },
            {
                code: 504,
                title: "Gateway Timeout",
                description: `{
            "message": "Gateway Timeout"
            }`,
                category: "Server Error",
            },
        ],
    ],
    [
        "CIN Verification",
        [
            {
                code: 200,
                title: "OK",
                description: `{
    "status": 1,
    "data": {
        "activeCompliance": "",
        "alternativeAddress": "",
        "authorisedCapital": "500000",
        "cin": "U12345XY2022PTC678910",
        "classOfCompany": "Private",
        "companyCategory": "Company limited by shares",
        "companyName": "DUMMY TECHNOLOGIES PRIVATE LIMITED",
        "companyStatus": "Active",
        "companySubCategory": "Non-government company",
        "dateOfBalanceSheet": "31/03/2023",
        "dateOfIncorporation": "01/01/2022",
        "dateOfLastAGM": "31/12/2023",
        "emailId": "dummyemail@example.com",
        "merchantId": "org_ABCDEFG12345678",
        "numberOfMembers": "",
        "paidUpCapital": "500000",
        "registeredAddress": "Dummy Address, Dummy City, Dummy State, Dummy Country, PIN-123456",
        "registrationNumber": "678910",
        "requestId": "12345abc-defg-6789-hijk-1234567890ab",
        "rocCode": "ROC Dummy",
        "statusCode": 200,
        "suspendedAtStockExchange": "",
        "timestamp": 1736921645828,
        "whetherListedOrNot": "N"
    },
    "message": "success"
}
`,
                category: "Success",
            },
            {
                code: 400,
                title: "Bad Request",
                description: `{
    "status": 0,
    "message": "error",
    "data": {
        "message": "INTERNAL_SERVICE_UNAVAILABLE",
        "referenceId": "cb7a2b2b-7c09-484c-b8c2-a68533f3c1a0",
        "merchantId": "org_DElCN34UEIrU78Y9",
        "requestId": "34081b6c-c02d-412e-a878-0b30b82079a5"
    }
}`,
                category: "Client Error",
            },
            {
                code: 401,
                title: "Unauthorized",
                description: `{
            
    "message": "Request is unAuthorized"
}`,
                category: "Client Error",
            },
            //   {
            //     code: 403,
            //     title: "Forbidden",
            //     description: "The PAN verification service refused the request due to insufficient permissions.",
            //     category: "Client Error",
            //   },
            //   {
            //     code: 422,
            //     title: "Unprocessable Entity",
            //     description: "The PAN verification service refused the request due to insufficient permissions.",
            //     category: "Client Error",
            //   },
            {
                code: 500,
                title: "Internal Server Error",
                description: `{
    "status": 0,
    "message": "server error",
    "data": "Unexpected token"
}`,
                category: "Server Error",
            },
            {
                code: 503,
                title: "Service Unavailable",
                description: `{
            "message": "Service Unavailable"
            }`,
                category: "Server Error",
            },
            {
                code: 504,
                title: "Gateway Timeout",
                description: `{
            "message": "Gateway Timeout"
            }`,
                category: "Server Error",
            },
        ],
    ],
    [
        "LLPIN Verification",
        [
            {
                code: 200,
                title: "OK",
                description: `{
    "status": 1,
    "data": {
        "annualReturnFiled": "01-01-2025",
        "dateOfIncorporation": "01/01/2025",
        "descriptionOfMainDivision": "Dummy Services",
        "emailId": "dummy.email@example.com",
        "llpName": "DUMMY MEDIA VENTURZ LLP",
        "llpStatus": "Active",
        "llpin": "DUP-1234",
        "mainDivision": "99",
        "merchantId": "org_DUMMY123456789",
        "numberOfDesignatedPartners": "2",
        "numberOfPartners": "",
        "previousFirm": "",
        "registeredAddress": "Dummy Building, Dummy Street, Dummy City, Dummy State, PIN-123456",
        "requestId": "12345678-1234-1234-1234-1234567890ab",
        "rocCode": "ROC Dummy",
        "solvencyFiled": "01-01-2025",
        "statusCode": 200,
        "timestamp": 1736922579739,
        "totalObligationOfContribution": "200000"
    },
    "message": "success"
}
`,
                category: "Success",
            },
            {
                code: 400,
                title: "Bad Request",
                description: `{
    "status": 0,
    "message": "error",
    "data": {
        "message": "NO_RECORD_FOUND",
        "referenceId": "ab20dfb7-d17f-497b-9606-247657a72490",
        "merchantId": "org_DElCN34UEIrU78Y9",
        "requestId": "cf005b55-4c5e-4989-b07b-d64d2d28a437"
    }
}`,
                category: "Client Error",
            },
            {
                code: 401,
                title: "Unauthorized",
                description: `{
            
    "message": "Request is unAuthorized"
}`,
                category: "Client Error",
            },
            // {
            //     code: 403,
            //     title: "Forbidden",
            //     description: "The PAN verification service refused the request due to insufficient permissions.",
            //     category: "Client Error",
            // },
            // {
            //     code: 422,
            //     title: "Unprocessable Entity",
            //     description: "The PAN verification service refused the request due to insufficient permissions.",
            //     category: "Client Error",
            // },
            {
                code: 500,
                title: "Internal Server Error",
                description: `{
    "status": 0,
    "message": "server error",
    "data": "Unexpected token"
}`,
                category: "Server Error",
            },
            {
                code: 503,
                title: "Service Unavailable",
                description: `{
            "message": "Service Unavailable"
            }`,
                category: "Server Error",
            },
            {
                code: 504,
                title: "Gateway Timeout",
                description: `{
            "message": "Gateway Timeout"
            }`,
                category: "Server Error",
            },
        ],
    ],
    [
        "FLLPIN Verification",
        [
            {
                code: 200,
                title: "OK",
                description: `{
    "status": 1,
    "data": {
        "countryOfIncorporation": "Dummyland",
        "dateOfIncorporation": "01/01/2025",
        "dateOfLastFyedSOA": "01/01/2026",
        "descriptionOfMainDivision": "Dummy Services",
        "details": "No details available",
        "emailId": "dummy.email@example.com",
        "fllpStatus": "Inactive",
        "fllpin": "DUMMY-1234",
        "foreignLLPName": "DUMMY INTERNATIONAL LLP",
        "mainDivisionOfBusinessActivity": "Dummy Activity",
        "merchantId": "org_DUMMY123456",
        "noOfRepresentatives": "5",
        "registeredAddress": "Dummy Street, Dummy City, Dummy State, Dummy Country, PIN-123456",
        "requestId": "12345678-1234-1234-1234-1234567890ab",
        "statusCode": 200,
        "timestamp": 1736923397858,
        "typeOfOffice": "Head Office"
    },
    "message": "success"
}
`,
                category: "Success",
            },
            {
                code: 400,
                title: "Bad Request",
                description: `{
    "status": 0,
    "message": "error",
    "data": {
        "message": "NO_RECORD_FOUND",
        "referenceId": "b5cdd8b3-d2d6-4a9e-b635-a695381d4413",
        "merchantId": "org_DElCN34UEIrU78Y9",
        "requestId": "2ea2e819-59e8-4526-bb61-2c9a9e7d79ef"
    }
}`,
                category: "Client Error",
            },
            {
                code: 401,
                title: "Unauthorized",
                description: `{
    "message": "Request is unAuthorized"
}`,
                category: "Client Error",
            },
            // {
            //     code: 403,
            //     title: "Forbidden",
            //     description: "The PAN verification service refused the request due to insufficient permissions.",
            //     category: "Client Error",
            // },
            // {
            //     code: 422,
            //     title: "Unprocessable Entity",
            //     description: "The PAN verification service refused the request due to insufficient permissions.",
            //     category: "Client Error",
            // },
            {
                code: 500,
                title: "Internal Server Error",
                description: `{
    "status": 0,
    "message": "server error",
    "data": "Unexpected token"
}`,
                category: "Server Error",
            },
            {
                code: 503,
                title: "Service Unavailable",
                description: `{
            "message": "Service Unavailable"
            }`,
                category: "Server Error",
            },
            {
                code: 504,
                title: "Gateway Timeout",
                description: `{
                    "message": "Gateway Timeout"
                    }`,
                category: "Server Error",
            },
        ],
    ],
    [
        "Company Search",
        [
            {
                code: 200,
                title: "OK",
                description: `{
    "status": 1,
    "data": {
        "cin": "U12345XY2023PTC678910",
        "companiesList": [
            {
                "companyName": "DUMMY TECH SOLUTIONS PRIVATE LIMITED",
                "cin": "U12345XY2023PTC678910"
            }
        ],
        "companyName": "DUMMY TECH SOLUTIONS PRIVATE LIMITED",
        "merchantId": "org_DUMMY123456",
        "message": "",
        "noOfmatches": 1,
        "requestId": "12345678-1234-1234-1234-1234567890ab",
        "searchName": "DummyTech",
        "statusCode": 200,
        "timestamp": 1736923738980
    },
    "message": "success"
}
`,
                category: "Success",
            },
            {
                code: 400,
                title: "Bad Request",
                description: `{
    "status": 0,
    "message": "error",
    "data": {
        "message": "invalid request",
        "referenceId": "",
        "merchantId": "org_DElCN34UEIrU78Y9",
        "requestId": "1dfdb089-b6fe-452c-ba32-da77415b02c0"
    }
}`,
                category: "Client Error",
            },
            {
                code: 401,
                title: "Unauthorized",
                description: `{
                    "message": "Request is unAuthorized"
                }`,
                category: "Client Error",
            },
            // {
            //     code: 403,
            //     title: "Forbidden",
            //     description: "The PAN verification service refused the request due to insufficient permissions.",
            //     category: "Client Error",
            // },
            // {
            //     code: 422,
            //     title: "Unprocessable Entity",
            //     description: "The PAN verification service refused the request due to insufficient permissions.",
            //     category: "Client Error",
            // },
            {
                code: 500,
                title: "Internal Server Error",
                description: `{
    "status": 0,
    "message": "server error",
    "data": "Unexpected token "
}`,
                category: "Server Error",
            },
            {
                code: 503,
                title: "Service Unavailable",
                description: `{
            "message": "Service Unavailable"
            }`,
                category: "Server Error",
            },
            {
                code: 504,
                title: "Gateway Timeout",
                description: `{
            "message": "Gateway Timeout"
            }`,
                category: "Server Error",
            },
        ],
    ],
    [
        "FCRN Verification",
        [
            {
                code: 200,
                title: "OK",
                description: `{
    "message": "Success"
}
`,
                category: "Success",
            },
            {
                code: 400,
                title: "Bad Request",
                description: `{
    "status": 0,
    "message": "error",
    "data": {
        "message": "NO_RECORD_FOUND",
        "referenceId": "12345678-90ab-cdef-1234-567890abcdef",
        "merchantId": "org_DUMMY123456",
        "requestId": "abcdef12-3456-7890-abcd-ef1234567890"
    }
}`,
                category: "Client Error",
            },
            {
                code: 401,
                title: "Unauthorized",
                description: `{
                    "message": "Request is unAuthorized"
                }`,
                category: "Client Error",
            },
            // {
            //     code: 403,
            //     title: "Forbidden",
            //     description: "The PAN verification service refused the request due to insufficient permissions.",
            //     category: "Client Error",
            // },
            // {
            //     code: 422,
            //     title: "Unprocessable Entity",
            //     description: "The PAN verification service refused the request due to insufficient permissions.",
            //     category: "Client Error",
            // },
            {
                code: 500,
                title: "Internal Server Error",
                description: `{
    "status": 0,
    "message": "server error",
    "data": "Unexpected token "
}`,
                category: "Server Error",
            },
            {
                code: 503,
                title: "Service Unavailable",
                description: `{
                    "message": "Service Unavailable"
                }`,
                category: "Server Error",
            },
            {
                code: 504,
                title: "Gateway Timeout",
                description: `{
                    "message": "Gateway Timeout"
                }`,
                category: "Server Error",
            },
        ],
    ],
    [
        "Udyam generate otp",
        [
            {
                code: 200,
                title: "OK",
                description: `{
    "status": 1,
    "data": {
        "result": {
            "requestId": "udyam_otp_kwpzOqanIOsriabcdhsr"
        }
    },
    "message": "success"
}`,
                category: "Success",
            },
            {
                code: 400,
                title: "Bad Request",
                description: `{
    "status": 0,
    "message": "error",
    "data": {
        "error": {
            "name": "error",
            "message": "Invalid Udyam number",
            "status": 400,
            "reason": "VALIDATION_ERROR",
            "type": "Bad Request",
            "statusCode": 400
        }
    }
}`,
                category: "Client Error",
            },
            {
                code: 401,
                title: "Unauthorized",
                description: `{
                    "message": "Request is unAuthorized"
                }`,
                category: "Client Error",
            },
            // {
            //     code: 403,
            //     title: "Forbidden",
            //     description: "The PAN verification service refused the request due to insufficient permissions.",
            //     category: "Client Error",
            // },
            // {
            //     code: 422,
            //     title: "Unprocessable Entity",
            //     description: "The PAN verification service refused the request due to insufficient permissions.",
            //     category: "Server Error",
            // },
            {
                code: 500,
                title: "Internal Server Error",
                description: `{
    "status": 0,
    "message": "server error",
    "data": "Unexpected token "
}`,
                category: "Server Error",
            },
            {
                code: 503,
                title: "Service Unavailable",
                description: `{
                    "message": "Service Unavailable"
                }`,
                category: "Server Error",
            },
            {
                code: 504,
                title: "Gateway Timeout",
                description: `{
                    "message": "Gateway Timeout"
                }`,
                category: "Server Error",
            },
        ],
    ],
    [
        "PAN Validate",
        [
            {
                code: 200,
                title: "OK",
                description: `{
    "status": 1,
    "message": "success",
    "data": {
        "name": "Rahul Verma",
        "phone": "98XXXXXX67",
        "pan": "ABCDE1234F",
        "aadhaarLinked": false,
        "status": 1,
        "msg": "Success"
    }
}
`,
                category: "Success",
            },
            {
                code: 400,
                title: "Bad Request",
                description: `{
    "status": 0,
    "message": "error",
    "data": {
        "error": {
            "name": "error",
            "message": "Invalid PAN number",
            "status": 400,
            "reason": "VALIDATION_ERROR",
            "type": "Bad Request",
            "statusCode": 400
        }
    }
}`,
                category: "Client Error",
            },
            {
                code: 401,
                title: "Unauthorized",
                description: `{
                    "message": "Request is unAuthorized"
                }`,
                category: "Client Error",
            },
            // {
            //     code: 403,
            //     title: "Forbidden",
            //     description: "The PAN verification service refused the request due to insufficient permissions.",
            //     category: "Client Error",
            // },
            // {
            //     code: 422,
            //     title: "Unprocessable Entity",
            //     description: "The PAN verification service refused the request due to insufficient permissions.",
            //     category: "Client Error",
            // },
            {
                code: 500,
                title: "Internal Server Error",
                description: `{
    "status": 1,
    "data": {
        "status": 2,
        "msg": "BAD_REQUEST"
    }
}`,
                category: "Server Error",
            },
            {
                code: 503,
                title: "Service Unavailable",
                description: `{
                "message":"Service unavailable"
                }`,
                category: "Server Error",
            },
            {
                code: 504,
                title: "Gateway Timeout",
                description: `{
                "message":"Gateway Timeout"
                }`,
                category: "Server Error",
            },
        ],
    ],
    [
        "PAN Profile",
        [
            {
                code: 200,
                title: "OK",
                description: `{
    "message": "success",
    "status": 1,
    "data": {
        "aadhaarLinked": false,
        "buildingName": "Dummy Building",
        "city": "Dummy City",
        "dob": "01/01/2000",
        "doi": "01/01/2025",
        "email": "dummy.email@example.com",
        "fatherName": "Dummy Father",
        "firstName": "Rahul",
        "gender": "Male",
        "isPanValid": false,
        "lastName": "Sharma",
        "locality": "Dummy Locality",
        "maskedAadhaar": "XXXXXXXX1234",
        "merchantId": "org_DUMMY123456",
        "middleName": "Kumar",
        "name": "Rahul Sharma",
        "pan": "ABCDE1234F",
        "panType": "Individual",
        "phone": "98XXXXXX67",
        "pinCode": "123456",
        "requestId": "12345678-90ab-cdef-1234-567890abcdef",
        "state": "Dummy State",
        "statusCode": 200,
        "streetName": "Dummy Street",
        "timestamp": 1736926144772
    }
}
`,
                category: "Success",
            },
            {
                code: 400,
                title: "Bad Request",
                description: `{
    "status": 0,
    "message": "error",
    "data": {
        "message": "BAD_REQUEST",
        "referenceId": "",
        "merchantId": "org_DElCN34UEIrU78Y9",
        "requestId": "54a6fb0b-e280-4741-b791-1c65f46435af"
    }
}`,
                category: "Client Error",
            },
            {
                code: 401,
                title: "Unauthorized",
                description: `{
                    "message": "Request is unAuthorized"
                }`,
                category: "Client Error",
            },
            // {
            //     code: 403,
            //     title: "Forbidden",
            //     description: "The PAN verification service refused the request due to insufficient permissions.",
            //     category: "Client Error",
            // },
            // {
            //     code: 422,
            //     title: "Unprocessable Entity",
            //     description: "The PAN verification service refused the request due to insufficient permissions.",
            //     category: "Client Error",
            // },
            {
                code: 500,
                title: "Internal Server Error",
                description: `{
    "status": 0,
    "message": "server error",
    "data": "Unexpected token "
}`,
                category: "Server Error",
            },
            {
                code: 503,
                title: "Service Unavailable",
                description: `{
                "message":"Service unavailable"
                }`,
                category: "Server Error",
            },
            {
                code: 504,
                title: "Gateway Timeout",
                description: `{
                "message":"Gateway Timeout"
                }`,
                category: "Server Error",
            },
        ],
    ],
    [
        "Voter Id Verification",
        [
            {
                code: 200,
                title: "OK",
                description: `{
    "status": 1,
    "data": {
        "acName": "Dummy North",
        "age": 25,
        "cardHolderId": "123456789_DUMMY123456_S99",
        "disabilityAny": "No",
        "disabilityType": "",
        "district": "Dummy District",
        "dob": "01-01-1998",
        "epicNumber": "DUMMY123456",
        "gender": "M",
        "houseNumber": "123",
        "isActive": true,
        "isForm8Migration": "No",
        "isLocomotorDisabled": "No",
        "isSpeechHearingDisabled": "No",
        "isValidated": "Yes",
        "isVisuallyImpaired": "No",
        "isWheelchairRequired": "No",
        "lastUpdate": "01-01-2025",
        "merchantId": "org_DUMMY123456",
        "name": "Rahul Kumar",
        "nameVernacular1": "राहुल कुमार",
        "nameVernacular2": "राहुल कुमार",
        "nameVernacular3": "",
        "otherDisability": "None",
        "partName": "Dummy School R No. 5",
        "partNumber": "50",
        "pcName": "Dummy City",
        "psLatLong": "",
        "psName": "Dummy Public School",
        "pwdMarkingFormType": "",
        "pwdMarkingRefNo": "",
        "relativeName": "Ramesh Kumar",
        "relativeNameVernacular1": "रमेश कुमार",
        "relativeNameVernacular2": "",
        "relativeNameVernacular3": "",
        "relativeRelationshipType": "F",
        "requestId": "abcdef12-3456-7890-abcd-ef1234567890",
        "sectionNumber": "2",
        "serialNumberInPart": "123",
        "state": "Dummy State",
        "stateCode": "S99",
        "statusCode": 200,
        "timestamp": 1736926693674
    },
    "message": "success"
}
`,
                category: "Success",
            },
            {
                code: 400,
                title: "Bad Request",
                description: `{
    "status": 0,
    "message": "error",
    "data": {
        "message": "NO_RECORD_FOUND",
        "referenceId": "",
        "merchantId": "org_DElCN34UEIrU78Y9",
        "requestId": "d3c0646c-d60c-431d-afad-ab3baa034f9d"
    }
}`,
                category: "Client Error",
            },
            {
                code: 401,
                title: "Unauthorized",
                description: `{
                    "message": "Request is unAuthorized"
                }`,
                category: "Client Error",
            },
            // {
            //     code: 403,
            //     title: "Forbidden",
            //     description: "The PAN verification service refused the request due to insufficient permissions.",
            //     category: "Client Error",
            // },
            // {
            //     code: 422,
            //     title: "Unprocessable Entity",
            //     description: "The PAN verification service refused the request due to insufficient permissions.",
            //     category: "Client Error",
            // },
            {
                code: 500,
                title: "Internal Server Error",
                description:`{
    "status": 0,
    "message": "server error",
    "data": "Unexpected token "
}`,
                category: "Server Error",
            },
            {
                code: 503,
                title: "Service Unavailable",
                description: `{
                "message":"Service unavailable"
                }`,
                category: "Server Error",
            },
            {
                code: 504,
                title: "Gateway Timeout",
                description: `{
                "message":"Gateway Timeout"
                }`,
                category: "Server Error",
            },
        ],
    ],
    [
        "Passport Verification",
        [
            {
                code: 200,
                title: "OK",
                description: `{
    "status": 1,
    "data": {
        "applicationDate": "01/01/2025",
        "dateOfIssueMatch": true,
        "dispatchedOnFromSource": "01/01/2025",
        "merchantId": "org_DUMMY123456",
        "nameFromPassport": "DUMMY NAME",
        "nameMatch": true,
        "nameScore": 100,
        "passportNumberFromSource": "A1234567",
        "passportNumberMatch": true,
        "requestId": "12345678-90ab-cdef-1234-567890abcdef",
        "statusCode": 200,
        "surnameFromPassport": "DUMMY SURNAME",
        "timestamp": 1736927168230,
        "typeOfApplication": "Urgent"
    },
    "message": "success"
}
`,
                category: "Success",
            },
            {
                code: 400,
                title: "Bad Request",
                description: `{
    "status": 0,
    "message": "error",
    "data": {
        "message": "NO_RECORD_FOUND",
        "referenceId": "9c31317b-8eba-4fe4-b4d7-1cae85f9d65d",
        "merchantId": "org_DElCN34UEIrU78Y9",
        "requestId": "f9ee5966-a40e-4d77-80d3-be9e10be9522"
    }`,
                category: "Client Error",
            },
            {
                code: 401,
                title: "Unauthorized",
                description: `{
                    "message": "Request is unAuthorized"
                }`,
                category: "Client Error",
            },
            // {
            //     code: 403,
            //     title: "Forbidden",
            //     description: "The PAN verification service refused the request due to insufficient permissions.",
            //     category: "Client Error",
            // },
            // {
            //     code: 422,
            //     title: "Unprocessable Entity",
            //     description: "The PAN verification service refused the request due to insufficient permissions.",
            //     category: "Client Error",
            // },
            {
                code: 500,
                title: "Internal Server Error",
                description: `{
                    "message": "Request is unAuthorized"
                }`,
                category: "Server Error",
            },
            {
                code: 503,
                title: "Service Unavailable",
                description: `Internal Server Error`,
                category: "Server Error",
            },
            {
                code: 504,
                title: "Gateway Timeout",
                description: `Gateway Timeout`,
                category: "Server Error",
            },
        ],
    ],
    [
        "Face Match",
        [
            {
                code: 200,
                title: "OK",
                description: `{
    "status": 1,
    "message": "success",
    "data": {
        "clientId": "d1234567890abcdef1234567890abcdef",
        "providerId": "d0987654321abcdef0987654321abcdef",
        "merchantId": "org_DUMMY987654",
        "score": {
            "code": 200,
            "confidence": 98.7654321,
            "message": "DUMMY_FACE_DETECTED",
            "similarity": 98.7654321
        },
        "timestamp": 1736927443086
    }
}
`,
                category: "Success",
            },
            {
                code: 400,
                title: "Bad Request",
                description: `{
    "success": 0,
    "message": "document is missing"
}`,
                category: "Client Error",
            },
            {
                code: 401,
                title: "Unauthorized",
                description:`{
                    "message": "Request is unAuthorized"
                }`,
                category: "Client Error",
            },
            // {
            //     code: 403,
            //     title: "Forbidden",
            //     description: "The PAN verification service refused the request due to insufficient permissions.",
            //     category: "Client Error",
            // },
            // {
            //     code: 422,
            //     title: "Unprocessable Entity",
            //     description: "The PAN verification service refused the request due to insufficient permissions.",
            //     category: "Client Error",
            // },
            {
                code: 500,
                title: "Internal Server Error",
                description: `{
    "status": 0,
    "message": "server error",
    "data": {
        "clientId": "64743c19138176755041ebb8",
        "providerId": "65c33edb0b8fa6924cab4946",
        "merchantId": "org_DElCN34UEIrU78Y9",
        "score": {
            "code": 500,
            "description": "Internal Server Error",
            "message": "INTERNAL_SERVER_ERROR",
            "metadata": null,
            "referenceId": "",
            "type": "INTERNAL_SERVER_ERROR"
        },
        "timestamp": 1736927499978
    }
}`,
                category: "Server Error",
            },
            {
                code: 503,
                title: "Service Unavailable",
                description: `{
                    "message": "Service Unavailable"
                }`,
                category: "Server Error",
            },
            {
                code: 504,
                title: "Gateway Timeout",
                description: `{
                    "message": "Gateway Timeout"
                }` ,
                category: "Server Error",
            },
        ],
    ],
    [
        "Liveness Check",
        [
            {
                code: 200,
                title: "OK",
                description: `{
    "status": 1,
    "message": "success",
    "data": {
        "clientId": "d1234567890abcdef1234567890abcdef",
        "providerId": "d0987654321abcdef0987654321abcdef",
        "merchantId": "org_DUMMY987654",
        "message": "Sample message",
        "result": {
            "faces": {
                "accessories": {
                    "clothing": 1,
                    "glasses": 1,
                    "headgear": 1,
                    "hijab": 1,
                    "mask": 1
                },
                "eyesOpen": false,
                "multipleFacesDetected": true
            },
            "liveness": {
                "live": true,
                "livenessScore": 99.999
            },
            "moderation": {
                "blackAndWhite": true,
                "gore": true,
                "nudity": true,
                "poorQuality": true,
                "skinOrChest": 1,
                "whiteBackground": false
            }
        },
        "timestamp": 1736927681486
    }
}
`,
                category: "Success",
            },
            {
                code: 400,
                title: "Bad Request",
                description: `{
    "status": 0,
    "message": "image url is missing."
}`,
                category: "Client Error",
            },
            {
                code: 401,
                title: "Unauthorized",
                description:`{
                    "message": "Request is unAuthorized"
                }`,
                category: "Client Error",
            },
            // {
            //     code: 403,
            //     title: "Forbidden",
            //     description: "The PAN verification service refused the request due to insufficient permissions.",
            //     category: "Client Error",
            // },
            // {
            //     code: 422,
            //     title: "Unprocessable Entity",
            //     description: "The PAN verification service refused the request due to insufficient permissions.",
            //     category: "Client Error",
            // },
            {
                code: 500,
                title: "Internal Server Error",
                description: `{
    "status": 0,
    "message": "server error",
    "data": "Unexpected token "
}`,
                category: "Server Error",
            },
            {
                code: 503,
                title: "Service Unavailable",
                description: `{
                    "message": "Service Unavailable"
                }`,
                category: "Server Error",
            },
            {
                code: 504,
                title: "Gateway Timeout",
                description: `{
                    "message": "Gateway Timeout"
                }` ,
                category: "Server Error",
            },
        ],
    ],
    [
        "Phone number to pan search",
        [
            {
                code: 200,
                title: "OK",
                description: `{
    "status": 1,
    "data": {
        "response": {
            "pan": "DUMMY1234F",
            "personalInfo": {
                "name": "John Doe",
                "gender": "Male",
                "dateOfBirth": "1990-01-01"
            }
        }
    },
    "message": "success"
}
`,
                category: "Success",
            },
            {
                code: 400,
                title: "Bad Request",
                description: `{
    "status": 0,
    "message": "error",
    "data": {
        "error": {
            "reason": "VALIDATION_ERROR",
            "status": 400,
            "message": "\"phoneNumber\" length must be 10 characters long",
            "type": "Bad Request",
            "statusCode": 400,
            "name": "error"
        }
    }
}`,
                category: "Client Error",
            },
            {
                code: 401,
                title: "Unauthorized",
                description: `{
                    "message": "Request is unAuthorized"
                }`,
                category: "Client Error",
            },
            // {
            //     code: 403,
            //     title: "Forbidden",
            //     description: "The PAN verification service refused the request due to insufficient permissions.",
            //     category: "Client Error",
            // },
            // {
            //     code: 422,
            //     title: "Unprocessable Entity",
            //     description: "The PAN verification service refused the request due to insufficient permissions.",
            //     category: "Client Error",
            // },
            {
                code: 500,
                title: "Internal Server Error",
                description: `{
    "status": 0,
    "message": "server error",
    "data": "Unexpected token "
}`,
                category: "Server Error",
            },
            {
                code: 503,
                title: "Service Unavailable",
                description: `{
                    "message": "Service Unavailable"
                }`,
                category: "Server Error",
            },
            {
                code: 504,
                title: "Gateway Timeout",
                description: `Gateway Timeout`,
                category: "Server Error",
            },
        ],
    ],
    [
        "Digilocker apis",
        [
            {
                code: 200,
                title: "OK",
                description: `{
    "message": "success",
    "url": "https://api.digitallocker.gov.in/public/oauth3/1/authorize?res_type=code&redirect_uri=https://prod.fstac.tech/id/digilocker/callback&client_id=XDC64FC54F&state=VTJGc2RHVmtYMTg5NTlYdHZPelUrd1pqQVYwVk5xcjQ3cnNhdGwzYnhCNnFjTHhKdWhrNHFybkZCeFU5VC85b0tvc3BWNmxIb3JlTDBxUGNvalFVbGpjRFpMT3pvbXZlaytwNDlKbFFZOWNyaTc5b3VMT3pPM0hTRWlwSmhBWWhxT0RtRThjeEFVcHpnYVo0T04xQytnZkZQdEtYc2ZLamdpOWVvd2NtZFJuVWwvVlNQQitJeTBQWlFSTjV3MHNNOVh3bDMvOUtRZnozaDlZMFNKYlFjT09mMXFaWWhra0hZeEdGU2lCemhkYz0&code_challenge=MOCz_V5116bHty3LLtyP_6AwVl11hxenEQEBFJqsxkY&code_challenge_method=S259"
}`,
                category: "Success",
            },
            {
                code: 400,
                title: "Bad Request",
                description: `{
    "status": 0,
    "message": "error",
    "data": {
        "message": "Pan is invalid",
        "referenceId": "",
        "merchantId": "org_DElCN34UEIrU78Y9",
        "requestId": "a73425a4-5340-4d45-a3e2-43af7afb3ff0"
    }
}`,
                category: "Client Error",
            },
            {
                code: 401,
                title: "Unauthorized",
                description: `{
                    "message": "Request is unAuthorized"
                }`,
                category: "Client Error",
            },
            // {
            //     code: 403,
            //     title: "Forbidden",
            //     description: "The PAN verification service refused the request due to insufficient permissions.",
            //     category: "Client Error",
            // },
            // {
            //     code: 422,
            //     title: "Unprocessable Entity",
            //     description: "The PAN verification service refused the request due to insufficient permissions.",
            //     category: "Client Error",
            // },
            {
                code: 500,
                title: "Internal Server Error",
                description: `{
    "status": 0,
    "message": "server error",
    "data": "Unexpected token"
}`,
                category: "Server Error",
            },
            {
                code: 503,
                title: "Service Unavailable",
                description: `{
                    "message": "Service Unavailable"
                }`,
                category: "Server Error",
            },
            {
                code: 504,
                title: "Gateway Timeout",
                description: `{
                    "message": "Gateway Timeout"
                }`,
                category: "Server Error",
            },
        ],
    ],

]);



interface ResponseStatusProps {
    currentApi: string;
}

function ResponseStatus({ currentApi }: ResponseStatusProps) {
    const [expandedCode, setExpandedCode] = useState<number | null>(null);

    const toggleExpand = (code: number) => {
        setExpandedCode(expandedCode === code ? null : code);
    };

    const getCategoryColor = (category: string) => {
        switch (category) {
            case "Success":
                return { backgroundColor: "#DFF6DD", color: "#237804" };
            case "Client Error":
                return { backgroundColor: "#FDE2E2", color: "#A8071A" };
            case "Server Error":
                return { backgroundColor: "#F4E3FF", color: "#531DAB" };
            default:
                return { backgroundColor: "#F5F5F5", color: "#595959" };
        }
    };

    const isValidJson = (str: string) => {
        try {
            JSON.parse(str);
            return true;
        } catch (e) {
            return false;
        }
    };

    const styleJsonDescription = (description: string | number) => {
        if (typeof description === 'number') {
            // If the description is a number, highlight it
            return `<span style="color: blue;">${description}</span>`;
        }

        if (isValidJson(description)) {
            const jsonObj = JSON.parse(description);
            const formattedJson = JSON.stringify(jsonObj, null, 2);

            // Regex to match keys and values for styling
            return formattedJson.replace(/"([^"]+)":/g, (match, p1) => {
                return `<span style="color:rgb(196, 126, 243);">"${p1}"</span>:`;
            }).replace(/: "(.*?)"/g, (match, p1) => {
                return `: <span style="color:rgb(127, 196, 24);">"${p1}"</span>`;
            });
        }

        // If it's plain text (string), just return it
        return description;
    };

    const statusCodes = apiStatusCodes.get(currentApi) || [];

    return (
        <div style={{ minHeight: "100vh",border: "1px solid #e5e7eb", padding: "3rem 1rem", borderRadius: "0.375rem",marginTop:"1rem" }}>
            <div style={{ maxWidth: "600px", margin: "0 auto" }}>
                <h1 style={{ fontSize: "2rem", fontWeight: "bold", color: "black", marginBottom: "2rem", textAlign: "center" }}>
                    HTTP Status Codes - {currentApi}
                </h1>
                <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                    {statusCodes.map((status) => (
                        <div
                            key={status.code}
                            style={{
                                backgroundColor: "#F3F4F6",
                                borderRadius: "0.5rem",
                                // boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                                overflow: "hidden",
                                
                            }}
                        >
                            <button
                                onClick={() => toggleExpand(status.code)}
                                style={{
                                    width: "100%",
                                    textAlign: "left",
                                    padding: "1rem 1.5rem",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    backgroundColor: "transparent",
                                    border: "none",
                                    cursor: "pointer",
                                    transition: "background-color 0.15s ease-in-out",
                                }}
                            >
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", paddingRight: "30px" }}>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <span style={{ fontSize: "1.125rem", fontWeight: "600", color: "black" }}>{status.code}</span>
                                        <span style={{ fontWeight: "500", color: "black" }}>{status.title}</span>
                                    </div>
                                    <span
                                        style={{
                                            padding: "0.25rem 0.75rem",
                                            borderRadius: "9999px",
                                            fontSize: "0.875rem",
                                            fontWeight: "500",
                                            ...getCategoryColor(status.category),
                                        }}
                                    >
                                        {status.category}
                                    </span>
                                </div>
                                {expandedCode === status.code ? (
                                    <ChevronUp style={{ height: "20px", width: "20px", color: "#fff" }} />
                                ) : (
                                    <ChevronDown style={{ height: "20px", width: "20px", color: "#fff" }} />
                                )}
                            </button>
                            {expandedCode === status.code && (
                                <div
                                    style={{
                                        padding: "1rem 1.5rem",
                                        backgroundColor: "#F3F4F6",
                                        borderTop: "1px solid black",
                                    }}
                                >
                                    <div
                                        style={{
                                            color: "#343436",
                                            whiteSpace: "pre-wrap",
                                            wordBreak: "break-word",
                                        }}
                                        dangerouslySetInnerHTML={{
                                            __html: styleJsonDescription(status.description),
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ResponseStatus;
