import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/happyUserSlider.css";
import coreyoLogo from "../assets/images/Coreyo_logo.png"; // Import the updated image

export const HappyUserStory = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="slider-container">
      {/* <Slider {...settings}> */}
        {/* <div className="sliderCard1">
          <div className="sliderImage1">
            <img src={coreyoLogo} alt="Coreyo Logo" />
          </div>
          <div className="sliderHeading">
            <h6>InDrive</h6>
          </div>
          <div className="sliderDescription">
            <p>
              Providing the highest degree of protection against fraud is top
              priority at MPL. We are focused on offering users a safe and
              secure gameplay
            </p>
          </div>
          <div className="founder">
            <p>Founder name, CEO</p>
          </div>
        </div> */}
        <div className="sliderCard1">
          <div className="sliderImage1">
            <img src={coreyoLogo} alt="Coreyo Logo" />
          </div>
          <div className="sliderHeading">
          </div>
          <div className="sliderDescription">
            <p>
            Fstac’s device intelligence services have been a game-changer for us at Coreyo for reduced fraudulent activities on our platform, they’re a trusted partner in our journey toward secure and scalable growth.
            </p>
          </div>
          <div className="founder">
            <p className="Founder">Vince M Jose</p>
            <p className="Co-Founder">Co-Founder, Director</p>
          </div>
        </div>
        {/* <div className="sliderCard1">
          <div className="sliderImage1">
            <img src={coreyoLogo} alt="Coreyo Logo" />
          </div>
          <div className="sliderHeading">
            <h6>InDrive</h6>
          </div>
          <div className="sliderDescription">
            <p>
              Providing the highest degree of protection against fraud is top
              priority at MPL. We are focused on offering users a safe and
              secure gameplay
            </p>
          </div>
          <div className="founder">
            <p>Founder name, CEO</p>
          </div>
        </div> */}
      {/* </Slider> */}
    </div>
  );
};


