import React from "react";
import Slider from "react-slick";
import "../styles/about.css"; // Assuming your styles are already here
import { FaLinkedin } from 'react-icons/fa'; // Importing LinkedIn icon from react-icons
import { Helmet } from "react-helmet";
import utkarshImage from "../assets/images/utkarshImage.svg";
import natarjanImage from "../assets/images/Nataranjanimage.png";
import rahulImage from "../assets/images/rahulImage.svg";
import { Link } from "react-router-dom";

export const About = () => {
  const settings = {
    dots: true, // Enable dots at the bottom
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "ease-in-out",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Helmet> <meta charSet="utf-8" />
        <title>About Us | Fstac</title>
        <meta  name="description" 
        content="FSTAC offers advanced fingerprint authentication solutions, enhancing security and accessibility with innovative, scalable, and user-friendly biometric technology tailored for businesses and individuals worldwide. Secure your future today."/>
        <link rel="canonical" href="https://www.fstac.tech/about-us" /></Helmet>
      <div className="container" style={{ maxWidth: "1200px", margin: "0 auto", overflow: "hidden" }}>
        {/* Blue Background Container */}
        <div
          className="row"
          style={{
            backgroundImage: "linear-gradient(#b8e1ff, #d7f1ff)",
            borderRadius: "20px",
            padding: "20px",
            marginTop: "20px",
          }}
        >
          {/* Our Story Section - Full Width */}
          <div className="col-lg-12 mb-4">
            <div className="howItBox d-flex justify-content-center" style={{ padding: "20px" }}>
              <div className="howItStarted border rounded p-4" style={{ backgroundColor: "#fff", width: "80%" }}>
                <div className="headingHowItStarted text-center mb-3">
                  <h3 className="centered-heading">Our Story</h3>
                </div>
                <p className="text-center mb-0" style={{ padding: "10px" }}>
                  Fstac was founded with a mission to revolutionize the fintech space by providing cutting-edge
                  solutions for fraud prevention, behavioral intelligence, and payment security. The organization
                  emerged from Utkarsh's & Natarajan's joint vision to create a platform that integrates the best of
                  fraud monitoring, risk management, and seamless payment flows, enabling businesses to operate securely
                  in a digital-first world. Now, Fstac is turning its attention towards creating a Self-Sovereign Identity
                  (SSI) solution that will herald a new dawn in identity verification and Proof of Human (PoH) services!
                </p>
              </div>
            </div>
          </div>

          {/* Vision and Mission Section - Two Equal Columns */}
          <div className="col-md-6 d-flex justify-content-center mb-4">
            {/* Our Vision */}
            <div
              className="visionBox border rounded p-4 text-center"
              style={{ backgroundColor: "#fff", width: "100%", height: "auto" }}
            >
              <h3 className="centered-heading mb-3">Our Vision</h3>
              <p>To empower businesses with secure, seamless, and innovative fraud prevention solutions in a digital-first world.</p>
            </div>
          </div>

          <div className="col-md-6 d-flex justify-content-center mb-4">
            {/* Our Mission */}
            <div
              className="missionBox border rounded p-4 text-center"
              style={{ backgroundColor: "#fff", width: "100%", height: "auto" }}
            >
              <h3 className="centered-heading mb-3">Our Mission</h3>
              <p>To build cutting-edge technologies that drive behavioral intelligence, identity verification, and payment security.</p>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container mt-5 about-cnt"
        style={{
          backgroundColor: "#fff",
          borderRadius: "20px",
          marginBottom: "20px",
          height: "auto",
          padding: "20px 0px",
        }}
      >
        <h3 className="centered-heading" style={{ display: "flex", justifyContent: "center" }}>Our Management</h3>

        {/* Slick Carousel for Profiles */}
        <Slider {...settings} className="about-slider">
          {/* Utkarsh Srivastava */}
          <div
            className="profile-box floating-card"
            style={{
              backgroundColor: "rgba(236, 246, 255, 1)",
              height: "400px",
              margin: "4px", // Adjusted margin to 4px
              borderRadius: "15px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <a href="https://www.linkedin.com/in/utkarshpm/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
              <div className="profile-image-container" style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                <h3 className="profile-name">Utkarsh Srivastava</h3>
                <img src={utkarshImage} alt="Utkarsh Srivastava" className="profile-image" style={{ width: '160px', height: '155px' }} />
              </div>
              <div className="profile-details" style={{ flex: 2, padding: "20px 3px" }}>
                {/* <a href="https://www.linkedin.com/in/utkarshpm/" target="_blank" rel="noopener noreferrer"> */}
                <FaLinkedin style={{ fontSize: '45px', color: '#0077b5', marginTop: '10px' }} />
                {/* </a> */}
                <p className="profile-title" style={{padding: "10px 0px"}}>CEO</p>
                <p className="profile-description" style={{padding:"0px"}}>
                  Utkarsh has an extensive experience in Fintech across payments, banking, and security, having worked at notable institutions such as RBL and Decentro. He co-founded Zeppery, which successfully raised USD 77K in 2015.
                  As an angel investor and advisor, he actively contributes his industry expertise to several FinTech startups. He enjoys sharing his knowledge and insights to support growth and innovation of the fintech ecosystem.
                </p>
              </div>
            </a>
          </div>

          {/* Natarajan Ranganathan */}
          <div
            className="profile-box floating-card"
            style={{
              backgroundColor: "rgba(236, 246, 255, 1)",
              height: "400px",
              margin: "4px", 
              borderRadius: "15px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <a href="https://www.linkedin.com/in/natarajanr1/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
              <div className="profile-image-container" style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                <h3 className="profile-name">Natarajan Ranganathan</h3>
                <img src={natarjanImage} alt="Natarajan Ranganathan" className="profile-image" style={{ width: '160px', height: '155px' }} />
              </div>
              <div className="profile-details" style={{ flex: 2, padding: "20px 3px" }}>
                {/* <a href="https://www.linkedin.com/in/natarajanr1/" target="_blank" rel="noopener noreferrer"> */}
                <FaLinkedin style={{ fontSize: '45px', color: '#0077b5', marginTop: '10px' }} />
                {/* </a> */}
                <p className="profile-title" style={{padding: "10px 0px"}}>Independent Director</p>
                <p className="profile-description" style={{padding:"0px"}}>
                  Natarajan Ranganathan, with over 30 years of expertise in financial management, organizational transformation,
                  and strategic advisory, has worked with VC/PE firms like UK CNT Fund and Helion Venture. Renowned for his
                  skills in business restructuring and growth, his leadership in due diligence and investment strategies will play
                  a key role in driving Fstac's go-to-market success.
                </p>
              </div>
            </a>
          </div>

          {/* Rahul Pandey Profile */}
          <div
            className="profile-box floating-card"
            style={{
              backgroundColor: "rgba(236, 246, 255, 1)",
              height: "400px",
              margin: "4px", // Adjusted margin to 4px
              borderRadius: "15px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <a href="https://www.linkedin.com/in/rhlpandey/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
              <div className="profile-image-container" style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                <h3 className="profile-name">Rahul Pandey</h3>
                <img src={rahulImage} alt="Rahul Pandey" className="profile-image" style={{ width: '160px', height: '155px' }} />
              </div>
              <div className="profile-details" style={{ flex: 2, padding: "20px 3px" }}>
                {/* <a href="https://www.linkedin.com/in/rhlpandey/" target="_blank" rel="noopener noreferrer"> */}
                <FaLinkedin style={{ fontSize: '45px', color: '#0077b5', marginTop: '10px' }} />
                {/* </a> */}
                <p className="profile-title" style={{padding: "10px 0px"}}>Co-founder</p>
                <p className="profile-description" style={{padding:"0px"}}>
                  Rahul Pandey, with eighteen years of experience, has co-founded several innovative ventures. At Fstac, he leads
                  integrational services development and oversees custom software solutions at Technido Systems. He also co-founded
                  Let It Wag, a social network promoting connections among pet owners and supporting animal welfare causes,
                  showcasing his versatility and commitment to innovation.
                </p>
              </div>
            </a>
          </div>

        </Slider>
      </div>
    </>
  );
};
