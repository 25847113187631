import "./App.css";
import "./styles/variable.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/navbar";
import Footer from "./components/footer";
import { Home } from "./pages/home";
import { IpIntelligence } from "./pages/ipIntelligence";
import { BrowserIntelligence } from "./pages/browserIntelligence";
import { FraudRiskManagement } from "./pages/FraudRiskManagement";
import { DeviceIntelligence } from "./pages/deviceIntelligence";
import { DeviceAndBrowserIntelligence } from "./pages/deviceAndBrowserIntelligence";
import { About } from "./pages/about";
import { ComingSoonPage } from "./pages/comingSoon";
import { ContactUs } from "./pages/contactUs";
import { PrivacyPolicy } from "./pages/privacyPolicy";
import { TermAndCondition } from "./pages/termAndCondition";
import { ScrollToTop } from "./pages/scrollTop";
import { UpiIntentFlow } from "./pages/upiIntentFlow";
import { Contact } from "./pages/contact";
import { UpiCollectFlow } from "./pages/upiCollectFlow";
import { Payouts } from "./pages/payouts";
import { Fraudwatch } from "./pages/fraudwatch";
import { Ssi } from "./pages/ssi";
import { Payments } from "./pages/payments";
import { Onboarding } from "./pages/onboarding";
import { Blog } from "./pages/blogs";
import {APIReferencePage} from "./pages/apireference";
import {Sandbox} from "./pages/sandbox";
import PromoAbuse from "./pages/promoAbuse";
import AccountTakeover from "./pages/accountTakeover";
import VpnDetection from "./pages/vpnDetection";
import CardDetection from "./pages/cardDetection";
import {DataPrivacyVault} from "./pages/dataPrivacyVault";
import TrackPageViews from "./components/trackPageview";

// Import Helmet to modify meta tags
import { Helmet } from "react-helmet-async";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Header />

      {/* Global Meta Tags */}
      <Helmet>
        <meta
          name="description"
          content="Fstac offers fraud prevention, identity verification, payment solutions, KYC, and more. Book a demo today to secure your business in a digital world."
        />
      </Helmet>

      <main>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Helmet>
                  <title>Ultimate Fraud Prevention for Payment Security & Risk Management</title>
                  <meta
                    name="description"
                    content="Fsatc offers advanced identity verification and fraud prevention solutions, ensuring seamless KYC processes and real-time AML checks for your business."
                  />
                </Helmet>
                <Home />
              </>
            }
          />
          <Route
            path="/ip-intelligence"
            element={
              <>
                <Helmet>
                  <title>The Best IP Intelligence Software You Can Get | Fstac</title>
                  <meta
                    name="description"
                    content="Get access to the best ip intelligence and geolocation softwares and offer improved and more secure online experiences to your users."
                  />
                </Helmet>
                <IpIntelligence />
              </>
            }
          />
          <Route
            path="/browser-intelligence"
            element={
              <>
                <Helmet>
                  <title>Cutting-Edge Browser Fingerprinting Solutions | Fstac</title>
                  <meta
                    name="description"
                    content="Our leading browser fingerprinting and browser intelligence solutions can help improve your enterprise’s security and help improve marketing efficiency. Talk to Us Today!"
                  />
                </Helmet>
                <BrowserIntelligence />
              </>
            }
          />
          <Route
            path="/fraud-risk-management"
            element={
              <>
                <Helmet>
                  <title>Fraud Risk Management Solutions That Make a Difference | Fstac</title>
                  <meta
                    name="description"
                    content="Fstac offers fraud detection and prevention solutions that can help your business comprehensively. Talk to us today!"
                  />
                </Helmet>
                <FraudRiskManagement />
              </>
            }
          />
          <Route
            path="/device-intelligence"
            element={
              <>
                <Helmet>
                  <title>Leading Device Intelligence and Device Fingerprinting Solutions | Fstac</title>
                  <meta
                    name="description"
                    content="Our leading browser fingerprinting and browser intelligence solutions can help improve your enterprise’s security and help improve marketing efficiency. Talk to Us Today!"
                  />
                </Helmet>
                <DeviceAndBrowserIntelligence />
              </>
            }
          />
          <Route
            path="/about-us"
            element={
              <>
                <Helmet>
                  <title>About Us | Fstac</title>
                  <meta
                    name="description"
                    content="FSTAC offers advanced fingerprint authentication solutions, enhancing security and accessibility with innovative, scalable, and user-friendly biometric technology tailored for businesses and individuals worldwide. Secure your future today."
                  />
                </Helmet>
                <About />
              </>
            }
          />
            <Route
            path="/dataPrivacyVault"
            element={
              <>
                <Helmet>
                  <title>Data Privacy Vault | Fstac</title>
                  <meta
                    name="description"
                    content="FSTAC offers advanced fingerprint authentication solutions, enhancing security and accessibility with innovative, scalable, and user-friendly biometric technology tailored for businesses and individuals worldwide. Secure your future today."
                  />
                </Helmet>
                <DataPrivacyVault />
              </>
            }
          />
          <Route
            path="/documentation"
            element={<APIReferencePage />}
          />
          <Route
          path="/apireference"
          element={<APIReferencePage/>}
          />
          <Route
          path="/sandbox"
          element={<Sandbox/>}
          />
          <Route
          path="/promoAbuse"
          element={<PromoAbuse/>}
          />
          <Route
          path="/accountTakeover"
          element={<AccountTakeover/>}
          />
          <Route
          path="/vpnDetection"
          element={<VpnDetection/>}
          />
          <Route
          path="/cardDetection"
          element={<CardDetection/>}
          />
          <Route
            path="/events"
            element={<ComingSoonPage />}
          />
          <Route
            path="/whitepapers"
            element={<ComingSoonPage />}
          />
          <Route
            path="/blogs"
            element={
              <>
                <Helmet>
                  <title>Fstac Blog - Insights on Identity Verification & Fraud Prevention</title>
                  <meta
                    name="description"
                    content="Explore the Fstac blog for expert insights, trends, and tips on identity verification, fraud prevention, compliance, and secure digital onboarding."
                  />
                </Helmet>
                <Blog />
              </>
            }
          />
          <Route
            path="/contact-us"
            element={
              <>
                <Helmet>
                  <title>Contact Us | Fstac</title>
                  <meta
                    name="description"
                    content="Contact FSTAC for KYC, payment gateway, identity verification, and fraud prevention services. Reach out today to secure your future."
                  />
                </Helmet>
                <ContactUs />
              </>
            }
          />
          <Route
            path="/privacyPolicy"
            element={<PrivacyPolicy />}
          />
          <Route
            path="/termAndCondition"
            element={<TermAndCondition />}
          />
          <Route
            path="/upiIntentFlow"
            element={<ComingSoonPage />}
          />
          <Route
          path="/contact"
            element={
              <>
                <Helmet>
                  <title>Contact Us | Fstac</title>
                  <meta
                    name="description"
                    content="Contact FSTAC for KYC, payment gateway, identity verification, and fraud prevention services. Reach out today to secure your future."
                  />
                </Helmet>
                <Contact />
              </> 
            }
            />
          <Route
            path="/upiCollectFlow"
            element={<ComingSoonPage />}
          />
          <Route
            path="/payouts"
            element={<ComingSoonPage />}
          />
          <Route
            path="/fraudwatch"
            element={
              <>
                <Helmet>
                  <title>Fraud Watch | Fstac</title>
                  <meta
                    name="description"
                    content="Discover cutting-edge device monitoring and fraud prevention solutions. Enhance security, detect anomalies, and safeguard data with AI-driven insights. Optimize operations, reduce risk, and ensure seamless protection."
                  />
                </Helmet>
                <Fraudwatch />
              </>
            }
          />
          <Route
            path="/ssi"
            element={<ComingSoonPage />}
          />
          <Route
            path="/payments"
            element={<Payments />}
          />
          <Route
            path="/onboarding"
            element={<ComingSoonPage/>}
          />
        </Routes>
        
      </main>

      <Footer />
    </Router>
  );
}

export default App;
