import React, { useState } from "react";
import "../styles/blog.css";
import downArrow from "../assets/images/newArrow-down.svg"; // Replace with the actual path to your downArrow icon
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

export const Blog: React.FC = () => {
  const navigate = useNavigate();
  const [isIndustryOpen, setIsIndustryOpen] = useState(false);
  const [isUseCasesOpen, setIsUseCasesOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const toggleIndustry = () => setIsIndustryOpen(!isIndustryOpen);
  const toggleUseCases = () => setIsUseCasesOpen(!isUseCasesOpen);

  // Sample post data
  const posts = [
    {
      tag: "Payments",
      title: "Payments: Facilitates secure, fast transactions, enhancing customer experience.",
      imgSrc: "https://via.placeholder.com/300x150",
      alt: "Bot Detection",
      link: "/payments",
    },
    {
      tag: "Fraud Watch",
      title: "Fraud Watch: Detects, monitors, and alerts suspicious activities.",
      imgSrc: "https://via.placeholder.com/300x150",
      alt: "Gaming In-App Purchases",
      link: "/fraud",
    },
    {
      tag: "Browser Intelligence",
      title: "Browser Intelligence: Tracks user interactions to prevent fraudulent actions.",
      imgSrc: "https://via.placeholder.com/300x150",
      alt: "OpenTelemetry",
      link: "/browser-intelligence",
    },
    {
      tag: "Device Intelligence",
      title: "Device Intelligence: Analyzes device behavior for fraud detection.",
      imgSrc: "https://via.placeholder.com/300x150",
      alt: "OpenTelemetry",
      link: "/device-intelligence",
    },
    {
      tag: "Onboarding",
      title: "Onboarding: Streamlines account creation with secure identity verification.",
      imgSrc: "https://via.placeholder.com/300x150",
      alt: "OpenTelemetry",
      link: "/onboarding",
    },
    {
      tag: "Fraud-Risk Management",
      title: "Fraud Risk Management: Identifies, prevents, mitigates financial fraud risks effectively.",
      imgSrc: "https://via.placeholder.com/300x150",
      alt: "OpenTelemetry",
      link: "/fraud-risk-management",
    },
  ];

  // Filter posts based on search term
  const filteredPosts = posts.filter(post =>
    post.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <section className="all-posts">
      {/* Meta Tags for SEO */}
      <Helmet>
        <title>Fstac Blog - Insights on Identity Verification & Fraud Prevention</title>
        <meta
          name="description"
          content="Explore the Fstac blog for expert insights, trends, and tips on identity verification, fraud prevention, compliance, and secure digital onboarding."
        />
        <link rel="canonical" href="https://www.fstac.tech/blog" />
      </Helmet>

      {/* Header Section */}
      <div className="header-section">
        {/* Search Section */}
        <div className="search-section">
          <input
            type="text"
            placeholder="Search for posts..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)} // Update the search term state
          />
        </div>

        {/* Navigation Section */}
        <div className="nav-section">
          {/* Industry Menu */}
          <div className={`menuItem d-flex`}>
            <div
              className="d-flex align-items-center menuItemWrapper"
              onClick={toggleIndustry}
            >
              <div className="menuItem1" style={{ fontSize: "17px" }}>
                Industry
              </div>
              <img
                className="menuItemArrowIcon"
                style={{ height: "7px", paddingLeft: "2px" }}
                alt=""
                src={downArrow}
              />
            </div>
            {isIndustryOpen && (
              <div className="itemList1">
                <ul>
                  <li onClick={() => navigate("/payments")}>Payments</li>
                  <li onClick={() => navigate("/fraud")}>Fraud</li>
                  <li onClick={() => navigate("/ip")}>IP</li>
                </ul>
              </div>
            )}
          </div>

          {/* Use Cases Menu */}
          <div className={`menuItem d-flex`}>
            <div
              className="d-flex align-items-center menuItemWrapper"
              onClick={toggleUseCases}
            >
              <div className="menuItem1" style={{ fontSize: "17px" }}>
                Use Cases
              </div>
              <img
                className="menuItemArrowIcon"
                style={{ height: "7px", paddingLeft: "2px" }}
                alt=""
                src={downArrow}
              />
            </div>
            {isUseCasesOpen && (
              <div className="itemList1">
                <ul>
                  <li onClick={() => navigate("/case1")}>Case 1</li>
                  <li onClick={() => navigate("/case2")}>Case 2</li>
                  <li onClick={() => navigate("/case3")}>Case 3</li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Posts Section */}
      <h3>All Posts</h3>
      <div className="post-grid">
        {filteredPosts.map((post, index) => (
          <div className="post-card" key={index}>
            <img src={post.imgSrc} alt={post.alt} />
            <div className="content">
              <span className="tag">{post.tag}</span>
              <h2>{post.title}</h2>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};
