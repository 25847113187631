import { useState } from "react";
import "../styles/secure.css";
import device from "../assets/images/device_icon.png";
import ip from "../assets/images/ipIntelligence.svg";
import payment from "../assets/images/payment.png";
import fraud from "../assets/images/fraudIntelligence.svg";
import device_ from "../assets/images/deviceIntelligence.svg";
import fingerPrint from "../assets/images/fingerprint-03.png";
import deviceInteli from "../assets/images/deviceInteli.svg";
import browserInteli from "../assets/images/browserInteli.svg";
import glob from "../assets/images/global.svg";
import globalSearch from "../assets/images/global-search.svg";
import { SmartProtectionSlider } from "./smartProtectionSlider";
import { Link,Navigate,useNavigate } from "react-router-dom";

import upi from "../assets/images/upi.svg";
import dollar from "../assets/images/dollar.svg";
import upicollect from "../assets/images/upicollect.svg";
import collect from "../assets/images/collect.svg";
import { Payouts } from "./payouts";
import payouts from "../assets/images/payouts.svg";
import coin from "../assets/images/coin.svg";
import fraudwatch from "../assets/images/fraudwatch.svg";
import { Ssi } from "./ssi";
import ssi from "../assets/images/ssi.svg";
import paymentsupi from "../assets/images/paymentsupi.svg";
import { Onboarding } from "./onboarding";
import onboarding from "../assets/images/onboarding.svg";
import sisidentity from "../assets/images/sisidentity.svg";
import incognito from "../assets/images/incognito.svg";
import payupi from "../assets/images/payupi.svg";
import kyc from "../assets/images/kyc.svg";



const Secure = () => {
  const [activeSection, setActiveSection] = useState(0);
  const navigate=useNavigate()
  const handleMouseLeave = () => {

    setActiveSection(0);
  };
  return (
    <>
      <div className="frameParent container-fluid p-5">
        <div className="frameGroup">
          <div className="securePaymentsSmarterProteParent">
            <div className="securePaymentsSmarterContainer">
              <span className="secure">Secure</span>
              <span>{` `}</span>
              <span className="payments">Payments</span>
              <span>{`, `}</span>
              <span className="secure">Smarter</span>
              <span>{` `}</span>
              <span className="payments">Protection</span>
            </div>

            <div className="fromRealTimeFraud">
              From real-time fraud detection and advanced data intelligence to
              multi-layered security and adaptive risk scoring, Fstac empowers
              your business to detect, prevent, and mitigate fraud, ensuring
              robust protection and seamless operations
            </div>
          </div>
          <div className="sliderSecure">
            <SmartProtectionSlider />
          </div>
          <div className="intelligence-container">
            <div
              className={`section ${activeSection === 0 ? "active" : ""}`}
              onMouseEnter={() => setActiveSection(0)}
              onMouseLeave={handleMouseLeave}
              onClick={()=>navigate('/fraudwatch')}
            >
              {activeSection !== 0 && (
                <div className="box_content">
                  <img src={fraudwatch} alt="" />
                </div>
              )}
              {/* <Link to={'/fraudwatch'} className="CartLink"> */}
                <div className="content">
                  <img className="img1" src={incognito} alt="" style={{ transform: "rotate(90deg)" }} />

                  <h2>Fraud Watch</h2>
                  <p style={{padding:"0px"}}>
                    Fraud Watch detects and prevents fraud by monitoring transactions, verifying identities, and flagging suspicious activities, ensuring user and business security.
                  </p>
                  <img className="img2" src={incognito} alt="" />
                </div>
              {/* </Link> */}
            </div>
            <div
              className={`section ${activeSection === 1 ? "active" : ""}`}
              onMouseEnter={() => setActiveSection(1)}
              onMouseLeave={handleMouseLeave}
              onClick={()=>navigate('/ssi')}
            >
              <div className="box_content">
                <img src={ssi} alt="" />
              </div>
              {/* <Link to={'/ssi'} className="CartLink"> */}
              <div className="content">
                <img className="img1" src={sisidentity} alt="" />
                <h2>Self Sovereign Identity (SSI)</h2>
                <p style={{padding:"0px"}}>
                  SSI enables individuals to own and control their digital identities using decentralized technologies
                  like blockchain,enhancing privacy, security, and trust.
                </p>
                <img className="img2" src={sisidentity} alt="" />
              </div>
              {/* </Link> */}
            </div>
            <div
              className={`section ${activeSection === 2 ? "active" : ""}`}
              onMouseEnter={() => setActiveSection(2)}
              onMouseLeave={handleMouseLeave}
              onClick={()=>navigate('/payments')}
            >
              <div className="box_content">
                <img src={paymentsupi} alt="" />
              </div>
              {/* <Link to={'/payments'} className="CartLink"> */}
                <div className="content">
                  <img className="img1" src={payupi} alt="" />

                  <h2>Payments (UPI)</h2>
                  <p style={{padding:"0px"}}>
                    UPI revolutionizes digital transactions by enabling instant, secure, and seamless fund transfers between bank accounts via mobile devices.
                  </p>
                  <img className="img2" src={payupi} alt="" />
                </div>
              {/* </Link> */}
            </div>

            <div
              className={`section ${activeSection === 3 ? "active" : ""}`}
              onMouseEnter={() => setActiveSection(3)}
              onMouseLeave={handleMouseLeave}
              onClick={()=>navigate('/onboarding')}
            >
              <div className="box_content">
                <img src={onboarding} alt="" />
              </div>
              {/* <Link to={'/onboarding'} className="CartLink"> */}
              <div className="content">
                <img className="img1" src={kyc} alt="" />

                <h2>Onboarding (KYC)</h2>
                <p style={{padding:"0px"}}>
                  Onboarding KYC streamlines user verification by collecting and validating identity documents, ensuring compliance, reducing fraud, and enhancing customer trust efficiently.
                </p>
                <img className="img2" src={kyc} alt="" />
              </div>
              {/* </Link> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Secure;
