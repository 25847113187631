
import { useEffect, useRef } from "react";
// import "../styles/description.css";
import eggGloabl from "../assets/images/eggGlobal.png";
import seemless from "../assets/images/seemlessIntegration.svg";
import deviceintelligencee from "../assets/images/deviceintelligencee.avif";
import optimizing from "../assets/images/optimizing.svg";
import simplified from "../assets/images/simplified.svg";
import wideAdoption from "../assets/images/wideAdoption.svg";
import instantInsight from "../assets/images/instantInsight.svg";
import crossDevice from "../assets/images/crossDevice.svg";
import ecommerse from "../assets/images/eCommerceB.svg";
import bankingB from "../assets/images/bankingB.svg";
import onlineB from "../assets/images/onlineGamingB.svg";
import streamingB from "../assets/images/streamingServiceB.svg";
import travelB from "../assets/images/travelB.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MultipleItems } from "../pages/slider";
import "../styles/fraudwatch.css";
import { Link } from "react-router-dom"; 
import fraudriskcart from "../assets/images/fraudslider.png";
import { Helmet } from "react-helmet";

export const Fraudwatch = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <><Helmet> <meta charSet="utf-8" />
      <title>Fraud Watch | Fstac</title>
      <meta name="Discover cutting-edge device monitoring and fraud prevention solutions. Enhance security, detect anomalies, and safeguard data with AI-driven insights. Optimize operations, reduce risk, and ensure seamless protection." />
      <link rel="canonical" href="https://www.fstac.tech/fraudwatch" /></Helmet>
      <div className="singlePage">
        <div className="container ">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-12 d-flex justify-content-center">
              <div className="container1">
                <div className="outer-circle">
                  <div className="inner-circle">
                    <div className="content">
                      <div className="contentHeading circle_header">
                        FRAUD WATCH
                        </div>
                      <div className="description innerCircleContent">
                        <p>
                          Fraud Watch focuses on detecting and preventing fraudulent activities in payment systems. It monitors transactions for unusual patterns, verifies customer identities, and flags suspicious activities to protect users and businesses. Key aspects include real-time transaction monitoring, leveraging AI for anomaly detection, and ensuring compliance with payment regulations. This proactive approach reduces risks associated with financial fraud, such as chargebacks and unauthorized transactions, safeguarding both merchants and consumers.
                        </p>
                      </div>
                    </div>
                    <div className="outerCircleIcon1">
                      <img src={eggGloabl} alt="" />
                    </div>
                    <div className="outerCircleIcon2">
                      <img src={eggGloabl} alt="" />
                    </div>
                  </div>
                  <div className="outerCircleIcon">
                    <img src={eggGloabl} alt="" />
                  </div>
                </div>
                <div className="outerBox"></div>
                <div className="content_mobileView">
                  <div>
                    <button>FRAUD WATCH</button>
                  </div>
                  <h3>Fraud Watch</h3>
                  <div className="contentHeading circle_header">
                    {/* Device & Browser Intelligence */}
                    <p>
                          Fraud Watch focuses on detecting and preventing fraudulent activities in payment systems. It monitors transactions for unusual patterns, verifies customer identities, and flags suspicious activities to protect users and businesses. Key aspects include real-time transaction monitoring, leveraging AI for anomaly detection, and ensuring compliance with payment regulations. This proactive approach reduces risks associated with financial fraud, such as chargebacks and unauthorized transactions, safeguarding both merchants and consumers.
                        </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container howItWork" style={{ position: "relative", marginTop:"10px"}}>
          <div className="fraudContainer">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-10 d-flex justify-content-center">
              <div className="box-content" style={{ width: "100%", textAlign: "center" }}>
                <div className="contentHowitWork">
                  <div className="itwork">Use Cases</div>
                  <p>
                    Fraud Watch is an advanced solution designed to enhance security in payment systems by detecting and preventing fraudulent activities. It operates through real-time transaction monitoring to identify unusual patterns and flag suspicious activities.
                    <br></br>
                    Leveraging AI-powered anomaly detection, it recognizes deviations in behavior, such as unusual spending patterns or unauthorized access attempts. Fraud Watch integrates multi-factor authentication (MFA) to secure user identities and employs risk-based scoring to assess the safety of each transaction.
                    <br></br>
                    It ensures compliance with global financial regulations like PCI DSS and PSD2, offering end-to-end encryption for secure data handling. Additionally, it assists in chargeback management by proactively addressing disputes and providing detailed logs for resolution. Behavioral analytics further enhance its capabilities by detecting automated bots or malicious activities based on user interactions.
                    <br></br>
                    By offering scalable protection, operational efficiency, and regulatory compliance, Fraud Watch builds trust among customers, reduces financial losses, and provides businesses with a future-proof fraud prevention strategy.
                  </p>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>

        {/* Floating carousel section */}
        <div className="container-fluid floatingCarousel">
          <Slider {...settings} className="fraud-slider">
            <div className="sliderItemContainer">
              <div className="sliderItem" style={{ backgroundColor: "#e3dfff" }}>
                <img src={seemless} alt="Seamless Integration" />
                <h6>Browser Intelligence</h6>
                <p className="para">Unlock actionable insights into user behavior and browser data
                  for enhanced security and audience understanding</p>
                <Link to="/browser-intelligence">
                  <button>Learn More</button>
                </Link>
              </div>
            </div>
            <div className="sliderItemContainer">
              <div className="sliderItem" style={{ backgroundColor: "#eeffdb" }}>
                <img src={deviceintelligencee} alt="Enhanced Security" />
                <h6>Device Intelligence</h6>
                <p className="para">Identify and secure devices accessing your platform with
                  real-time data, fingerprinting, and anomaly detection</p>
                <Link to="/device-intelligence">
                  <button>Learn More</button>
                </Link>
              </div>
            </div>
            <div className="sliderItemContainer">
              <div className="sliderItem" style={{ backgroundColor: "#b8e1ff" }}>
                <img src={optimizing} alt="Optimizing Transactions" />
                <h6>IP Intelligence</h6>
                <p className="para">Monitor and analyze IP addresses with geolocation tracking,
                  reputation scoring, and proxy/VPN detection for secure
                  operations</p>
                <Link to="/ip-intelligence">
                  <button>Learn More</button>
                </Link>
              </div>
            </div>
            <div className="sliderItemContainer">
              <div className="sliderItem" style={{ backgroundColor: "#FFE2E2" }}>
                <img src={fraudriskcart} alt="fraud" />
                <h6>Fraud Risk Management</h6>
                <p className="para">Fraud Watch detects and prevents payment fraud by monitoring transactions, verifying identities, and flagging suspicious activities.</p>
                <Link to="/fraud-risk-management">
                  <button>Learn More</button>
                </Link>
              </div>
            </div>
          </Slider>
        </div>

      </div>
      </>
  );
};

