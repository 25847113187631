import TopHeader from "./topHeader";
import Secure from "../pages/secure";
import Management from "./management";
import PaymentStack from "./paymentStack";
import { GetInTouch } from "./getInTouch";
import { WhoTrust } from "./whotrust";
import { WhyFtac } from "./whyFstac";
import { HappyUser } from "./happyUser";
import {Seo} from "./seo";
import { Helmet } from "react-helmet";



export const Home = () => {
  return (
    <div className="home-container">
      <Helmet>
        <meta charSet="utf-8" />
        <title> Ultimate Fraud Prevention for Payment Security & Risk Management</title>
        <meta 
          name="description" 
          content=" Fsatc offers advanced identity verification and fraud prevention solutions, ensuring seamless KYC processes and real-time AML checks for your business." 
        />
        <link rel="canonical" href="https://www.fstac.tech/" />
      </Helmet>
      <TopHeader />
      {/* <WhoTrust /> */}
      <Secure />
      
      {/* <Management /> */}
      <WhyFtac />

      <PaymentStack />
      <HappyUser />
      <Seo />
      <GetInTouch
        heading="Let’s get in touch!"
        subheading="We’ll make your business safer. Lets discuss how"
        buttonText=" Contact Us"
      />
    </div>
  );
};
