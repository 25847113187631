// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/images/arrow-right-blue.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content4 {
  background-image: linear-gradient(#175fc9, #399cff);
  border-radius: 12px;
  padding: 50px;
  margin-top: 15px;
  margin-bottom: -30px;
}

.heading1 h3,
.heading1 p {
  color: white;
}
.heading1 p {
  padding: 0;
  color: #fbe9d6;
  font-size: 28px;
  font-weight: 500;
  padding-top: 10px;
}
.heading1 h2 {
  color: #ffffff;
}
.heading1 {
  padding-top: 20px;
}
.contactus {
  border: 1px solid rgba(255, 255, 255, 1);
  color: rgba(255, 255, 255, 1);
  border-radius: 12px;
  background: rgb(90 148 234 / 40%);
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 18px;

  margin-top: 3%;
  padding: 11px 34px;
}

.contactus {
  position: relative;
  transition: all 0.3s ease-in-out;
}

.contactus:hover {
  background-color: white;
  border: none;
  color: #175fc9;
  border: 1px solid rgb(51, 115, 210);
}

.contactus::after {
  content: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  position: absolute;
  right: 20px;
  top: 14px;
  opacity: 0;
  transform: translateX(-5px);
  transition: all 0.3s ease-in-out;
}
.contactus:hover {
  padding-right: 46px;
}
.contactus:hover::after {
  opacity: 1;
  transform: translateX(0);
}
`, "",{"version":3,"sources":["webpack://./src/styles/getintouch.css"],"names":[],"mappings":"AAAA;EACE,mDAAmD;EACnD,mBAAmB;EACnB,aAAa;EACb,gBAAgB;EAChB,oBAAoB;AACtB;;AAEA;;EAEE,YAAY;AACd;AACA;EACE,UAAU;EACV,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,cAAc;AAChB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,wCAAwC;EACxC,6BAA6B;EAC7B,mBAAmB;EACnB,iCAAiC;EACjC,gCAAgC;EAChC,gBAAgB;EAChB,eAAe;;EAEf,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,gCAAgC;AAClC;;AAEA;EACE,uBAAuB;EACvB,YAAY;EACZ,cAAc;EACd,mCAAmC;AACrC;;AAEA;EACE,gDAAqD;EACrD,kBAAkB;EAClB,WAAW;EACX,SAAS;EACT,UAAU;EACV,2BAA2B;EAC3B,gCAAgC;AAClC;AACA;EACE,mBAAmB;AACrB;AACA;EACE,UAAU;EACV,wBAAwB;AAC1B","sourcesContent":[".content4 {\n  background-image: linear-gradient(#175fc9, #399cff);\n  border-radius: 12px;\n  padding: 50px;\n  margin-top: 15px;\n  margin-bottom: -30px;\n}\n\n.heading1 h3,\n.heading1 p {\n  color: white;\n}\n.heading1 p {\n  padding: 0;\n  color: #fbe9d6;\n  font-size: 28px;\n  font-weight: 500;\n  padding-top: 10px;\n}\n.heading1 h2 {\n  color: #ffffff;\n}\n.heading1 {\n  padding-top: 20px;\n}\n.contactus {\n  border: 1px solid rgba(255, 255, 255, 1);\n  color: rgba(255, 255, 255, 1);\n  border-radius: 12px;\n  background: rgb(90 148 234 / 40%);\n  font-family: \"Inter\", sans-serif;\n  font-weight: 700;\n  font-size: 18px;\n\n  margin-top: 3%;\n  padding: 11px 34px;\n}\n\n.contactus {\n  position: relative;\n  transition: all 0.3s ease-in-out;\n}\n\n.contactus:hover {\n  background-color: white;\n  border: none;\n  color: #175fc9;\n  border: 1px solid rgb(51, 115, 210);\n}\n\n.contactus::after {\n  content: url(\"../assets/images/arrow-right-blue.svg\");\n  position: absolute;\n  right: 20px;\n  top: 14px;\n  opacity: 0;\n  transform: translateX(-5px);\n  transition: all 0.3s ease-in-out;\n}\n.contactus:hover {\n  padding-right: 46px;\n}\n.contactus:hover::after {\n  opacity: 1;\n  transform: translateX(0);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
