import React, { useState, FC } from "react";
import "../styles/cardDetection.css";

const CardDetection: FC = () => {
    const [cardNumber, setCardNumber] = useState<string>("");
    const [expiration, setExpiration] = useState<string>("");
    const [cvv, setCvv] = useState<string>("");
    const [chargeback, setChargeback] = useState<boolean>(false);
    const [flagStolen, setFlagStolen] = useState<boolean>(false);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        alert(`Card: ${cardNumber}\nExpiration: ${expiration}\nCVV: ${cvv}\nChargeback: ${chargeback}\nFlag Stolen: ${flagStolen}`);
        // Reset form fields after submission
        setCardNumber("");
        setExpiration("");
        setCvv("");
        setChargeback(false);
        setFlagStolen(false);
    };

    return (
        <div className="card-detection-container">
            <div className="card-detection-fingerprint-container">
                
            </div>
            <div className="card-detection-content">
            <div className="card-detection-fingerprint-id">
                    fingerprintId : k5pL1JnOOCo8THVwXDZIi0yOL7QU0C4dHwdWn6Rz1EA=
                </div>
                <h1 className="card-detection-title">Card Detection</h1>
                <p className="card-detection-description">
                    Use the demo to see how we secure transactions from card cracking
                    attempts, protect against stolen credit cards, and help reduce chargebacks.
                </p>

                <div className="card-detection-instructions">
                    <h2>How to use this demo</h2>
                    <ul>
                        <li>Change the pre-filled card details to simulate testing with stolen credit cards.</li>
                        <li>Place multiple orders to experience promo abuse after 3 attempts.</li>
                        <li>Click "Restart" to reset the demo for further testing.</li>
                        <li>Use the correct card details but select "Ask for chargeback" to see how we prevent future orders based on chargeback history.</li>
                        <li>Try placing an order again, you'll be blocked due to your past chargebacks.</li>
                        <li>Select "Flag this as a stolen credit card" after purchase to simulate using a stolen card.</li>
                        <li>We prevent any further transactions based on your stolen card history.</li>
                    </ul>
                </div>

                <div className="card-detection-form-container">
                    <div className="card-detection-cart-header">Shopping Cart</div>
                    <form className="card-detection-form" onSubmit={handleSubmit}>
                        <div className="card-detection-form-group">
                            <label htmlFor="card-number">
                                Card<span className="astrik">*</span>
                            </label>
                            <input
                                type="text"
                                id="card-number"
                                value={cardNumber}
                                onChange={(e) => setCardNumber(e.target.value)}
                                placeholder="4242424242424242"
                                required
                                className="card-detection-input"
                            />
                        </div>
                        <div className="card-detection-form-row">
                            <div className="card-detection-form-group">
                                <label htmlFor="expiration">
                                    Expiration<span className="astrik">*</span>
                                </label>
                                <input
                                    type="text"
                                    id="expiration"
                                    value={expiration}
                                    onChange={(e) => setExpiration(e.target.value)}
                                    placeholder="MM/YY"
                                    required
                                    className="card-detection-input"
                                />
                            </div>
                            <div className="card-detection-form-group">
                                <label htmlFor="cvv">
                                    CVV<span className="astrik">*</span>
                                </label>
                                <input
                                    type="text"
                                    id="cvv"
                                    value={cvv}
                                    onChange={(e) => setCvv(e.target.value)}
                                    placeholder="123"
                                    required
                                    className="card-detection-input"
                                />
                            </div>
                        </div>
                        <div className="card-detection-form-checkbox">
                            <input
                                type="checkbox"
                                id="chargeback"
                                checked={chargeback}
                                onChange={(e) => setChargeback(e.target.checked)}
                            />
                            <label htmlFor="chargeback">Ask for chargeback after purchase.</label>
                        </div>
                        <div className="card-detection-form-checkbox">
                            <input
                                type="checkbox"
                                id="flag-stolen"
                                checked={flagStolen}
                                onChange={(e) => setFlagStolen(e.target.checked)}
                            />
                            <label htmlFor="flag-stolen">
                                Flag this visitor using stolen card after purchase.
                            </label>
                        </div>
                        <button type="submit" className="card-detection-submit-btn">
                            Submit
                        </button>
                    </form>
                   
                </div>
            </div>
        </div>
    );
};

export default CardDetection;
