import "../styles/contactus.css";
import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "../styles/contactus.css";
import { ThankYouModal } from "./thankyou";
import { Helmet } from "react-helmet";

// Define the shape of the form values
interface ContactFormValues {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  platforms: {
    MobileApp: boolean;
    Website: boolean;
  };
  protections: {
    accountTakeover: boolean;
    fakeAccounts: boolean;
    referralPromoAbuse: boolean;
    paymentFraud: boolean;
    incentiveAbuse: boolean;
    spamAbuse: boolean;
    identityFraud: boolean;
    adFraud: boolean;
  };
  description: string;
  termsAccepted: boolean;
}

// Define the validation schema using Yup
const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^[a-zA-Z]+$/, "First name can only contain letters.")
    .min(2, "First name must be at least 2 characters.")
    .max(50, "First name can't exceed 50 characters.")
    .test(
      "not-same",
      "First name and last name cannot be the same.",
      function (value) {
        return value !== this.parent.lastName;
      }
    )
    .required("First name is required."),
  lastName: Yup.string()
    .matches(/^[a-zA-Z]+$/, "Last name can only contain letters.")
    .min(2, "Last name must be at least 2 characters.")
    .max(50, "Last name can't exceed 50 characters.")
    .test(
      "not-same",
      "Last name and first name cannot be the same.",
      function (value) {
        return value !== this.parent.firstName;
      }
    )
    .required("Last name is required."),
  email: Yup.string()
    .email("Invalid email format.")
    .matches(/^[a-zA-Z0-9._%+-]+@fstac\.tech$/, "Invalid email address.")
    .required("Email is required."),
  phone: Yup.string()
    .matches(/^\+?[0-9]{7,15}$/, "Phone number must contain only digits and an optional '+' for country code and be between 7 to 15 characters.")
    .required("Phone number is required."),
  platforms: Yup.object().test(
    "at-least-one-platform",
    "At least one platfortm must be selected.",
    (platforms)=>Object.values(platforms).some((value)=>value)
  ),
  protections: Yup.object().test(
    "at-least-one-protections",
    "At least one protections must be selected.",
    (protections)=>Object.values(protections).some((value)=>value)
  ),
  description: Yup.string()
    .max(1000, "Description cannot exceed 1000 characters.")
    .nullable(),
});

export const ContactUs: React.FC = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  // Initial values for the form
  const initialValues: ContactFormValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    platforms: {
      MobileApp: false,
      Website: false,
    },
    protections: {
      accountTakeover: false,
      fakeAccounts: false,
      referralPromoAbuse: false,
      paymentFraud: false,
      incentiveAbuse: false,
      spamAbuse: false,
      identityFraud: false,
      adFraud: false,
    },
    description: "",
    termsAccepted: false,
  };

  const dataModifid = (data: any[]) => {
    if (data.length > 0) {
      return data;
    } else {
      return null;
    }
  };

  const handleSubmit = async (values: ContactFormValues) => {
    // Prepare the payload
    const payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
      platforms: dataModifid(
        Object.keys(values.platforms).filter(
          (key) => values.platforms[key as keyof typeof values.platforms]
        )
      ),
      protections: dataModifid(
        Object.keys(values.protections).filter(
          (key) => values.protections[key as keyof typeof values.protections]
        )
      ),
      description: values.description === "" ? null : values.description,
    };

    try {
      const response = await fetch("https://prod.fstac.tech/id/website/leads", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        setModalOpen(true);
      } else {
        console.error("Failed to submit form");
      }
    } catch (error) {
      console.error("Error occurred while submitting form:", error);
    }
  };

  const closeModal = (resetForm: () => void) => {
    setModalOpen(false);
    resetForm(); // Reset the form when the modal closes
  };

  return (
    <div id="contactUs">
      <Helmet>
        <title>Contact Us | Fstac</title>
        <meta
          name="description"
          content="Contact FSTAC for KYC, payment gateway, identity verification, and fraud prevention services. Reach out today to secure your future."
        />
        <link rel="canonical" href="https://www.fstac.tech/contact-us" />
      </Helmet>
      <div className="container">
        <div className="contactUs">
          <div className="row justify-content-center align-items-center pb-5">
            <div className="col-lg-6">
              <div className="contactHeading">
                <h5 className="text-center">Contact Us</h5>
                <h2 className="text-center">Get Started Today</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-9">
              <div className="contactUsFrom">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({
                    values,
                    handleChange,
                    isValid,
                    dirty,
                    resetForm,
                    errors,
                    touched,
                  }) => (
                    <Form className="p-5">
                      <div className="firstName_">
                        <div className="inputBox">
                          <div className="label">
                            <label
                              htmlFor="firstName"
                              style={{ paddingBottom: "10px" }}
                            >
                              First Name <span className="required">*</span>
                            </label>
                          </div>
                          <div className="inputTag">
                            <Field
                              type="text"
                              name="firstName"
                              className={`inputTag ${
                                errors.firstName && touched.firstName
                                  ? "input-error"
                                  : ""
                              }`}
                              placeholder="First Name"
                            />
                            <ErrorMessage
                              name="firstName"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                        <div className="inputBox">
                          <div className="label">
                            <label
                              htmlFor="lastName"
                              style={{ paddingBottom: "10px" }}
                            >
                              Last Name <span className="required">*</span>
                            </label>
                          </div>
                          <div className="inputTag">
                            <Field
                              type="text"
                              name="lastName"
                              className={`inputTag ${
                                errors.lastName && touched.lastName
                                  ? "input-error"
                                  : ""
                              }`}
                              placeholder="Last Name"
                            />
                            <ErrorMessage
                              name="lastName"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="firstName_">
                        <div className="inputBox">
                          <div className="label">
                            <label
                              htmlFor="email"
                              style={{ paddingBottom: "10px" }}
                            >
                              Company Email <span className="required">*</span>
                            </label>
                          </div>
                          <div className="inputTag">
                            <Field
                              type="email"
                              name="email"
                              className={`inputTag ${
                                errors.email && touched.email
                                  ? "input-error"
                                  : ""
                              }`}
                              placeholder="Email"
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                        <div className="inputBox">
                          <div className="label">
                            <label
                              htmlFor="phone"
                              style={{ paddingBottom: "10px" }}
                            >
                              Contact Number <span className="required">*</span>
                            </label>
                          </div>
                          <div className="inputTag">
                            <Field
                              type="text"
                              name="phone"
                              className={`inputTag ${
                                errors.phone && touched.phone
                                  ? "input-error"
                                  : ""
                              }`}
                              placeholder="Contact Number"
                            />
                            <ErrorMessage
                              name="phone"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="inputBox">
                        <div className="label">
                          <label>
                            The platform(s) you’re looking to protect:
                            <span className="required">*</span>
                          </label>
                        </div>
                        <div className="checkbox_Box d-flex">
                          <div className="checkbox_single_box">
                            <Field
                              type="checkbox"
                              name="platforms.MobileApp"
                              checked={values.platforms.MobileApp}
                              onChange={handleChange}
                            />
                            <label>Mobile App</label>
                          </div>
                          <div className="checkbox_single_box">
                            <Field
                              type="checkbox"
                              name="platforms.Website"
                              checked={values.platforms.Website}
                              onChange={handleChange}
                            />
                            <label>Website</label>
                          </div>
                        </div>
                      </div>

                      <div className="inputBox">
                        <div className="label">
                          <label>
                            What are you looking to protect against:
                            <span className="required">*</span>
                          </label>
                        </div>
                        <div className="checkbox_Box_1">
                          {Object.keys(initialValues.protections).map(
                            (protection) => (
                              <div
                                key={protection}
                                className="checkbox_single_box_1"
                              >
                                <Field
                                  type="checkbox"
                                  name={`protections.${protection}`}
                                  checked={
                                    values.protections[
                                      protection as keyof typeof initialValues.protections
                                    ]
                                  }
                                  onChange={handleChange}
                                />
                                <label>
                                  {protection.replace(/([A-Z])/g, " $1").trim()}
                                </label>
                              </div>
                            )
                          )}
                        </div>
                      </div>

                      <div className="inputBox">
                        <Field
                          type="text"
                          name="description"
                          placeholder="Brief description of your requirements (optional)"
                          maxLength={1000}
                        />
                      </div>

                      <div className="termAndCondtion">
                        <div className="checkbox_term">
                          <label>
                            By submitting this form, you agree to our{" "}
                            <span className="colorChangeText">
                              <a href="/termAndCondition">Terms & Conditions</a>
                            </span>{" "}
                            &{" "}
                            <span className="colorChangeText">
                              <a href="/privacyPolicy">Privacy Policy</a>
                            </span>.
                          </label>
                          <ErrorMessage
                            name="termsAccepted"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>

                      <div>
                        <button
                          type="submit"
                          className="bookDemo"
                          disabled={!(isValid && dirty)}
                        >
                          Book a Demo
                        </button>
                      </div>
                      <ThankYouModal
                        isOpen={isModalOpen}
                        onClose={() => closeModal(resetForm)}
                      />
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
