import { useEffect, useRef } from "react";
import "../styles/description.css";
import eggGloabl from "../assets/images/eggGlobal.png";
import seemless from "../assets/images/seemlessIntegration.svg";
import enhanceSec from "../assets/images/enhanceSecurity.svg";
import optimizing from "../assets/images/optimizing.svg";
import simplified from "../assets/images/simplified.svg";
import wideAdoption from "../assets/images/wideAdoption.svg";
import instantInsight from "../assets/images/instantInsight.svg";
import crossDevice from "../assets/images/crossDevice.svg";
import ecommerse from "../assets/images/eCommerceB.svg";
import bankingB from "../assets/images/bankingB.svg";
import onlineB from "../assets/images/onlineGamingB.svg";
import streamingB from "../assets/images/streamingServiceB.svg";
import travelB from "../assets/images/travelB.svg";
import { MultipleItems } from "../pages/slider";

export const BrowserIntelligence = () => {
  return (
    <div className="singlePage">
      <div className="container ">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-12 d-flex justify-content-center">
            <div className="container1">
              <div className="outer-circle">
                <div className="inner-circle">
                  <div className="content">
                    <div>
                      <button>FRAUD WATCH</button>
                    </div>
                    <div className="contentHeading circle_header">
                      Browser Intelligence
                    </div>
                    <div className="description">
                      <p>
                        In the evolving digital landscape, understanding and
                        securing browser interactions are vital for businesses
                        to safeguard against threats and optimize user
                        experiences. Fstac’s Browser Intelligence is an advanced
                        solution that provides deep insights into browser
                        behavior, utilizing real-time analysis, smart signals,
                        and anomaly detection to protect your platform. This
                        solution is scalable, integrates seamlessly, and adapts
                        to your specific security needs, offering businesses a
                        powerful tool to prevent fraud and enhance operational
                        efficiency.
                      </p>
                    </div>
                  </div>
                  <div className="outerCircleIcon1">
                    <img src={eggGloabl} alt="" />
                  </div>
                  <div className="outerCircleIcon2">
                    <img src={eggGloabl} alt="" />
                  </div>
                </div>
                <div className="outerCircleIcon">
                  <img src={eggGloabl} alt="" />
                  
                </div>
              </div>
              <div className="outerBox"></div>
              <div className="content_mobileView">
                <div>
                  <button>FRAUD WATCH</button>
                </div>
                <div className="contentHeading circle_header">
                  Browser Intelligence
                </div>
                <div className="description">
                  <p>
                        In the evolving digital landscape, understanding and
                        securing browser interactions are vital for businesses
                        to safeguard against threats and optimize user
                        experiences. Fstac’s Browser Intelligence is an advanced
                        solution that provides deep insights into browser
                        behavior, utilizing real-time analysis, smart signals,
                        and anomaly detection to protect your platform. This
                        solution is scalable, integrates seamlessly, and adapts
                        to your specific security needs, offering businesses a
                        powerful tool to prevent fraud and enhance operational
                        efficiency.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row">
        <div className="col-lg-12">
          <div className="NewBox">
            <p>hello</p>
          </div>
        </div>
      </div> */}
      </div>
      {/* <div className="container singlePageBox" style={{marginTop:"60px"}}>
        <div className="row text-center">
          <div className="col-lg-12">
            <div className="keyFeatureHeading">
              <div>
                <h5>Key Features</h5>
              </div>
              <div>
                <h2>Secure, Insightful, Powerful</h2>
              </div>
            </div>
          </div>
          <div
            className="col-lg-12 "
            style={{ paddingTop: "50px", paddingBottom: "40px" }}
          >
            <div className="singleCard d-flex justify-content-between align-items-center p-3 singleCardBox">
              <div className="contentSection text-start">
                <div className="headingSinglePage">
                  <h6>Seamless Integration</h6>
                </div>
                <div className="descriptionContent">
                  <p>
                    Fstac’s IP Intelligence integrates seamlessly with your
                    existing systems, offering real-time, scalable IP data
                    analysis that adapts as your business grows. Whether you’re
                    operating a startup or managing a global enterprise, our
                    solution ensures that you’re equipped with the critical
                    insights necessary to safeguard and enhance your operations.
                  </p>
                </div>
              </div>
              <div className="singlePageContent">
                <img src={seemless} alt="" />
              </div>
            </div>
            <div
              className="singleCard d-flex justify-content-between align-items-center p-3 singleCardBox"
              style={{ marginTop: "30px" }}
            >
              <div className="contentSection text-start">
                <div className="headingSinglePage">
                  <h6>Enhanced Security</h6>
                </div>
                <div className="descriptionContent">
                  <p>
                    Our solution boosts your security framework by providing
                    precise geolocation accuracy, detecting proxies, VPNs, and
                    bots, and assigning risk scores to IPs. This multifaceted
                    approach allows you to proactively prevent fraud, protect
                    transactions, and ensure user authenticity, all while
                    maintaining a secure and reliable environment.
                  </p>
                </div>
              </div>
              <div className="singlePageContent">
                <img src={enhanceSec} alt="" />
              </div>
            </div>
            <div
              className="singleCard d-flex justify-content-between align-items-center p-3 singleCardBox"
              style={{ marginTop: "30px" }}
            >
              <div className="contentSection text-start">
                <div className="headingSinglePage">
                  <h6>Instant Insights</h6>
                </div>
                <div className="descriptionContent">
                  <p>
                    With Fstac’s powerful API, access to IP intelligence is
                    instantaneous, enabling real-time, data-driven
                    decision-making without disrupting your workflow. Whether
                    detecting suspicious IP patterns or verifying user
                    legitimacy, our solution keeps you ahead of potential
                    threats, ensuring your business operations are both secure
                    and efficient.
                  </p>
                </div>
              </div>
              <div className="singlePageContent">
                <img src={instantInsight} alt="" />
              </div>
            </div>
            <div
              className="singleCard d-flex justify-content-between align-items-center p-3 singleCardBox"
              style={{ marginTop: "30px" }}
            >
              <div className="contentSection text-start">
                <div className="headingSinglePage">
                  <h6>Consistent Cross-Device Experience</h6>
                </div>
                <div className="descriptionContent">
                  <p>
                    Fstac’s IP Intelligence guarantees a consistent and secure
                    experience across all devices, whether users access your
                    platform via mobile, desktop, or other connected devices.
                    This ensures that your security protocols and user
                    experiences are uniformly maintained, regardless of how
                    customers interact with your platform.
                  </p>
                </div>
              </div>
              <div className="singlePageContent">
                <img src={crossDevice} alt="" />
              </div>
            </div>
            <div
              className="singleCard d-flex justify-content-between align-items-center p-3 singleCardBox"
              style={{ marginTop: "30px" }}
            >
              <div className="contentSection text-start">
                <div className="headingSinglePage">
                  <h6>Wide Adoption and Global Coverage</h6>
                </div>
                <div className="descriptionContent">
                  <p>
                    Our extensive IP intelligence coverage spans IP addresses
                    worldwide, allowing you to secure and optimize a larger,
                    more diverse customer base. This broad coverage also
                    supports geo-blocking and access control measures, ensuring
                    your compliance with regional regulations and enhancing your
                    ability to manage user access effectively.
                  </p>
                </div>
              </div>
              <div className="singlePageContent">
                <img src={wideAdoption} alt="" />
              </div>
            </div>
            <div
              className="singleCard d-flex justify-content-between align-items-center p-3 singleCardBox"
              style={{ marginTop: "30px" }}
            >
              <div className="contentSection text-start">
                <div className="headingSinglePage">
                  <h6>Optimized Marketing and Personalization</h6>
                </div>
                <div className="descriptionContent">
                  <p>
                    By analyzing IP geolocation and behavior patterns, Fstac’s
                    IP Intelligence allows you to deliver personalized content
                    and offers, driving user engagement and satisfaction. It
                    also filters out fraudulent traffic, ensuring that your
                    marketing efforts reach genuine users, thereby maximizing
                    your return on investment (ROI).
                  </p>
                </div>
              </div>
              <div className="singlePageContent">
                <img src={optimizing} alt="" />
              </div>
            </div>
            <div
              className="singleCard d-flex justify-content-between align-items-center p-3 singleCardBox"
              style={{ marginTop: "30px" }}
            >
              <div className="contentSection text-start">
                <div className="headingSinglePage">
                  <h6>Simplified Reconciliation and Compliance</h6>
                </div>
                <div className="descriptionContent">
                  <p>
                    With instant payment confirmations and detailed transaction
                    records, Fstac’s IP Intelligence simplifies financial
                    reconciliation processes and helps maintain compliance with
                    global data protection regulations. Our ability to enforce
                    geo-blocking policies and monitor access based on user
                    location ensures your business remains legally compliant
                    across various jurisdictions.
                  </p>
                </div>
              </div>
              <div className="singlePageContent">
                <img src={simplified} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="container mx-auto mt-16 px-6">
  <div className="text-center mb-12">
    <h5 className="text-lg font-semibold text-gray-600">Key Features</h5>
    <h2 className="text-3xl font-bold text-gray-800">Secure, Insightful, Powerful</h2>
  </div>
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
    {/* Card 1 */}
    <div className="bg-white rounded-lg shadow-lg p-6 text-center">
      <img
        src={seemless}
        alt="Seamless Integration"
        className=" seemlessimg w-20 h-20 mx-auto mb-4"
      />
      <h6 className="text-lg font-semibold text-gray-800 mb-2">Seamless Integration</h6>
      <p className="text-gray-600">
                    Fstac’s IP Intelligence integrates seamlessly with your
                    existing systems, offering real-time, scalable IP data
                    analysis that adapts as your business grows. Whether you’re
                    operating a startup or managing a global enterprise, our
                    solution ensures that you’re equipped with the critical
                    insights necessary to safeguard and enhance your operations.
      </p>
    </div>

    {/* Card 2 */}
    <div className="bg-white rounded-lg shadow-lg p-6 text-center">
      <img
        src={enhanceSec}
        alt="Enhanced Security"
        className="enhancedimg w-20 h-20 mx-auto mb-4"
      />
      <h6 className="text-lg font-semibold text-gray-800 mb-2">Enhanced Security</h6>
      <p className="text-gray-600">
                    Our solution boosts your security framework by providing
                    precise geolocation accuracy, detecting proxies, VPNs, and
                    bots, and assigning risk scores to IPs. This multifaceted
                    approach allows you to proactively prevent fraud, protect
                    transactions, and ensure user authenticity, all while
                    maintaining a secure and reliable environment.
      </p>
    </div>

    {/* Card 3 */}
    <div className="bg-white rounded-lg shadow-lg p-6 text-center">
      <img
        src={instantInsight}
        alt="Instant Insights"
        className="instantimg w-20 h-20 mx-auto mb-4"
      />
      <h6 className="text-lg font-semibold text-gray-800 mb-2">Instant Insights</h6>
      <p className="text-gray-600">
                    With Fstac’s powerful API, access to IP intelligence is
                    instantaneous, enabling real-time, data-driven
                    decision-making without disrupting your workflow. Whether
                    detecting suspicious IP patterns or verifying user
                    legitimacy, our solution keeps you ahead of potential
                    threats, ensuring your business operations are both secure
                    and efficient.
      </p>
    </div>

    {/* Card 4 */}
    <div className="bg-white rounded-lg shadow-lg p-6 text-center">
      <img
        src={crossDevice}
        alt="Cross Device Experience"
        className="consistentimg w-20 h-20 mx-auto mb-4"
      />
      <h6 className="text-lg font-semibold text-gray-800 mb-2">Consistent Cross-Device Experience</h6>
      <p className="text-gray-600">
                    Ensure a consistent, secure experience across all devices, maintaining
                    Fstac’s IP Intelligence guarantees a consistent and secure
                    experience across all devices, whether users access your
                    platform via mobile, desktop, or other connected devices.
                    This ensures that your security protocols and user
                    experiences are uniformly maintained, regardless of how
                    customers interact with your platform.
      </p>
    </div>

    {/* Card 5 */}
    <div className="bg-white rounded-lg shadow-lg p-6 text-center">
      <img
        src={wideAdoption}
        alt="Wide Adoption"
        className="adoptionimg w-20 h-20 mx-auto mb-4"
      />
      <h6 className="text-lg font-semibold text-gray-800 mb-2">Wide Adoption and Global Coverage</h6>
      <p className="text-gray-600">
                    Our extensive IP intelligence coverage spans IP addresses
                    worldwide, allowing you to secure and optimize a larger,
                    more diverse customer base. This broad coverage also
                    supports geo-blocking and access control measures, ensuring
                    your compliance with regional regulations and enhancing your
                    ability to manage user access effectively.
      </p>
    </div>

    {/* Card 6 */}
    <div className="bg-white rounded-lg shadow-lg p-6 text-center">
      <img
        src={optimizing}
        alt="Optimized Marketing"
        className="optimizedimg w-20 h-20 mx-auto mb-4"
      />
      <h6 className="text-lg font-semibold text-gray-800 mb-2">Optimized Marketing and Personalization</h6>
      <p className="text-gray-600">
                By analyzing IP geolocation and behavior patterns, Fstac’s
                    IP Intelligence allows you to deliver personalized content
                    and offers, driving user engagement and satisfaction. It
                    also filters out fraudulent traffic, ensuring that your
                    marketing efforts reach genuine users, thereby maximizing
                    your return on investment (ROI).
      </p>
    </div>
  </div>
</div>

      {/* <div className="container-fluid p-0">
        <div className="sliderBox">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-12">
              <div className="sliderHeadingContent text-center">
                <h2>
                  See how our platform <br /> works quickly and easily!
                </h2>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <MultipleItems />
          </div>
        </div>
      </div> */}
      <div className="container">
  <div className="row align-items-center justify-content-center">
    <div className="col-lg-6">
      <div className="useCases text-center p-4">
        <h5>Use Case by Industry</h5>
        <p>
          Businesses often curate special offers for specific customers, such as
          first-time buyers, members of a referral program, and so on.
        </p>
      </div>
    </div>
  </div>
  <div className="row justify-content-center align-items-center mb-4">
    <div className="col-lg-10">
      <div className="industryCards">
         {/* E-Commerce Card */}
        <div className="card ecommerceCard">
          <img src={ecommerse} alt="E-Commerce" className="iconImage" />
          <h3 className="cardHeading">E-Commerce</h3>
          <p className="cardDescription">
          By correlating IP address with device and browser fingerprints, e-commerce platforms can detect unusual login attempts from unfamiliar environments, reducing the risk of account takeovers.

Identifies inconsistencies between IP, device, and browser data during transactions helps prevent fraudulent payments and chargebacks.
          </p>
        </div>
         {/* Banking & Financial Services Card  */}
        <div className="card bankingCard">
          <img src={bankingB} alt="Banking" className="iconImage" />
          <h3 className="cardHeading">Banking & Financial Services</h3>
          <p className="cardDescription">
          By combining IP intelligence with device and browser fingerprints ensures that only recognized devices from known locations can access sensitive financial accounts, blocking potential fraudsters.

By analyzing mismatches in IP and device/browser data, financial institutions can identify and halt phishing attempts where attackers use different environments to mimic legitimate users.


          </p>
        </div>
        {/* Online Gaming Card  */}
        <div className="card gamingCard">
          <img src={onlineB} alt="Online Gaming" className="iconImage" />
          <h3 className="cardHeading">Online Gaming</h3>
          <p className="cardDescription">
          By matching IP data with device and browser fingerprints, gaming platforms can detect and prevent users from creating multiple accounts to exploit promotions or cheat.

Real-time detection of discrepancies between IP and device/browser data can stop fraudulent in-game transactions and protect player accounts.
          </p>
        </div>
        {/* Streaming Services Card  */}
        <div className="card streamingCard">
          <img src={streamingB} alt="Streaming Services" className="iconImage" />
          <h3 className="cardHeading">Streaming Services</h3>
          <p className="cardDescription">
          Streaming services can use IP and fingerprinting data to identify and restrict unauthorized account sharing across different locations and devices.
          </p>
        </div>
         {/* Travel & Hospitality Card  */}
        <div className="card travelCard">
          <img src={travelB} alt="Travel & Hospitality" className="iconImage" />
          <h3 className="cardHeading">Travel & Hospitality</h3>
          <p className="cardDescription">
          Travel companies can use these technologies to detect and prevent the abuse of loyalty programs by tracking unusual access patterns across IPs and devices.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  );
};
