import React, { useState, FC } from "react";
import "../styles/accountTakeover.css"; // Ensure this CSS file is styled appropriately

const AccountTakeover: FC = () => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [checkboxChecked, setCheckboxChecked] = useState<boolean>(false); // Checkbox state

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    alert("Submitted");

    // Reset checkboxes (and other form states if needed)
    setUsername("");
    setPassword("");
    setCheckboxChecked(false); // Reset the checkbox
  };

  return (
    <div className="account-takeover-container">
      <div className="account-takeover-fingerprint-wrapper">
        <div className="account-takeover-fingerprint-container">
          <div className="account-takeover-fingerprint-id">
            fingerprintId : k5pL1JnOOCo8THVwXDZIi0yOL7QU0C4dHwdWn6Rz1EA=
          </div>
        </div>
      </div>
      <div className="account-takeover-content">
        <h1 className="account-takeover-title">Account Takeover</h1>
        <p className="account-takeover-description">
          Use the demo below to see how we safeguard your login page from
          account takeover attempts, even if credentials are compromised.
        </p>

        <div className="account-takeover-instructions">
          <h2>How to use this demo</h2>
          <ul>
            <li>Put in the username user and password password.</li>
            <li>
              Click Log in. You won't be able to log in despite having the
              correct credentials.
            </li>
            <li>
              Try calling the login endpoint directly or using JavaScript to
              spoof the request ID and visitor ID.
            </li>
            <li>
              Try to login with different passwords. You will be blocked after 5
              attempts.
            </li>
            <li>Try using incognito mode to log into this page.</li>
            <li>
              You can reset this scenario using the Restart button on the top
              right.
            </li>
          </ul>
        </div>

        <form className="account-takeover-login-form" onSubmit={handleSubmit}>
          <div className="account-takeover-form-group">
            <label htmlFor="username" className="account-takeover-label">
              Username<span className="account-takeover-asterisk">*</span>
            </label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="account-takeover-input"
              placeholder="Enter username"
              required
            />
          </div>
          <div className="account-takeover-form-group">
            <label htmlFor="password" className="account-takeover-label">
              Enter Password<span className="account-takeover-asterisk">*</span>
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="account-takeover-input"
              placeholder="Enter password"
              required
            />
          </div>
          <div className="account-takeover-form-group">
            <label className="account-takeover-label">
              Remember Me
            </label>
            <input
              type="checkbox"
              checked={checkboxChecked}
              onChange={() => setCheckboxChecked(!checkboxChecked)} // Toggle checkbox state
              className="account-takeover-checkbox"
            />
          </div>
          <button type="submit" className="account-takeover-submit-btn">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default AccountTakeover;
