// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.happyUserSubHeading {
  font-family: "Gilroy-Medium";
  font-size: 38px;
}
.happyUser {
  padding: 3%;
  border-radius: 12px;
  /* background: rgba(250, 159, 66, 0.1); */
  margin-top: 5%;
  margin-bottom: 5%;
   /* background-color: #f5f5f5; */
   border: 1px solid #ddd;
   border-radius: 8px;
   box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
   transition: transform 0.3s, box-shadow 0.3s;
}
.happyUser:hover{
  transform: translateY(-5px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}
.happyUserSubHeading{
  padding-top: 4px;
}

`, "",{"version":3,"sources":["webpack://./src/styles/happyuser.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,eAAe;AACjB;AACA;EACE,WAAW;EACX,mBAAmB;EACnB,yCAAyC;EACzC,cAAc;EACd,iBAAiB;GAChB,+BAA+B;GAC/B,sBAAsB;GACtB,kBAAkB;GAClB,wCAAwC;GACxC,2CAA2C;AAC9C;AACA;EACE,2BAA2B;IACzB,yCAAyC;AAC7C;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".happyUserSubHeading {\n  font-family: \"Gilroy-Medium\";\n  font-size: 38px;\n}\n.happyUser {\n  padding: 3%;\n  border-radius: 12px;\n  /* background: rgba(250, 159, 66, 0.1); */\n  margin-top: 5%;\n  margin-bottom: 5%;\n   /* background-color: #f5f5f5; */\n   border: 1px solid #ddd;\n   border-radius: 8px;\n   box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n   transition: transform 0.3s, box-shadow 0.3s;\n}\n.happyUser:hover{\n  transform: translateY(-5px);\n    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);\n}\n.happyUserSubHeading{\n  padding-top: 4px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
