import React, { useState, useEffect } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dracula } from "react-syntax-highlighter/dist/esm/styles/prism";
import "../styles/api-reference-updated.css";
import ResponseStatus from "../components/responseStatus";

export const APIReferencePage: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [selectedSection, setSelectedSection] = useState("API References");
  const [searchQuery, setSearchQuery] = useState("");

  const sidebarItems = [
    "API References",
    "GST search by PAN",
    "GST auth advance",
    "CIN Verification",
    "LLPIN Verification",
    "FLLPIN Verification",
    "Company Search",
    "FCRN Verification",
    "Udyam generate otp",
    "PAN Validate",
    "PAN profile",
    "Voter Id Verification",
    "Passport Verification",
    "Face Match",
    "Liveness Check",
    "Phone number to pan search",
    "Digilocker apis",
  ];

  useEffect(() => {
    const savedEmail = localStorage.getItem("userEmail");
    if (savedEmail) {
      setIsRegistered(true);
      setEmail(savedEmail);
    }
  }, []);

  const handleLogin = (e: React.FormEvent) => {
    e.preventDefault();
    if (email.trim() !== "" && password.trim() !== "") {
      localStorage.setItem("userEmail", email);
      setIsLoggedIn(true);
    } else {
      alert("Please enter a valid email and password.");
    }
  };

  const handleGoogleLogin = () => {
    const googleUserEmail = "user@gmail.com";
    localStorage.setItem("userEmail", googleUserEmail);
    setEmail(googleUserEmail);
    setIsLoggedIn(true);
  };

  const filteredItems = sidebarItems.filter((item) =>
    item.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const sectionContent: { [key: string]: JSX.Element | string } = {
    "API References": (
      <>

        Fstac is dedicated to helping businesses protect their customers and their bottom line from fraud while maintaining a seamless and user-friendly experience. Our API-based solutions are designed to provide a comprehensive set of tools for mitigating fraud risks without compromising user convenience. Built with adherence to RESTful principles, our APIs use JSON for efficient data exchange and support HTTP methods like GET, POST, PUT, and DELETE, ensuring compatibility with a wide range of programming languages and platforms. These intuitive and scalable solutions make integration seamless, whether you are working on web, mobile, or cloud-based applications.
        <br></br>
        <br></br>
        Fstac’s tools go beyond traditional fraud detection to include advanced features like behavioral analytics, device fingerprinting, geolocation insights, and dynamic risk scoring. These capabilities empower businesses to identify unusual patterns, track suspicious devices, and assess transaction risks in real time. Designed for scalability and ease of use, our APIs cater to businesses of all sizes, offering robust protection against evolving fraud tactics.
        <br></br>
        <br></br>
        At Fstac, we don’t just deliver cutting-edge technology—we partner with businesses to ensure successful implementation and ongoing optimization. Our dedicated support team is always available to assist with integration, troubleshooting, and enhancing fraud prevention strategies. Committed to staying ahead of emerging threats, Fstac continuously innovates to provide future-ready solutions, helping businesses safeguard their operations and build trust with their customers in an increasingly complex digital landscape.
        

      </>
    ),
    "GST search by PAN": (
      <div className="api-documentation">
        <div className="api-endpoint">
          <span className="method-post">POST</span>
          <span className="endpoint-url">https://uat0.fstac.tech/id/kyb/gst-search-by-pan</span>
        </div>


        <p className="api-description">
          This API enables you to retrieve GST details using a PAN number. It provides insights about registered GST numbers linked to the provided PAN.
        </p>

        {/* <div className="section-container">
          <h3>Recent Requests</h3>
          <div className="recent-requests">
            <div className="request-item">
              <div className="request-time">1h ago</div>
              <div className="request-status error">401</div>
              <div className="request-agent">
                <button className="try-it-btn">Try it!</button>
              </div>
              <div className="request-details">
                1 Request This Month
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="section-container">
          <h3>Recent Requests</h3>
          <div className="recent-requests">
            <table className="recent-requests-table">
              <thead className="recent-col1">
                <tr>
                  <th>TIME</th>
                  <th>STATUS</th>
                  <th>USER AGENT</th>
                </tr>
              </thead>
              <tbody className="recent-col2">
                <tr>
                  <td>3h ago</td>
                  <td>
                    <span className="status-error">401</span>
                  </td>
                  <td>
                    <button className="try-it-btn">Try It!</button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="request-summary">
              <span>1 Request This Month</span>
            </div>
          </div>
        </div> */}

        <div className="section-container body-params">
          <h3>Body Parameters</h3>
          <div className="params-table">
            <div className="table-header">
              <div className="table-cell">Name</div>
              <div className="table-cell">Type</div>
              <div className="table-cell">Required</div>
              <div className="table-cell">Description</div>
              <div className="table-cell">Example</div>
            </div>
            <div className="table-row">
              <div className="table-cell">provider</div>
              <div className="table-cell">number</div>
              <div className="table-cell">Yes</div>
              <div className="table-cell">
                Provider ID for the GST verification service.
              </div>
              <div className="table-cell">5</div>
            </div>
            <div className="table-row">
              <div className="table-cell">pan</div>
              <div className="table-cell">string</div>
              <div className="table-cell">Yes</div>
              <div className="table-cell">
                Valid PAN number to search for GST details.
              </div>
              <div className="table-cell">AAFCL5948L</div>
            </div>
          </div>
        </div>



        {/* <div className="section-container">
          <h3>Example Request</h3>
          <SyntaxHighlighter
            language="bash"
            style={dracula}
            customStyle={{
              backgroundColor: '#1e2329',
              borderRadius: '8px',
              padding: '20px',
              marginTop: '10px'
            }}
          >
            {`curl --location 'https://uat0.fstac.tech/id/kyb/gst-search-by-pan' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: Basic SzYwTTlQMmJpblprX1ZxNEFBQUE6NGZmYTRiMGVhYmFjZDk5ZmRmNzQ0MGFhNGFlMjNlZWM=' \\
--data '{
    "provider": 5,
    "pan": "AAFCL5948L"
}'`}
          </SyntaxHighlighter>
        </div> */}
        <ResponseStatus currentApi="GST search by PAN" />
        {/* <div className="section-container response-section">
          <h3>Responses</h3>
          <div className="responses-list">
            {[
              { code: "200", text: "Success", type: "success" },
              { code: "400", text: "Bad Request", type: "error" },
              { code: "401", text: "Unauthorized", type: "error" },
              { code: "403", text: "Credits Exhausted", type: "error" },
              { code: "422", text: "No Record Found", type: "error" },
              { code: "500", text: "Internal Server Error", type: "error" },
              { code: "503", text: "Internal Service Unavailable", type: "error" },
              { code: "504", text: "Gateway Timeout", type: "error" },
            ].map((response, index) => (
              <div key={index} className={`response-item ${response.type}`}>
                <div className="status-circle"></div>
                <div className="status-details">
                  <span className="status-code">{response.code}</span>
                  <span className="status-text">{response.text}</span>
                </div>
                <span className="arrow-icon">›</span>
              </div>
            ))}
          </div>
        </div> */}




        <div className="page-footer">
          <div className="update-info">
            <span>Updated 2 days ago</span>
          </div>
          <div className="feedback-section">
            <span>Did this page help you?</span>
            <button className="feedback-btn yes">Yes</button>
            <button className="feedback-btn no">No</button>
          </div>
        </div>
      </div>
    ),
    "GST auth advance": (

      <div className="api-documentation">
        <div className="api-endpoint">
          <span className="method-post">POST</span>
          <span className="endpoint-url">https://uat0.fstac.tech/id/kyb/gst-auth-advance
          </span>
        </div>


        <p className="api-description">
          This API enables you to retrieve GST details using a PAN number. It provides insights about registered GST numbers linked to the provided PAN.
        </p>

        {/* <div className="section-container">
            <h3>Recent Requests</h3>
            <div className="recent-requests">
              <div className="request-item">
                <div className="request-time">1h ago</div>
                <div className="request-status error">401</div>
                <div className="request-agent">
                  <button className="try-it-btn">Try it!</button>
                </div>
                <div className="request-details">
                  1 Request This Month
                </div>
              </div>
            </div>
          </div> */}

        {/* <div className="section-container">
            <h3>Recent Requests</h3>
            <div className="recent-requests">
              <table className="recent-requests-table">
                <thead className="recent-col1">
                  <tr>
                    <th>TIME</th>
                    <th>STATUS</th>
                    <th>USER AGENT</th>
                  </tr>
                </thead>
                <tbody className="recent-col2">
                  <tr>
                    <td>3h ago</td>
                    <td>
                      <span className="status-error">401</span>
                    </td>
                    <td>
                      <button className="try-it-btn">Try It!</button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="request-summary">
                <span>1 Request This Month</span>
              </div>
            </div>
          </div> */}

        <div className="section-container body-params">
          <h3>Body Parameters</h3>
          <div className="params-table">
            <div className="table-header">
              <div className="table-cell">Name</div>
              <div className="table-cell">Type</div>
              <div className="table-cell">Required</div>
              <div className="table-cell">Description</div>
              <div className="table-cell">Example</div>
            </div>
            <div className="table-row">
              <div className="table-cell">provider</div>
              <div className="table-cell">number</div>
              <div className="table-cell">Yes</div>
              <div className="table-cell">
                Provider ID for the GST verification service.
              </div>
              <div className="table-cell">5</div>
            </div>
            <div className="table-row">
              <div className="table-cell">gst</div>
              <div className="table-cell">string</div>
              <div className="table-cell">Yes</div>
              <div className="table-cell">
                Valid gst number to search for GST details.
              </div>
              <div className="table-cell">27ABKCS0842C1ZI</div>
            </div>
          </div>
        </div>



        {/* <div className="section-container">
            <h3>Example Request</h3>
            <SyntaxHighlighter
              language="bash"
              style={dracula}
              customStyle={{
                backgroundColor: '#1e2329',
                borderRadius: '8px',
                padding: '20px',
                marginTop: '10px'
              }}
            >
              {`curl --location 'https://uat0.fstac.tech/id/kyb/gst-search-by-pan' \\
  --header 'Content-Type: application/json' \\
  --header 'Authorization: Basic SzYwTTlQMmJpblprX1ZxNEFBQUE6NGZmYTRiMGVhYmFjZDk5ZmRmNzQ0MGFhNGFlMjNlZWM=' \\
  --data '{
      "provider": 5,
      "pan": "AAFCL5948L"
  }'`}
            </SyntaxHighlighter>
          </div> */}
        <ResponseStatus currentApi="GST Auth Advance" />

        <div className="page-footer">
          <div className="update-info">
            <span>Updated 2 days ago</span>
          </div>
          <div className="feedback-section">
            <span>Did this page help you?</span>
            <button className="feedback-btn yes">Yes</button>
            <button className="feedback-btn no">No</button>
          </div>
        </div>
      </div>
    ),

    "CIN Verification": (
      <div className="api-documentation">
        <div className="api-endpoint">
          <span className="method-post">POST</span>
          <span className="endpoint-url">https://uat0.fstac.tech/id/kyb/cin-verification
          </span>
        </div>


        <p className="api-description">
          This API enables you to retrieve GST details using a PAN number. It provides insights about registered GST numbers linked to the provided PAN.
        </p>

        {/* <div className="section-container">
            <h3>Recent Requests</h3>
            <div className="recent-requests">
              <div className="request-item">
                <div className="request-time">1h ago</div>
                <div className="request-status error">401</div>
                <div className="request-agent">
                  <button className="try-it-btn">Try it!</button>
                </div>
                <div className="request-details">
                  1 Request This Month
                </div>
              </div>
            </div>
          </div> */}

        {/* <div className="section-container">
            <h3>Recent Requests</h3>
            <div className="recent-requests">
              <table className="recent-requests-table">
                <thead className="recent-col1">
                  <tr>
                    <th>TIME</th>
                    <th>STATUS</th>
                    <th>USER AGENT</th>
                  </tr>
                </thead>
                <tbody className="recent-col2">
                  <tr>
                    <td>3h ago</td>
                    <td>
                      <span className="status-error">401</span>
                    </td>
                    <td>
                      <button className="try-it-btn">Try It!</button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="request-summary">
                <span>1 Request This Month</span>
              </div>
            </div>
          </div> */}

        <div className="section-container body-params">
          <h3>Body Parameters</h3>
          <div className="params-table">
            <div className="table-header">
              <div className="table-cell">Name</div>
              <div className="table-cell">Type</div>
              <div className="table-cell">Required</div>
              <div className="table-cell">Description</div>
              <div className="table-cell">Example</div>
            </div>
            <div className="table-row">
              <div className="table-cell">provider</div>
              <div className="table-cell">number</div>
              <div className="table-cell">Yes</div>
              <div className="table-cell">
                Provider ID for the cin verification service.
              </div>
              <div className="table-cell">5</div>
            </div>
            <div className="table-row">
              <div className="table-cell">cin</div>
              <div className="table-cell">string</div>
              <div className="table-cell">Yes</div>
              <div className="table-cell">
                Valid cin number.
              </div>
              <div className="table-cell">U72900MH2022PTC374442",</div>
            </div>
          </div>
        </div>



        {/* <div className="section-container">
            <h3>Example Request</h3>
            <SyntaxHighlighter
              language="bash"
              style={dracula}
              customStyle={{
                backgroundColor: '#1e2329',
                borderRadius: '8px',
                padding: '20px',
                marginTop: '10px'
              }}
            >
              {`curl --location 'https://uat0.fstac.tech/id/kyb/gst-search-by-pan' \\
  --header 'Content-Type: application/json' \\
  --header 'Authorization: Basic SzYwTTlQMmJpblprX1ZxNEFBQUE6NGZmYTRiMGVhYmFjZDk5ZmRmNzQ0MGFhNGFlMjNlZWM=' \\
  --data '{
      "provider": 5,
      "pan": "AAFCL5948L"
  }'`}
            </SyntaxHighlighter>
          </div> */}


        <ResponseStatus currentApi="CIN Verification" />
        <div className="page-footer">
          <div className="update-info">
            <span>Updated 2 days ago</span>
          </div>
          <div className="feedback-section">
            <span>Did this page help you?</span>
            <button className="feedback-btn yes">Yes</button>
            <button className="feedback-btn no">No</button>
          </div>
        </div>
      </div>
    ),
    "LLPIN Verification": (
      <div className="api-documentation">
        <div className="api-endpoint">
          <span className="method-post">POST</span>
          <span className="endpoint-url">https://uat0.fstac.tech/id/kyb/llpin-verification
          </span>
        </div>


        <p className="api-description">
          This API enables you to retrieve GST details using a PAN number. It provides insights about registered GST numbers linked to the provided PAN.
        </p>

        {/* <div className="section-container">
            <h3>Recent Requests</h3>
            <div className="recent-requests">
              <div className="request-item">
                <div className="request-time">1h ago</div>
                <div className="request-status error">401</div>
                <div className="request-agent">
                  <button className="try-it-btn">Try it!</button>
                </div>
                <div className="request-details">
                  1 Request This Month
                </div>
              </div>
            </div>
          </div> */}

        {/* <div className="section-container">
            <h3>Recent Requests</h3>
            <div className="recent-requests">
              <table className="recent-requests-table">
                <thead className="recent-col1">
                  <tr>
                    <th>TIME</th>
                    <th>STATUS</th>
                    <th>USER AGENT</th>
                  </tr>
                </thead>
                <tbody className="recent-col2">
                  <tr>
                    <td>3h ago</td>
                    <td>
                      <span className="status-error">401</span>
                    </td>
                    <td>
                      <button className="try-it-btn">Try It!</button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="request-summary">
                <span>1 Request This Month</span>
              </div>
            </div>
          </div> */}

        <div className="section-container body-params">
          <h3>Body Parameters</h3>
          <div className="params-table">
            <div className="table-header">
              <div className="table-cell">Name</div>
              <div className="table-cell">Type</div>
              <div className="table-cell">Required</div>
              <div className="table-cell">Description</div>
              <div className="table-cell">Example</div>
            </div>
            <div className="table-row">
              <div className="table-cell">provider</div>
              <div className="table-cell">number</div>
              <div className="table-cell">Yes</div>
              <div className="table-cell">
                Provider ID for the LLPIN verification service.
              </div>
              <div className="table-cell">5</div>
            </div>
            <div className="table-row">
              <div className="table-cell">llpin</div>
              <div className="table-cell">string</div>
              <div className="table-cell">Yes</div>
              <div className="table-cell">
                LLPIN Verification
              </div>
              <div className="table-cell">AAB-2933</div>
            </div>
          </div>
        </div>



        {/* <div className="section-container">
            <h3>Example Request</h3>
            <SyntaxHighlighter
              language="bash"
              style={dracula}
              customStyle={{
                backgroundColor: '#1e2329',
                borderRadius: '8px',
                padding: '20px',
                marginTop: '10px'
              }}
            >
              {`curl --location 'https://uat0.fstac.tech/id/kyb/gst-search-by-pan' \\
  --header 'Content-Type: application/json' \\
  --header 'Authorization: Basic SzYwTTlQMmJpblprX1ZxNEFBQUE6NGZmYTRiMGVhYmFjZDk5ZmRmNzQ0MGFhNGFlMjNlZWM=' \\
  --data '{
      "provider": 5,
      "pan": "AAFCL5948L"
  }'`}
            </SyntaxHighlighter>
          </div> */}
        <ResponseStatus currentApi="LLPIN Verification" />

        <div className="page-footer">
          <div className="update-info">
            <span>Updated 2 days ago</span>
          </div>
          <div className="feedback-section">
            <span>Did this page help you?</span>
            <button className="feedback-btn yes">Yes</button>
            <button className="feedback-btn no">No</button>
          </div>
        </div>
      </div>
    ),
    "FLLPIN Verification": (
      <div className="api-documentation">
        <div className="api-endpoint">
          <span className="method-post">POST</span>
          <span className="endpoint-url">https://uat0.fstac.tech/id/kyb/fllpin-verification
          </span>
        </div>


        <p className="api-description">
          This API enables you to retrieve GST details using a PAN number. It provides insights about registered GST numbers linked to the provided PAN.
        </p>

        {/* <div className="section-container">
            <h3>Recent Requests</h3>
            <div className="recent-requests">
              <div className="request-item">
                <div className="request-time">1h ago</div>
                <div className="request-status error">401</div>
                <div className="request-agent">
                  <button className="try-it-btn">Try it!</button>
                </div>
                <div className="request-details">
                  1 Request This Month
                </div>
              </div>
            </div>
          </div> */}

        {/* <div className="section-container">
            <h3>Recent Requests</h3>
            <div className="recent-requests">
              <table className="recent-requests-table">
                <thead className="recent-col1">
                  <tr>
                    <th>TIME</th>
                    <th>STATUS</th>
                    <th>USER AGENT</th>
                  </tr>
                </thead>
                <tbody className="recent-col2">
                  <tr>
                    <td>3h ago</td>
                    <td>
                      <span className="status-error">401</span>
                    </td>
                    <td>
                      <button className="try-it-btn">Try It!</button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="request-summary">
                <span>1 Request This Month</span>
              </div>
            </div>
          </div> */}

        <div className="section-container body-params">
          <h3>Body Parameters</h3>
          <div className="params-table">
            <div className="table-header">
              <div className="table-cell">Name</div>
              <div className="table-cell">Type</div>
              <div className="table-cell">Required</div>
              <div className="table-cell">Description</div>
              <div className="table-cell">Example</div>
            </div>
            <div className="table-row">
              <div className="table-cell">provider</div>
              <div className="table-cell">number</div>
              <div className="table-cell">Yes</div>
              <div className="table-cell">
                Provider ID for the FLLPIN verification service.
              </div>
              <div className="table-cell">5</div>
            </div>
            <div className="table-row">
              <div className="table-cell">fllpin</div>
              <div className="table-cell">string</div>
              <div className="table-cell">Yes</div>
              <div className="table-cell">
                FLLPIN Verification
              </div>
              <div className="table-cell">AAD-1373</div>
            </div>
          </div>
        </div>



        {/* <div className="section-container">
            <h3>Example Request</h3>
            <SyntaxHighlighter
              language="bash"
              style={dracula}
              customStyle={{
                backgroundColor: '#1e2329',
                borderRadius: '8px',
                padding: '20px',
                marginTop: '10px'
              }}
            >
              {`curl --location 'https://uat0.fstac.tech/id/kyb/gst-search-by-pan' \\
  --header 'Content-Type: application/json' \\
  --header 'Authorization: Basic SzYwTTlQMmJpblprX1ZxNEFBQUE6NGZmYTRiMGVhYmFjZDk5ZmRmNzQ0MGFhNGFlMjNlZWM=' \\
  --data '{
      "provider": 5,
      "pan": "AAFCL5948L"
  }'`}
            </SyntaxHighlighter>
          </div> */}

        <ResponseStatus currentApi="FLLPIN Verification" />

        <div className="page-footer">
          <div className="update-info">
            <span>Updated 2 days ago</span>
          </div>
          <div className="feedback-section">
            <span>Did this page help you?</span>
            <button className="feedback-btn yes">Yes</button>
            <button className="feedback-btn no">No</button>
          </div>
        </div>
      </div>
    ),
    "Company Search": (
      <div className="api-documentation">
        <div className="api-endpoint">
          <span className="method-post">POST</span>
          <span className="endpoint-url">https://uat0.fstac.tech/id/kyb/company-search
          </span>
        </div>


        <p className="api-description">
          This API enables you to retrieve GST details using a PAN number. It provides insights about registered GST numbers linked to the provided PAN.
        </p>

        {/* <div className="section-container">
            <h3>Recent Requests</h3>
            <div className="recent-requests">
              <div className="request-item">
                <div className="request-time">1h ago</div>
                <div className="request-status error">401</div>
                <div className="request-agent">
                  <button className="try-it-btn">Try it!</button>
                </div>
                <div className="request-details">
                  1 Request This Month
                </div>
              </div>
            </div>
          </div> */}

        {/* <div className="section-container">
            <h3>Recent Requests</h3>
            <div className="recent-requests">
              <table className="recent-requests-table">
                <thead className="recent-col1">
                  <tr>
                    <th>TIME</th>
                    <th>STATUS</th>
                    <th>USER AGENT</th>
                  </tr>
                </thead>
                <tbody className="recent-col2">
                  <tr>
                    <td>3h ago</td>
                    <td>
                      <span className="status-error">401</span>
                    </td>
                    <td>
                      <button className="try-it-btn">Try It!</button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="request-summary">
                <span>1 Request This Month</span>
              </div>
            </div>
          </div> */}

        <div className="section-container body-params">
          <h3>Body Parameters</h3>
          <div className="params-table">
            <div className="table-header">
              <div className="table-cell">Name</div>
              <div className="table-cell">Type</div>
              <div className="table-cell">Required</div>
              <div className="table-cell">Description</div>
              <div className="table-cell">Example</div>
            </div>
            <div className="table-row">
              <div className="table-cell">provider</div>
              <div className="table-cell">number</div>
              <div className="table-cell">Yes</div>
              <div className="table-cell">
                Provider ID for the FLLPIN verification service.
              </div>
              <div className="table-cell">5</div>
            </div>
            <div className="table-row">
              <div className="table-cell">Company Name</div>
              <div className="table-cell">string</div>
              <div className="table-cell">Yes</div>
              <div className="table-cell">
                FCRN Verification
              </div>
              <div className="table-cell">Microsoft</div>
            </div>
          </div>
        </div>



        {/* <div className="section-container">
            <h3>Example Request</h3>
            <SyntaxHighlighter
              language="bash"
              style={dracula}
              customStyle={{
                backgroundColor: '#1e2329',
                borderRadius: '8px',
                padding: '20px',
                marginTop: '10px'
              }}
            >
              {`curl --location 'https://uat0.fstac.tech/id/kyb/gst-search-by-pan' \\
  --header 'Content-Type: application/json' \\
  --header 'Authorization: Basic SzYwTTlQMmJpblprX1ZxNEFBQUE6NGZmYTRiMGVhYmFjZDk5ZmRmNzQ0MGFhNGFlMjNlZWM=' \\
  --data '{
      "provider": 5,
      "pan": "AAFCL5948L"
  }'`}
            </SyntaxHighlighter>
          </div> */}

        <ResponseStatus currentApi="Company Search" />

        <div className="page-footer">
          <div className="update-info">
            <span>Updated 2 days ago</span>
          </div>
          <div className="feedback-section">
            <span>Did this page help you?</span>
            <button className="feedback-btn yes">Yes</button>
            <button className="feedback-btn no">No</button>
          </div>
        </div>
      </div>
    ),
    "FCRN Verification": (
      <div className="api-documentation">
        <div className="api-endpoint">
          <span className="method-post">POST</span>
          <span className="endpoint-url">https://uat0.fstac.tech/id/kyb/fcrn-verification
          </span>
        </div>


        <p className="api-description">
          This API enables you to retrieve GST details using a PAN number. It provides insights about registered GST numbers linked to the provided PAN.
        </p>

        {/* <div className="section-container">
            <h3>Recent Requests</h3>
            <div className="recent-requests">
              <div className="request-item">
                <div className="request-time">1h ago</div>
                <div className="request-status error">401</div>
                <div className="request-agent">
                  <button className="try-it-btn">Try it!</button>
                </div>
                <div className="request-details">
                  1 Request This Month
                </div>
              </div>
            </div>
          </div> */}

        {/* <div className="section-container">
            <h3>Recent Requests</h3>
            <div className="recent-requests">
              <table className="recent-requests-table">
                <thead className="recent-col1">
                  <tr>
                    <th>TIME</th>
                    <th>STATUS</th>
                    <th>USER AGENT</th>
                  </tr>
                </thead>
                <tbody className="recent-col2">
                  <tr>
                    <td>3h ago</td>
                    <td>
                      <span className="status-error">401</span>
                    </td>
                    <td>
                      <button className="try-it-btn">Try It!</button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="request-summary">
                <span>1 Request This Month</span>
              </div>
            </div>
          </div> */}

        <div className="section-container body-params">
          <h3>Body Parameters</h3>
          <div className="params-table">
            <div className="table-header">
              <div className="table-cell">Name</div>
              <div className="table-cell">Type</div>
              <div className="table-cell">Required</div>
              <div className="table-cell">Description</div>
              <div className="table-cell">Example</div>
            </div>
            <div className="table-row">
              <div className="table-cell">provider</div>
              <div className="table-cell">number</div>
              <div className="table-cell">Yes</div>
              <div className="table-cell">
                Provider ID for the FCRN verification service.
              </div>
              <div className="table-cell">5</div>
            </div>
            <div className="table-row">
              <div className="table-cell">fcrn</div>
              <div className="table-cell">string</div>
              <div className="table-cell">Yes</div>
              <div className="table-cell">
                FCRN Verification
              </div>
              <div className="table-cell">F04663</div>
            </div>
          </div>
        </div>



        {/* <div className="section-container">
            <h3>Example Request</h3>
            <SyntaxHighlighter
              language="bash"
              style={dracula}
              customStyle={{
                backgroundColor: '#1e2329',
                borderRadius: '8px',
                padding: '20px',
                marginTop: '10px'
              }}
            >
              {`curl --location 'https://uat0.fstac.tech/id/kyb/gst-search-by-pan' \\
  --header 'Content-Type: application/json' \\
  --header 'Authorization: Basic SzYwTTlQMmJpblprX1ZxNEFBQUE6NGZmYTRiMGVhYmFjZDk5ZmRmNzQ0MGFhNGFlMjNlZWM=' \\
  --data '{
      "provider": 5,
      "pan": "AAFCL5948L"
  }'`}
            </SyntaxHighlighter>
          </div> */}

        <ResponseStatus currentApi="FCRN Verification" />


        <div className="page-footer">
          <div className="update-info">
            <span>Updated 2 days ago</span>
          </div>
          <div className="feedback-section">
            <span>Did this page help you?</span>
            <button className="feedback-btn yes">Yes</button>
            <button className="feedback-btn no">No</button>
          </div>
        </div>
      </div>
    ),
    "Udyam generate otp": (
      <div className="api-documentation">
        <div className="api-endpoint">
          <span className="method-post">POST</span>
          <span className="endpoint-url">https://uat0.fstac.tech/id/kyb/fcrn-verification
          </span>
        </div>


        <p className="api-description">
          This API enables you to retrieve GST details using a PAN number. It provides insights about registered GST numbers linked to the provided PAN.
        </p>

        {/* <div className="section-container">
            <h3>Recent Requests</h3>
            <div className="recent-requests">
              <div className="request-item">
                <div className="request-time">1h ago</div>
                <div className="request-status error">401</div>
                <div className="request-agent">
                  <button className="try-it-btn">Try it!</button>
                </div>
                <div className="request-details">
                  1 Request This Month
                </div>
              </div>
            </div>
          </div> */}

        {/* <div className="section-container">
            <h3>Recent Requests</h3>
            <div className="recent-requests">
              <table className="recent-requests-table">
                <thead className="recent-col1">
                  <tr>
                    <th>TIME</th>
                    <th>STATUS</th>
                    <th>USER AGENT</th>
                  </tr>
                </thead>
                <tbody className="recent-col2">
                  <tr>
                    <td>3h ago</td>
                    <td>
                      <span className="status-error">401</span>
                    </td>
                    <td>
                      <button className="try-it-btn">Try It!</button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="request-summary">
                <span>1 Request This Month</span>
              </div>
            </div>
          </div> */}

        <div className="section-container body-params">
          <h3>Body Parameters</h3>
          <div className="params-table">
            <div className="table-header">
              <div className="table-cell">Name</div>
              <div className="table-cell">Type</div>
              <div className="table-cell">Required</div>
              <div className="table-cell">Description</div>
              <div className="table-cell">Example</div>
            </div>
            <div className="table-row">
              <div className="table-cell">provider</div>
              <div className="table-cell">number</div>
              <div className="table-cell">Yes</div>
              <div className="table-cell">
                Provider ID for the FCRN verification service.
              </div>
              <div className="table-cell">5</div>
            </div>
            <div className="table-row">
              <div className="table-cell">fcrn</div>
              <div className="table-cell">string</div>
              <div className="table-cell">Yes</div>
              <div className="table-cell">
                FCRN Verification
              </div>
              <div className="table-cell">F04663</div>
            </div>
          </div>
        </div>



        {/* <div className="section-container">
            <h3>Example Request</h3>
            <SyntaxHighlighter
              language="bash"
              style={dracula}
              customStyle={{
                backgroundColor: '#1e2329',
                borderRadius: '8px',
                padding: '20px',
                marginTop: '10px'
              }}
            >
              {`curl --location 'https://uat0.fstac.tech/id/kyb/gst-search-by-pan' \\
  --header 'Content-Type: application/json' \\
  --header 'Authorization: Basic SzYwTTlQMmJpblprX1ZxNEFBQUE6NGZmYTRiMGVhYmFjZDk5ZmRmNzQ0MGFhNGFlMjNlZWM=' \\
  --data '{
      "provider": 5,
      "pan": "AAFCL5948L"
  }'`}
            </SyntaxHighlighter>
          </div> */}

        <ResponseStatus currentApi="Udyam generate otp" />

        <div className="page-footer">
          <div className="update-info">
            <span>Updated 2 days ago</span>
          </div>
          <div className="feedback-section">
            <span>Did this page help you?</span>
            <button className="feedback-btn yes">Yes</button>
            <button className="feedback-btn no">No</button>
          </div>
        </div>
      </div>
    ),
    "PAN Validate": (
      <div className="api-documentation">
        <div className="api-endpoint">
          <span className="method-post">POST</span>
          <span className="endpoint-url">https://uat0.fstac.tech/id/validate/pan
          </span>
        </div>


        <p className="api-description">
          This API enables you to retrieve GST details using a PAN number. It provides insights about registered GST numbers linked to the provided PAN.
        </p>

        {/* <div className="section-container">
        <h3>Recent Requests</h3>
        <div className="recent-requests">
          <div className="request-item">
            <div className="request-time">1h ago</div>
            <div className="request-status error">401</div>
            <div className="request-agent">
              <button className="try-it-btn">Try it!</button>
            </div>
            <div className="request-details">
              1 Request This Month
            </div>
          </div>
        </div>
      </div> */}

        {/* <div className="section-container">
        <h3>Recent Requests</h3>
        <div className="recent-requests">
          <table className="recent-requests-table">
            <thead className="recent-col1">
              <tr>
                <th>TIME</th>
                <th>STATUS</th>
                <th>USER AGENT</th>
              </tr>
            </thead>
            <tbody className="recent-col2">
              <tr>
                <td>3h ago</td>
                <td>
                  <span className="status-error">401</span>
                </td>
                <td>
                  <button className="try-it-btn">Try It!</button>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="request-summary">
            <span>1 Request This Month</span>
          </div>
        </div>
      </div> */}

        <div className="section-container body-params">
          <h3>Body Parameters</h3>
          <div className="params-table">
            <div className="table-header">
              <div className="table-cell">Name</div>
              <div className="table-cell">Type</div>
              <div className="table-cell">Required</div>
              <div className="table-cell">Description</div>
              <div className="table-cell">Example</div>
            </div>
            <div className="table-row">
              <div className="table-cell">provider</div>
              <div className="table-cell">number</div>
              <div className="table-cell">Yes</div>
              <div className="table-cell">
                PAN verification service.
              </div>
              <div className="table-cell">5</div>
            </div>
            <div className="table-row">
              <div className="table-cell">pan</div>
              <div className="table-cell">string</div>
              <div className="table-cell">Yes</div>
              <div className="table-cell">
                PAN Verification
              </div>
              <div className="table-cell">FEWPS2079E</div>
            </div>
          </div>
        </div>



        {/* <div className="section-container">
        <h3>Example Request</h3>
        <SyntaxHighlighter
          language="bash"
          style={dracula}
          customStyle={{
            backgroundColor: '#1e2329',
            borderRadius: '8px',
            padding: '20px',
            marginTop: '10px'
          }}
        >
          {`curl --location 'https://uat0.fstac.tech/id/kyb/gst-search-by-pan' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: Basic SzYwTTlQMmJpblprX1ZxNEFBQUE6NGZmYTRiMGVhYmFjZDk5ZmRmNzQ0MGFhNGFlMjNlZWM=' \\
--data '{
  "provider": 5,
  "pan": "AAFCL5948L"
}'`}
        </SyntaxHighlighter>
      </div> */}

        <ResponseStatus currentApi="PAN Validate" />


        <div className="page-footer">
          <div className="update-info">
            <span>Updated 2 days ago</span>
          </div>
          <div className="feedback-section">
            <span>Did this page help you?</span>
            <button className="feedback-btn yes">Yes</button>
            <button className="feedback-btn no">No</button>
          </div>
        </div>
      </div>
    ),
    "PAN profile": (
      <div className="api-documentation">
        <div className="api-endpoint">
          <span className="method-post">POST</span>
          <span className="endpoint-url">https://uat0.fstac.tech/id/kyc/pan-profile
          </span>
        </div>


        <p className="api-description">
          This API enables you to retrieve GST details using a PAN number. It provides insights about registered GST numbers linked to the provided PAN.
        </p>

        {/* <div className="section-container">
        <h3>Recent Requests</h3>
        <div className="recent-requests">
          <div className="request-item">
            <div className="request-time">1h ago</div>
            <div className="request-status error">401</div>
            <div className="request-agent">
              <button className="try-it-btn">Try it!</button>
            </div>
            <div className="request-details">
              1 Request This Month
            </div>
          </div>
        </div>
      </div> */}

        {/* <div className="section-container">
        <h3>Recent Requests</h3>
        <div className="recent-requests">
          <table className="recent-requests-table">
            <thead className="recent-col1">
              <tr>
                <th>TIME</th>
                <th>STATUS</th>
                <th>USER AGENT</th>
              </tr>
            </thead>
            <tbody className="recent-col2">
              <tr>
                <td>3h ago</td>
                <td>
                  <span className="status-error">401</span>
                </td>
                <td>
                  <button className="try-it-btn">Try It!</button>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="request-summary">
            <span>1 Request This Month</span>
          </div>
        </div>
      </div> */}

        <div className="section-container body-params">
          <h3>Body Parameters</h3>
          <div className="params-table">
            <div className="table-header">
              <div className="table-cell">Name</div>
              <div className="table-cell">Type</div>
              <div className="table-cell">Required</div>
              <div className="table-cell">Description</div>
              <div className="table-cell">Example</div>
            </div>
            <div className="table-row">
              <div className="table-cell">provider</div>
              <div className="table-cell">number</div>
              <div className="table-cell">Yes</div>
              <div className="table-cell">
                PAN Profile
              </div>
              <div className="table-cell">5</div>
            </div>
            <div className="table-row">
              <div className="table-cell">pan</div>
              <div className="table-cell">string</div>
              <div className="table-cell">Yes</div>
              <div className="table-cell">
                PAN Profile
              </div>
              <div className="table-cell">CNKPM1983R</div>
            </div>
          </div>
        </div>



        {/* <div className="section-container">
        <h3>Example Request</h3>
        <SyntaxHighlighter
          language="bash"
          style={dracula}
          customStyle={{
            backgroundColor: '#1e2329',
            borderRadius: '8px',
            padding: '20px',
            marginTop: '10px'
          }}
        >
          {`curl --location 'https://uat0.fstac.tech/id/kyb/gst-search-by-pan' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: Basic SzYwTTlQMmJpblprX1ZxNEFBQUE6NGZmYTRiMGVhYmFjZDk5ZmRmNzQ0MGFhNGFlMjNlZWM=' \\
--data '{
  "provider": 5,
  "pan": "AAFCL5948L"
}'`}
        </SyntaxHighlighter>
      </div> */}

        <ResponseStatus currentApi="PAN Profile" />



        <div className="page-footer">
          <div className="update-info">
            <span>Updated 2 days ago</span>
          </div>
          <div className="feedback-section">
            <span>Did this page help you?</span>
            <button className="feedback-btn yes">Yes</button>
            <button className="feedback-btn no">No</button>
          </div>
        </div>
      </div>
    ),
    "Voter Id Verification": (
      <div className="api-documentation">
        <div className="api-endpoint">
          <span className="method-post">POST</span>
          <span className="endpoint-url">https://uat0.fstac.tech/id/kyc/voter-id-verification
          </span>
        </div>


        <p className="api-description">
          This API enables you to retrieve GST details using a PAN number. It provides insights about registered GST numbers linked to the provided PAN.
        </p>

        {/* <div className="section-container">
        <h3>Recent Requests</h3>
        <div className="recent-requests">
          <div className="request-item">
            <div className="request-time">1h ago</div>
            <div className="request-status error">401</div>
            <div className="request-agent">
              <button className="try-it-btn">Try it!</button>
            </div>
            <div className="request-details">
              1 Request This Month
            </div>
          </div>
        </div>
      </div> */}

        {/* <div className="section-container">
        <h3>Recent Requests</h3>
        <div className="recent-requests">
          <table className="recent-requests-table">
            <thead className="recent-col1">
              <tr>
                <th>TIME</th>
                <th>STATUS</th>
                <th>USER AGENT</th>
              </tr>
            </thead>
            <tbody className="recent-col2">
              <tr>
                <td>3h ago</td>
                <td>
                  <span className="status-error">401</span>
                </td>
                <td>
                  <button className="try-it-btn">Try It!</button>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="request-summary">
            <span>1 Request This Month</span>
          </div>
        </div>
      </div> */}

        <div className="section-container body-params">
          <h3>Body Parameters</h3>
          <div className="params-table">
            <div className="table-header">
              <div className="table-cell">Name</div>
              <div className="table-cell">Type</div>
              <div className="table-cell">Required</div>
              <div className="table-cell">Description</div>
              <div className="table-cell">Example</div>
            </div>
            <div className="table-row">
              <div className="table-cell">provider</div>
              <div className="table-cell">number</div>
              <div className="table-cell">Yes</div>
              <div className="table-cell">
                Voter Id Verification
              </div>
              <div className="table-cell">5</div>
            </div>
            <div className="table-row">
              <div className="table-cell">epicNumber</div>
              <div className="table-cell">string</div>
              <div className="table-cell">Yes</div>
              <div className="table-cell">
                Voter Id Verification
              </div>
              <div className="table-cell">OR/09/050/628369</div>
            </div>
          </div>
        </div>



        {/* <div className="section-container">
        <h3>Example Request</h3>
        <SyntaxHighlighter
          language="bash"
          style={dracula}
          customStyle={{
            backgroundColor: '#1e2329',
            borderRadius: '8px',
            padding: '20px',
            marginTop: '10px'
          }}
        >
          {`curl --location 'https://uat0.fstac.tech/id/kyb/gst-search-by-pan' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: Basic SzYwTTlQMmJpblprX1ZxNEFBQUE6NGZmYTRiMGVhYmFjZDk5ZmRmNzQ0MGFhNGFlMjNlZWM=' \\
--data '{
  "provider": 5,
  "pan": "AAFCL5948L"
}'`}
        </SyntaxHighlighter>
      </div> */}
        <ResponseStatus currentApi="Voter Id Verification" />

        <div className="page-footer">
          <div className="update-info">
            <span>Updated 2 days ago</span>
          </div>
          <div className="feedback-section">
            <span>Did this page help you?</span>
            <button className="feedback-btn yes">Yes</button>
            <button className="feedback-btn no">No</button>
          </div>
        </div>
      </div>
    ),
    "Passport Verification": (
      <div className="api-documentation">
        <div className="api-endpoint">
          <span className="method-post">POST</span>
          <span className="endpoint-url">https://uat0.fstac.tech/id/kyc/passport-verification
          </span>
        </div>


        <p className="api-description">
          This API enables you to retrieve GST details using a PAN number. It provides insights about registered GST numbers linked to the provided PAN.
        </p>

        {/* <div className="section-container">
        <h3>Recent Requests</h3>
        <div className="recent-requests">
          <div className="request-item">
            <div className="request-time">1h ago</div>
            <div className="request-status error">401</div>
            <div className="request-agent">
              <button className="try-it-btn">Try it!</button>
            </div>
            <div className="request-details">
              1 Request This Month
            </div>
          </div>
        </div>
      </div> */}

        {/* <div className="section-container">
        <h3>Recent Requests</h3>
        <div className="recent-requests">
          <table className="recent-requests-table">
            <thead className="recent-col1">
              <tr>
                <th>TIME</th>
                <th>STATUS</th>
                <th>USER AGENT</th>
              </tr>
            </thead>
            <tbody className="recent-col2">
              <tr>
                <td>3h ago</td>
                <td>
                  <span className="status-error">401</span>
                </td>
                <td>
                  <button className="try-it-btn">Try It!</button>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="request-summary">
            <span>1 Request This Month</span>
          </div>
        </div>
      </div> */}

        <div className="section-container body-params">
          <h3>Body Parameters</h3>
          <div className="params-table">
            <div className="table-header">
              <div className="table-cell">Name</div>
              <div className="table-cell">Type</div>
              <div className="table-cell">Required</div>
              <div className="table-cell">Description</div>
              <div className="table-cell">Example</div>
            </div>
            <div className="table-row">
              <div className="table-cell">provider</div>
              <div className="table-cell">number</div>
              <div className="table-cell">Yes</div>
              <div className="table-cell">
                Passport Verification
              </div>
              <div className="table-cell">5</div>
            </div>
            <div className="table-row">
              <div className="table-cell">fileNumber</div>
              <div className="table-cell">string</div>
              <div className="table-cell">Yes</div>
              <div className="table-cell">
                Passport Verification
              </div>
              <div className="table-cell">BN1065771399323</div>
            </div>
          </div>
        </div>



        {/* <div className="section-container">
        <h3>Example Request</h3>
        <SyntaxHighlighter
          language="bash"
          style={dracula}
          customStyle={{
            backgroundColor: '#1e2329',
            borderRadius: '8px',
            padding: '20px',
            marginTop: '10px'
          }}
        >
          {`curl --location 'https://uat0.fstac.tech/id/kyb/gst-search-by-pan' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: Basic SzYwTTlQMmJpblprX1ZxNEFBQUE6NGZmYTRiMGVhYmFjZDk5ZmRmNzQ0MGFhNGFlMjNlZWM=' \\
--data '{
  "provider": 5,
  "pan": "AAFCL5948L"
}'`}
        </SyntaxHighlighter>
      </div> */}

        <ResponseStatus currentApi="Passport Verification" />

        <div className="page-footer">
          <div className="update-info">
            <span>Updated 2 days ago</span>
          </div>
          <div className="feedback-section">
            <span>Did this page help you?</span>
            <button className="feedback-btn yes">Yes</button>
            <button className="feedback-btn no">No</button>
          </div>
        </div>
      </div>
    ),
    "Face Match": (
      <div className="api-documentation">
        <div className="api-endpoint">
          <span className="method-post">POST</span>
          <span className="endpoint-url">https://uat0.fstac.tech/id/biometric/facematch
          </span>
        </div>


        <p className="api-description">
          This API enables you to retrieve GST details using a PAN number. It provides insights about registered GST numbers linked to the provided PAN.
        </p>

        {/* <div className="section-container">
        <h3>Recent Requests</h3>
        <div className="recent-requests">
          <div className="request-item">
            <div className="request-time">1h ago</div>
            <div className="request-status error">401</div>
            <div className="request-agent">
              <button className="try-it-btn">Try it!</button>
            </div>
            <div className="request-details">
              1 Request This Month
            </div>
          </div>
        </div>
      </div> */}

        {/* <div className="section-container">
        <h3>Recent Requests</h3>
        <div className="recent-requests">
          <table className="recent-requests-table">
            <thead className="recent-col1">
              <tr>
                <th>TIME</th>
                <th>STATUS</th>
                <th>USER AGENT</th>
              </tr>
            </thead>
            <tbody className="recent-col2">
              <tr>
                <td>3h ago</td>
                <td>
                  <span className="status-error">401</span>
                </td>
                <td>
                  <button className="try-it-btn">Try It!</button>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="request-summary">
            <span>1 Request This Month</span>
          </div>
        </div>
      </div> */}

        <div className="section-container body-params">
          <h3>Body Parameters</h3>
          <div className="params-table">
            <div className="table-header">
              <div className="table-cell">Name</div>
              <div className="table-cell">Type</div>
              <div className="table-cell">Required</div>
              <div className="table-cell">Description</div>
              <div className="table-cell">Example</div>
            </div>
            <div className="table-row">
              <div className="table-cell">provider</div>
              <div className="table-cell">number</div>
              <div className="table-cell">Yes</div>
              <div className="table-cell">
                Face Match
              </div>
              <div className="table-cell">5</div>
            </div>
            <div className="table-row">
              <div className="table-cell">documents</div>
              <div className="table-cell">string</div>
              <div className="table-cell">Yes</div>
              <div className="table-cell">
                Face Match
              </div>
              <div className="table-cell">https://techind-dev.s3.ap-south-1.amazonaws.com/gaon/document/1707805859571.jpg</div>
            </div>
            <div className="table-row">
              <div className="table-cell">Selfie</div>
              <div className="table-cell">string</div>
              <div className="table-cell">Yes</div>
              <div className="table-cell">
                Face Match
              </div>
              <div className="table-cell">https://techind-dev.s3.ap-south-1.amazonaws.com/gaon/document/1707805859571.jpg</div>
            </div>
          </div>
        </div>



        {/* <div className="section-container">
        <h3>Example Request</h3>
        <SyntaxHighlighter
          language="bash"
          style={dracula}
          customStyle={{
            backgroundColor: '#1e2329',
            borderRadius: '8px',
            padding: '20px',
            marginTop: '10px'
          }}
        >
          {`curl --location 'https://uat0.fstac.tech/id/kyb/gst-search-by-pan' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: Basic SzYwTTlQMmJpblprX1ZxNEFBQUE6NGZmYTRiMGVhYmFjZDk5ZmRmNzQ0MGFhNGFlMjNlZWM=' \\
--data '{
  "provider": 5,
  "pan": "AAFCL5948L"
}'`}
        </SyntaxHighlighter>
      </div> */}
        <ResponseStatus currentApi="Face Match" />

        <div className="page-footer">
          <div className="update-info">
            <span>Updated 2 days ago</span>
          </div>
          <div className="feedback-section">
            <span>Did this page help you?</span>
            <button className="feedback-btn yes">Yes</button>
            <button className="feedback-btn no">No</button>
          </div>
        </div>
      </div>
    ),
    "Liveness Check": (
      <div className="api-documentation">
        <div className="api-endpoint">
          <span className="method-post">POST</span>
          <span className="endpoint-url">https://uat0.fstac.tech/id/biometric/livenessurl
          </span>
        </div>


        <p className="api-description">
          This API enables you to retrieve GST details using a PAN number. It provides insights about registered GST numbers linked to the provided PAN.
        </p>

        {/* <div className="section-container">
        <h3>Recent Requests</h3>
        <div className="recent-requests">
          <div className="request-item">
            <div className="request-time">1h ago</div>
            <div className="request-status error">401</div>
            <div className="request-agent">
              <button className="try-it-btn">Try it!</button>
            </div>
            <div className="request-details">
              1 Request This Month
            </div>
          </div>
        </div>
      </div> */}

        {/* <div className="section-container">
        <h3>Recent Requests</h3>
        <div className="recent-requests">
          <table className="recent-requests-table">
            <thead className="recent-col1">
              <tr>
                <th>TIME</th>
                <th>STATUS</th>
                <th>USER AGENT</th>
              </tr>
            </thead>
            <tbody className="recent-col2">
              <tr>
                <td>3h ago</td>
                <td>
                  <span className="status-error">401</span>
                </td>
                <td>
                  <button className="try-it-btn">Try It!</button>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="request-summary">
            <span>1 Request This Month</span>
          </div>
        </div>
      </div> */}

        <div className="section-container body-params">
          <h3>Body Parameters</h3>
          <div className="params-table">
            <div className="table-header">
              <div className="table-cell">Name</div>
              <div className="table-cell">Type</div>
              <div className="table-cell">Required</div>
              <div className="table-cell">Description</div>
              <div className="table-cell">Example</div>
            </div>
            <div className="table-row">
              <div className="table-cell">provider</div>
              <div className="table-cell">number</div>
              <div className="table-cell">Yes</div>
              <div className="table-cell">
                imageURL Check
              </div>
              <div className="table-cell">5</div>
            </div>
            <div className="table-row">
              <div className="table-cell">imageURL</div>
              <div className="table-cell">string</div>
              <div className="table-cell">Yes</div>
              <div className="table-cell">
                imageURL check
              </div>
              <div className="table-cell">https://techind-dev.s3.ap-south-1.amazonaws.com/gaon/document/1707805859571.jpg</div>
            </div>
          </div>
        </div>



        {/* <div className="section-container">
        <h3>Example Request</h3>
        <SyntaxHighlighter
          language="bash"
          style={dracula}
          customStyle={{
            backgroundColor: '#1e2329',
            borderRadius: '8px',
            padding: '20px',
            marginTop: '10px'
          }}
        >
          {`curl --location 'https://uat0.fstac.tech/id/kyb/gst-search-by-pan' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: Basic SzYwTTlQMmJpblprX1ZxNEFBQUE6NGZmYTRiMGVhYmFjZDk5ZmRmNzQ0MGFhNGFlMjNlZWM=' \\
--data '{
  "provider": 5,
  "pan": "AAFCL5948L"
}'`}
        </SyntaxHighlighter>
      </div> */}

        <ResponseStatus currentApi="Liveness Check" />

        <div className="page-footer">
          <div className="update-info">
            <span>Updated 2 days ago</span>
          </div>
          <div className="feedback-section">
            <span>Did this page help you?</span>
            <button className="feedback-btn yes">Yes</button>
            <button className="feedback-btn no">No</button>
          </div>
        </div>
      </div>
    ),
    "Phone number to pan search": (
      <div className="api-documentation">
        <div className="api-endpoint">
          <span className="method-post">POST</span>
          <span className="endpoint-url">https://uat0.fstac.tech/id/kyc/phone-to-pan-search
          </span>
        </div>


        <p className="api-description">
          This API enables you to retrieve GST details using a PAN number. It provides insights about registered GST numbers linked to the provided PAN.
        </p>

        {/* <div className="section-container">
        <h3>Recent Requests</h3>
        <div className="recent-requests">
          <div className="request-item">
            <div className="request-time">1h ago</div>
            <div className="request-status error">401</div>
            <div className="request-agent">
              <button className="try-it-btn">Try it!</button>
            </div>
            <div className="request-details">
              1 Request This Month
            </div>
          </div>
        </div>
      </div> */}

        {/* <div className="section-container">
        <h3>Recent Requests</h3>
        <div className="recent-requests">
          <table className="recent-requests-table">
            <thead className="recent-col1">
              <tr>
                <th>TIME</th>
                <th>STATUS</th>
                <th>USER AGENT</th>
              </tr>
            </thead>
            <tbody className="recent-col2">
              <tr>
                <td>3h ago</td>
                <td>
                  <span className="status-error">401</span>
                </td>
                <td>
                  <button className="try-it-btn">Try It!</button>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="request-summary">
            <span>1 Request This Month</span>
          </div>
        </div>
      </div> */}

        <div className="section-container body-params">
          <h3>Body Parameters</h3>
          <div className="params-table">
            <div className="table-header">
              <div className="table-cell">Name</div>
              <div className="table-cell">Type</div>
              <div className="table-cell">Required</div>
              <div className="table-cell">Description</div>
              <div className="table-cell">Example</div>
            </div>
            <div className="table-row">
              <div className="table-cell">provider</div>
              <div className="table-cell">number</div>
              <div className="table-cell">Yes</div>
              <div className="table-cell">
                imageURL Check
              </div>
              <div className="table-cell">11</div>
            </div>
            <div className="table-row">
              <div className="table-cell">firstname</div>
              <div className="table-cell">string</div>
              <div className="table-cell">Yes</div>
              <div className="table-cell">
                user_firstname
              </div>
              <div className="table-cell">Darshan</div>
            </div>
            <div className="table-row">
              <div className="table-cell">lastname</div>
              <div className="table-cell">string</div>
              <div className="table-cell">Yes</div>
              <div className="table-cell">
                user_lastname
              </div>
              <div className="table-cell">GORDHAN</div>
            </div>
            <div className="table-row">
              <div className="table-cell">phoneNumber</div>
              <div className="table-cell">number</div>
              <div className="table-cell">Yes</div>
              <div className="table-cell">
                user_phonenumber
              </div>
              <div className="table-cell">9996889976</div>
            </div>
          </div>
        </div>



        {/* <div className="section-container">
        <h3>Example Request</h3>
        <SyntaxHighlighter
          language="bash"
          style={dracula}
          customStyle={{
            backgroundColor: '#1e2329',
            borderRadius: '8px',
            padding: '20px',
            marginTop: '10px'
          }}
        >
          {`curl --location 'https://uat0.fstac.tech/id/kyb/gst-search-by-pan' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: Basic SzYwTTlQMmJpblprX1ZxNEFBQUE6NGZmYTRiMGVhYmFjZDk5ZmRmNzQ0MGFhNGFlMjNlZWM=' \\
--data '{
  "provider": 5,
  "pan": "AAFCL5948L"
}'`}
        </SyntaxHighlighter>
      </div> */}

        <ResponseStatus currentApi="Phone number to pan search" />

        <div className="page-footer">
          <div className="update-info">
            <span>Updated 2 days ago</span>
          </div>
          <div className="feedback-section">
            <span>Did this page help you?</span>
            <button className="feedback-btn yes">Yes</button>
            <button className="feedback-btn no">No</button>
          </div>
        </div>
      </div>
    ),
    "Digilocker apis": (
      <div className="api-documentation">
        <div className="api-endpoint">
          <span className="method-post">POST</span>
          <span className="endpoint-url">'https://prod.fstac.tech/id/digilocker/authorizationurl
          </span>
        </div>
        <p className="api-description">
          This API enables you to retrieve GST details using a PAN number. It provides insights about registered GST numbers linked to the provided PAN.
        </p>
        {/* <div className="section-container">
        <h3>Recent Requests</h3>
        <div className="recent-requests">
          <div className="request-item">
            <div className="request-time">1h ago</div>
            <div className="request-status error">401</div>
            <div className="request-agent">
              <button className="try-it-btn">Try it!</button>
            </div>
            <div className="request-details">
              1 Request This Month
            </div>
          </div>
        </div>
      </div> */}

        {/* <div className="section-container">
        <h3>Recent Requests</h3>
        <div className="recent-requests">
          <table className="recent-requests-table">
            <thead className="recent-col1">
              <tr>
                <th>TIME</th>
                <th>STATUS</th>
                <th>USER AGENT</th>
              </tr>
            </thead>
            <tbody className="recent-col2">
              <tr>
                <td>3h ago</td>
                <td>
                  <span className="status-error">401</span>
                </td>
                <td>
                  <button className="try-it-btn">Try It!</button>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="request-summary">
            <span>1 Request This Month</span>
          </div>
        </div>
      </div> */}

        <div className="section-container body-params">
          <h3>Body Parameters</h3>
          <div className="params-table">
            <div className="table-header">
              <div className="table-cell">Name</div>
              <div className="table-cell">Type</div>
              <div className="table-cell">Required</div>
              <div className="table-cell">Description</div>
              <div className="table-cell">Example</div>
            </div>
            <div className="table-row">
              <div className="table-cell">provider</div>
              <div className="table-cell">number</div>
              <div className="table-cell">Yes</div>
              <div className="table-cell">
                imageURL Check
              </div>
              <div className="table-cell">5</div>
            </div>
            <div className="table-row">
              <div className="table-cell">imageURL</div>
              <div className="table-cell">string</div>
              <div className="table-cell">Yes</div>
              <div className="table-cell">
                imageURL check
              </div>
              <div className="table-cell">https://techind-dev.s3.ap-south-1.amazonaws.com/gaon/document/1707805859571.jpg</div>
            </div>
          </div>
        </div>



        {/* <div className="section-container">
        <h3>Example Request</h3>
        <SyntaxHighlighter
          language="bash"
          style={dracula}
          customStyle={{
            backgroundColor: '#1e2329',
            borderRadius: '8px',
            padding: '20px',
            marginTop: '10px'
          }}
        >
          {`curl --location 'https://uat0.fstac.tech/id/kyb/gst-search-by-pan' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: Basic SzYwTTlQMmJpblprX1ZxNEFBQUE6NGZmYTRiMGVhYmFjZDk5ZmRmNzQ0MGFhNGFlMjNlZWM=' \\
--data '{
  "provider": 5,
  "pan": "AAFCL5948L"
}'`}
        </SyntaxHighlighter>
      </div> */}

        <ResponseStatus currentApi="Digilocker apis" />

        <div className="page-footer">
          <div className="update-info">
            <span>Updated 2 days ago</span>
          </div>
          <div className="feedback-section">
            <span>Did this page help you?</span>
            <button className="feedback-btn yes">Yes</button>
            <button className="feedback-btn no">No</button>
          </div>
        </div>
      </div>
    ),
  };

  // if (!isLoggedIn) {
  //   return (
  //     <div className="login-page-container">
  //       <div className="login-box">
  //         <h1 className="login-logo">Fstac</h1>
  //         <h3 className="login-title">Welcome</h3>
  //         <p className="login-subtitle">Log in to Fstac Platform.</p>
  //         {isRegistered ? (
  //           <form className="login-form" onSubmit={handleLogin}>
  //             <div className="form-input-group">
  //               <label htmlFor="password" className="form-label">
  //                 Password
  //               </label>
  //               <div className="form-password-wrapper">
  //                 <input
  //                   type="password"
  //                   id="password"
  //                   className="form-input"
  //                   placeholder="Enter your password"
  //                   value={password}
  //                   onChange={(e) => setPassword(e.target.value)}
  //                   required
  //                 />
  //               </div>
  //             </div>
  //             <button type="submit" className="login-submit-btn">
  //               Continue
  //             </button>
  //           </form>
  //         ) : (
  //           <>
  //             <form className="login-form" onSubmit={handleLogin}>
  //               <div className="form-input-group">
  //                 <label htmlFor="email" className="form-label">
  //                   Email address
  //                 </label>
  //                 <input
  //                   type="email"
  //                   id="email"
  //                   className="form-input"
  //                   placeholder="Enter your email"
  //                   value={email}
  //                   onChange={(e) => setEmail(e.target.value)}
  //                   required
  //                 />
  //               </div>
  //               <div className="form-input-group">
  //                 <label htmlFor="password" className="form-label">
  //                   Password
  //                 </label>
  //                 <div className="form-password-wrapper">
  //                   <input
  //                     type="password"
  //                     id="password"
  //                     className="form-input"
  //                     placeholder="Enter your password"
  //                     value={password}
  //                     onChange={(e) => setPassword(e.target.value)}
  //                     required
  //                   />
  //                 </div>
  //               </div>
  //               <button type="submit" className="login-submit-btn">
  //                 Continue
  //               </button>
  //             </form>
  //             <div className="divider">OR</div>
  //             <button className="google-login-btn" onClick={handleGoogleLogin}>
  //               Continue with Google
  //             </button>
  //           </>
  //         )}
  //         <div className="login-options">
  //           <a href="#" className="forgot-password-link">
  //             Forgot password?
  //           </a>
  //           <p className="signup-link">
  //             Don't have an account? <a href="#">Sign up</a>
  //           </p>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <div className="api-page-container">
      {/* <header className="api-header">
        <div className="header-logo">Fstac</div>
        <div className="header-actions">
          <nav className="nav-links">
            <a href="/" className="nav-link">Home</a>
            <a href="/faq" className="nav-link">FAQs</a>
            <a href="/dashboard" className="nav-link">Dashboard</a>
            <a href="/resources" className="nav-link">Resources</a>
          </nav>
          <div className="header-user-info">
            <p>Logged in as: {email}</p>
          </div>
        </div>
      </header> */}

      <div className="api-search-container">
        <input
          type="text"
          placeholder="Search API Reference..."
          className="api-search-input"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      <div className="api-content">
        <aside className="api-sidebar">
          <ul className="sidebar-list">
            {filteredItems.map((item, index) => (
              <li
                key={index}
                className={`sidebar-item ${selectedSection === item ? "active" : ""}`}
                onClick={() => setSelectedSection(item)}
              >
                {item}
              </li>
            ))}
          </ul>
        </aside>
        <main className="api-main-content">
          <h1 className="main-content-title">{selectedSection}</h1>
          <div className="main-content-text">
            {sectionContent[selectedSection] || "Content not found."}
          </div>
        </main>
      </div>
    </div>
  );
};