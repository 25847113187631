import { useEffect, useRef } from "react";
import "../styles/description.css";
import eggGloabl from "../assets/images/globalBrowserIcon.svg";
import seemless from "../assets/images/seemlessIntegration.svg";
import enhanceSec from "../assets/images/enhanceSecurity.svg";
import optimizing from "../assets/images/optimizing.svg";
import simplified from "../assets/images/simplified.svg";
import wideAdoption from "../assets/images/wideAdoption.svg";
import instantInsight from "../assets/images/instantInsight.svg";
import crossDevice from "../assets/images/crossDevice.svg";
import ecommerse from "../assets/images/eCommerceB.svg";
import bankingB from "../assets/images/bankingB.svg";
import onlineB from "../assets/images/onlineGamingB.svg";
import streamingB from "../assets/images/streamingServiceB.svg";
import travelB from "../assets/images/travelB.svg";
import { MultipleItems } from "../pages/slider";
import browdstric from "../assets/images/CrowdStrike_logo.png";
import aws from "../assets/images/Amazon_Web.png";
import elastic from "../assets/images/elastic.png";
import sysdin from "../assets/images/sysdig-inc-logo-vector 1.png";
import dataLogo from "../assets/images/dataLogo.png";
import howitwork from "../assets/images/howitwork.svg";
import onlineGaming from "../assets/images/onlineGaming1.svg";
import financialService from "../assets/images/financialService.svg";
import { Helmet } from "react-helmet";

export const DeviceAndBrowserIntelligence = () => {
  return (
    <><Helmet> <meta charSet="utf-8" />
      <title>Cutting-Edge Browser Fingeprinting Solutions | Fstac</title>
      <meta
       name="description" 
       content=" Our leading browser fingerprinting and browser intelligence solutions can help improve your enterprise’s security and help improve marketing efficiency. Talk to Us Today!" />
      <link rel="canonical" href="https://www.fstac.tech/device-intelligence" /></Helmet>
      <div className="singlePage">
        <div className="container ">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-12 d-flex justify-content-center">
              <div className="container1">
                <div className="outer-circle">
                  <div className="inner-circle">
                    <div className="content">
                      <div>
                        <button>FRAUD WATCH</button>
                      </div>
                      <div className="contentHeading circle_header">
                        Device Intelligence
                      </div>
                      <div className="description innerCircleContent">
                        <p>
                        Device Intelligence helps businesses prevent fraud by accurately identifying devices and tracking behavior across web and mobile platforms. Using device fingerprinting and browser detection, our solutions offer real-time insights for proactive fraud prevention. With advanced device identification and behavioral analytics, companies can strengthen user authentication, ensure regulatory compliance, and enhance security, minimizing the risk of fraud and malicious activities across digital transactions.
                        </p>
                      </div>
                    </div>
                    <div className="outerCircleIcon1">
                      <img src={eggGloabl} alt="" />
                    </div>
                    <div className="outerCircleIcon2">
                      <img src={eggGloabl} alt="" />
                    </div>
                  </div>
                  <div className="outerCircleIcon">
                    <img src={eggGloabl} alt="" />
                  </div>
                </div>
                <div className="outerBox"></div>
                <div className="content_mobileView">
                  <div>
                    <button>FRAUD WATCH</button>
                  </div>
                  <div className="contentHeading circle_header">
                    Device Intelligence
                  </div>
                  <div className="description innerCircleContent">
                    <p>
                    Device Intelligence helps businesses prevent fraud by accurately identifying devices and tracking behavior across web and mobile platforms. Using device fingerprinting and browser detection, our solutions offer real-time insights for proactive fraud prevention. With advanced device identification and behavioral analytics, companies can strengthen user authentication, ensure regulatory compliance, and enhance security, minimizing the risk of fraud and malicious activities across digital transactions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row">
    <div className="col-lg-12">
      <div className="NewBox">
        <p>hello</p>
      </div>
    </div>
  </div> */}
        </div>
        {/* <div className="container seller_box">
      <div className="row justify-content-center align-items-center">
        <div className="col-lg-10">
          <div className="seller_heading">
            More than 100+ sellers who trust Fstac
          </div>
        </div>
        <div className="col-lg-10 pt-5">
          <div className="sellerImage d-flex justify-content-between align-items-center">
            <div>
              <img className="whoWeTrustImage" src={sysdin} alt="" />
            </div>
            <div>
              <img className="whoWeTrustImage" src={browdstric} alt="" />
            </div>
            <div>
              <img className="whoWeTrustImage" src={aws} alt="" />
            </div>
            <div>
              <img className="whoWeTrustImage" src={dataLogo} alt="" />
            </div>
            <div>
              <img className="whoWeTrustImage" src={elastic} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div> */}
        <div className="container howItWork" style={{ position: "relative", marginTop:"10px"}}>
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-10 d-flex  justify-content-between howitworkContent_">
              <div className="box-content" style={{ width: "45%" }}>
                <div className="contentHowitWork">
                  <div className="itwork">How It Works</div>
                  <div className="howboldHeading">
                    A Multi-Layered <br /> Security Approach
                  </div>
                  <div>
                    <p>
                      Fstac’s Device and Browser Intelligence solutions provide
                      robust, multi-dimensional protection by combining data from
                      a variety of sources, sophisticated analysis techniques, and
                      cutting-edge machine learning algorithms. By integrating the
                      best features, Fstac offers a detailed, holistic approach to
                      fraud detection and security.
                    </p>
                  </div>
                </div>
              </div>
              <div className="box-image" style={{ width: "45%" }}>
                <div className="howitworkImage">
                  <img src={howitwork} alt="" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center align-items-center pt-4">
            <div className="col-lg-10 d-flex  justify-content-between subBoxContentRow">
              <div className="subBoxContent" style={{ width: "45%" }}>
                <div className="howDescriptio">
                  <div className="headingHow1">
                    Comprehensive Device Fingerprinting
                  </div>
                  <div>
                    <p>
                      Fstac gathers stable, persistent attributes from devices,
                      ensuring that each device can be uniquely identified over
                      time, even when users attempt to evade detection.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="subBoxContent" style={{ width: "45%" }}>
                <div className="howDescriptio">
                  <div className="headingHow1">
                    Real-Time Behavioral Monitoring & Analysis
                  </div>
                  <div>
                    <p>
                      Fstac incorporates behavioral monitoring to detect subtle
                      deviations in user behavior, it continuously tracks user
                      interactions, such as typing patterns, input pressure
                      patterns, navigation flow, and real-time user actions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-10 d-flex justify-content-between subBoxContentRow">
              <div className="subBoxContent" style={{ width: "45%" }}>
                <div className="howDescriptio">
                  <div className="headingHow1">
                    Contextual Awareness & Risk Scoring
                  </div>
                  <div>
                    <p>
                      Fstac’s contextual awareness capabilities assess real-time
                      environmental factors, providing an additional layer of
                      fraud prevention by examining the user’s network and device
                      context.
                    </p>
                  </div>
                </div>
              </div>
              <div className="subBoxContent" style={{ width: "45%" }}>
                <div className="howDescriptio">
                  <div className="headingHow1">Real-Time Anomaly Detection</div>
                  <div>
                    <p>
                      Fstac’s machine learning engine, anomaly detection models,
                      continuously evaluates data across multiple touchpoints in
                      real time, identifying potential fraud through advanced
                      analytics.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-5" id="smartSignal">
          <div className="row justify-content-center align-items-center pb-4">
            <div className="col-lg-10">
              <div className="signalHeading text-center">Smart Signals</div>
              <div>
                <p className="text-center" style={{ fontSize: "20px" }}>
                  Fstac’s Browser Intelligence incorporates a variety of smart
                  signals inspired by technologies like FingerprintJS to enhance
                  its threat detection capabilities
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center align-items-center pt-4">
            <div className="col-lg-10 d-flex  justify-content-between smartSignalRaw">
              <div className="singnalBox" style={{ width: "45%" }}>
                <div className="SingalDescriptio">
                  <div className="signalHeadingBox">Browser Signals</div>
                  <div>
                    <p>
                      Captures unique identifiers based on browser attributes,
                      including screen resolution, time zone, language settings,
                      and installed fonts, to create a unique user profile.
                    </p>
                  </div>
                </div>
              </div>
              <div className="singnalBox" style={{ width: "45%" }}>
                <div className="SingalDescriptio">
                  <div className="signalHeadingBox">Device Signals</div>
                  <div>
                    <p>
                      Analyzes device characteristics and network configurations,
                      such as device memory, and CPU information, to detect
                      inconsistencies and prevent spoofing.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-10 d-flex justify-content-between smartSignalRaw ">
              <div className="singnalBox" style={{ width: "45%" }}>
                <div className="SingalDescriptio">
                  <div className="signalHeadingBox">IP Signals</div>
                  <div>
                    <p>
                      Identifies IP addresses associated with a variety of threat
                      sources, including proxy, crawler, VPN, or bots and web
                      attacks.
                    </p>
                  </div>
                </div>
              </div>
              <div className="singnalBox" style={{ width: "45%" }}>
                <div className="SingalDescriptio">
                  <div className="signalHeadingBox">Behavioral Biometrics</div>
                  <div>
                    <p>
                      Tracks subtle user interactions, such as mouse movements and
                      keystroke dynamics, to build behavioral profiles and detect
                      automated or fraudulent activities.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="useCaseStudy container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-10">
              <div className="useCaseHeadig">
                <h2 className="text-center">Use Cases by Industry</h2>{" "}
              </div>
            </div>
          </div>
          <div className="row justify-content-center align-items-center pt-5">
            <div className="col-lg-10 pt-5">
              <div className="useCaseStudyPoint d-flex">
                <div className="img">
                  <img src={ecommerse} alt="" />
                </div>
                <div className="useBoxDescription">
                  <div className="useBoxHeading">
                    <h6>E-Commerce</h6>
                  </div>
                  <div>
                    <p style={{ paddingTop: "0px" }}>
                      In the fast-paced world of e-commerce, businesses face
                      persistent threats from fraudulent activities like fake
                      accounts, card-not-present fraud, and bot attacks. Fstac’s
                      device intelligence helps protect against these risks by
                    </p>
                  </div>
                  <div className="subBox d-flex pt-4">
                    <div className="useBoxchild">
                      <div className="childHeading">
                        Preventing Account Takeovers
                      </div>
                      <div className="subBoxDescription">
                        <p>
                          By continuously tracking user devices and behaviors,
                          Fstac identifies unauthorized attempts to access
                          customer accounts, even if login credentials are stolen.
                        </p>
                      </div>
                    </div>
                    <div className="useBoxchild">
                      <div className="childHeading">Blocking Fake Accounts</div>
                      <div className="subBoxDescription">
                        <p>
                          Using behavioral and device intelligence, Fstac can
                          detect attempts to create multiple accounts from the
                          same device or using fraudulent means to exploit
                          promotions.
                        </p>
                      </div>
                    </div>
                    <div className="useBoxchild">
                      <div className="childHeading">Protecting Payments</div>
                      <div className="subBoxDescription">
                        <p>
                          Real-time risk scoring helps identify suspicious
                          transactions, flagging high-risk payment activities such
                          as mismatched geolocation or proxy use.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-10 pt-5">
              <div className="useCaseStudyPoint1 d-flex">
                <div className="img">
                  <img src={financialService} alt="" />
                </div>
                <div className="useBoxDescription">
                  <div className="useBoxHeading">
                    <h6>Financial Services & Banking Security</h6>
                  </div>
                  <div>
                    <p style={{ paddingTop: "0px" }}>
                      The financial services sector is heavily targeted by
                      cybercriminals, making robust fraud prevention crucial.
                      Fstac enables secure access to financial accounts and
                      safeguards sensitive transactions by:
                    </p>
                  </div>
                  <div className="subBox d-flex pt-4">
                    <div className="useBoxchild">
                      <div className="childHeading">Securing Account Logins</div>
                      <div className="subBoxDescription">
                        <p>
                          Device fingerprinting ensures that only recognized
                          devices can access sensitive financial accounts.
                          Suspicious device behavior, such as logging in from new
                          locations or devices, triggers additional security
                          measures.
                        </p>
                      </div>
                    </div>
                    <div className="useBoxchild">
                      <div className="childHeading">
                        Detecting Suspicious Transactions
                      </div>
                      <div className="subBoxDescription">
                        <p>
                          Fstac analyzes transaction patterns in real-time,
                          looking for deviations from normal user behavior, such
                          as unusual amounts, time zones, or device usage.
                          High-risk transactions can be halted or flagged for
                          further review.
                        </p>
                      </div>
                    </div>
                    <div className="useBoxchild">
                      <div className="childHeading">
                        Mitigating Phishing Attacks
                      </div>
                      <div className="subBoxDescription">
                        <p>
                          By monitoring behavioral biometrics and user interaction
                          signals (e.g., typing speeds, mouse movements), Fstac
                          identifies phishing attempts where fraudsters attempt to
                          mimic legitimate users.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-10 pt-5">
              <div className="useCaseStudyPoint d-flex">
                <div className="img">
                  <img src={onlineGaming} alt="" />
                </div>
                <div className="useBoxDescription">
                  <div className="useBoxHeading">
                    <h6>Online Gaming & Entertainment Fraud Detection</h6>
                  </div>
                  <div>
                    <p style={{ paddingTop: "0px" }}>
                      The gaming and online entertainment industries are
                      vulnerable to various types of fraud, such as bot-driven
                      account creation, in-game cheating, and payment fraud. Fstac
                      provides comprehensive protection by:
                    </p>
                  </div>
                  <div className="subBox d-flex pt-4">
                    <div className="useBoxchild">
                      <div className="childHeading">Preventing Account Theft</div>
                      <div className="subBoxDescription">
                        <p>
                          Fstac continuously monitors device integrity and
                          behavioral patterns to detect and block unauthorized
                          attempts to access gaming accounts, preserving user
                          progress and protecting in-game assets.
                        </p>
                      </div>
                    </div>
                    <div className="useBoxchild">
                      <div className="childHeading">Bot Detection</div>
                      <div className="subBoxDescription">
                        <p>
                          Using behavioral biometrics and interaction signals,
                          Fstac identifies automated scripts and bots attempting
                          to cheat in games, ensuring fair play and a better user
                          experience.
                        </p>
                      </div>
                    </div>
                    <div className="useBoxchild">
                      <div className="childHeading">Preventing In-Game Fraud</div>
                      <div className="subBoxDescription">
                        <p>
                          By analyzing transaction data and device behavior, Fstac
                          can detect unusual in-game transactions (e.g., item
                          trading, currency purchases), which may indicate account
                          abuse or fraud.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
  );
};
