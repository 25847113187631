import React from "react";
import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import "../styles/paymentStack.css";
import upiPayment from "../assets/images/upiPayment.svg";
import arrowUp from "../assets/images/arrow-up.svg";
import upicollectflow from "../assets/images/upicollectflow.svg";
import { UpiCollectFlow } from "./upiCollectFlow";
import payoutsolution from "../assets/images/payoutssolution.svg";



const PaymentStack = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="container-fluid paymentM">
      <div className="row justify-content-center text-center align-items-center">
        <div className="col-lg-8">
          <div className="heading">
            <h2>Payments Stack</h2>
            <p>
              Fstac’s Payment Gateway offers a secure, reliable, and efficient
              way to process transactions. Our platform supports various payment
              methods and ensures real-time processing with advanced fraud
              detection mechanisms
            </p>
          </div>
        </div>
      </div>
      <div className="row justify-content-center text-center align-items-center">
        <div className="col-lg-8 parentBoxes">
          <Slider {...settings} className="payment-slider">
            <div className="sliderCardX ">
              <div className="sliderImage">
                <img className="upiimg" src={upiPayment} alt="UPI Payment" />
              </div>
              <div className="sliderHeading">
                <h6>UPI Intent Flow</h6>
              </div>
              <div className="sliderDescription">
                <p>
                Fstac enables seamless UPI Intent Flow, allowing customers to make quick, secure payments directly from their bank accounts via UPI apps.
                </p>
              </div>
              <div className="d-flex justify-content-center align-items-center buttonBox">
                <div className="buttonTypeBox">
                  {/* <img src={arrowUp} alt="" /> */}
                  <div className="name">Seamless Integration</div>
                </div>
                <div className="buttonTypeBox">
                  {/* <img src={arrowUp} alt="" /> */}
                  <div className="name">Real-Time Processing</div>
                </div>
                <div className="buttonTypeBox">
                  {/* <img src={arrowUp} alt="" /> */}
                  <div className="name">User-Friendly</div>
                </div>
                <div className="buttonTypeBox">
                  {/* <img src={arrowUp} alt="" /> */}
                  <div className="name">Enhanced Security</div>
                </div>
              </div>
            </div>

            <div className="sliderCard2">
              <div className="sliderImage">
                <img className="upiimg" src={upicollectflow} alt="UPI Payment" />
              </div>
              <div className="sliderHeading">
                <h6>UPI Collect Flow</h6>
              </div>
              <div className="sliderDescription">
                <p>
                Enable quick and secure payment requests with UPI Collect, allowing customers to authorize transactions instantly from their mobile devices
                </p>
              </div>
              <div className="d-flex justify-content-center align-items-center buttonBox">
                <div className="buttonTypeBox">
                  {/* <img src={arrowUp} alt="" /> */}
                  <div className="name">Instant Payments</div>
                </div>
                <div className="buttonTypeBox">
                  {/* <img src={arrowUp} alt="" /> */}
                  <div className="name">High Compatibility</div>
                </div>
                <div className="buttonTypeBox">
                  {/* <img src={arrowUp} alt="" /> */}
                  <div className="name">Secure Transactions</div>
                </div>
                <div className="buttonTypeBox">
                  {/* <img src={arrowUp} alt="" /> */}
                  <div className="name">Customizable</div>
                </div>
              </div>
            </div>

            <div className="sliderCard3">
              <div className="sliderImage">
                <img className="upiimg" src={payoutsolution} alt="UPI Payment" />
              </div>
              <div className="sliderHeading">
                <h6>Payout Solutions</h6>
              </div>
              <div className="sliderDescription">
                <p>
                Send & manage automated payouts to vendors, partners, customers & employees via IMPS, RTGS, NEFT or UPI 
                </p>
              </div>
              <div className="d-flex justify-content-center align-items-center buttonBox">
                <div className="buttonTypeBox">
                  {/* <img src={arrowUp} alt="" /> */}
                  <div className="name">Instant Transfers</div>
                </div>
                <div className="buttonTypeBox">
                  {/* <img src={arrowUp} alt="" /> */}
                  <div className="name">24/7 Availability</div>
                </div>
                <div className="buttonTypeBox">
                  {/* <img src={arrowUp} alt="" /> */}
                  <div className="name">Bulk payouts</div>
                </div>
                <div className="buttonTypeBox">
                  {/* <img src={arrowUp} alt="" /> */}
                  <div className="name">Secure Transactions</div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default PaymentStack;
