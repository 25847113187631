import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/happyUserSlider.css";
import circle from "../assets/images/circle.png";
import  ssi from "../assets/images/sisidentity.svg";
import browserInteli from "../assets/images/browserInteli.svg";
import payupi from "../assets/images/payupi.svg";
import kyc from "../assets/images/kyc.svg";
import incognito from "../assets/images/incognito.svg";
import { Link } from 'react-router-dom';

export const SmartProtectionSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enables auto scroll
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 991, // Tablet and smaller
        settings: {
          slidesToShow: 2, // Show 2 slides on tablet
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // Mobile and smaller
        settings: {
          slidesToShow: 1, // Show 1 slide on mobile
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="slider-container">
      <Slider {...settings} className="smartProtection-slider">
        <div className="sliderCard1">
          <div className="section">
            <div className="content" style={{ padding: "40px" }}>
            <Link to="/fraudwatch" style={{ textDecoration: "none", color: "inherit" }}>
              <img className="img1" src={incognito} alt="" />
              <h2>Fraud Watch</h2>
              <p style={{padding:"0px"}}>
              Fraud Watch detects and prevents fraud by monitoring transactions, verifying identities, and flagging suspicious activities, ensuring user and business security.
              </p>
              <img className="img2" src={incognito} alt="" />
              </Link>
            </div>
          </div>
        </div>

        <div className="sliderCard1">
          <div className="section">
            <div className="content" style={{ padding: "40px" }}>
            <Link to="/ssi" style={{ textDecoration: "none", color: "inherit" }}>
              <img className="img1" src={ssi} alt="" />
              <h2>Self Sovereign Identity</h2>
              <p style={{padding:"0px"}}>
              SSI enables individuals to own and control their digital identities using decentralized technologies 
              like blockchain,enhancing privacy, security, and trust.
              </p>
              <img className="img2" src={ssi} alt="" />
            </Link>
            </div>
          </div>
        </div>
        <div className="sliderCard1">
          <div className="section">
            <div className="content" style={{ padding: "40px" }}>
            <Link to="/payments" style={{ textDecoration: "none", color: "inherit" }}>
              <img className="img1" src={payupi} alt="" />
              <h2>Payments</h2>
              <p style={{padding:"0px"}}>
              UPI revolutionizes digital transactions by enabling instant, secure, and seamless fund transfers between bank accounts via mobile devices.
              </p>
              <img className="img2" src={payupi} alt="" />
            </Link>
            </div>
          </div>
        </div>
        <div className="sliderCard1">
          <div className="section">
            <div className="content" style={{ padding: "40px" }}>
            <Link to="/onboarding" style={{ textDecoration: "none", color: "inherit" }}>
              <img className="img1" src={kyc} alt="" />
              <h2>Onboarding</h2>
              <p style={{padding:"0px"}}>
              Onboarding KYC streamlines user verification by collecting and validating identity documents, ensuring compliance, reducing fraud, and enhancing customer trust efficiently.
              </p>
              <img className="img2" src={kyc} alt="" />
              </Link>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
};
