import React, { useState, FC } from "react";
import "../styles/vpnDetection.css";

type ProductName = "Nike Sport" | "Nike Air";

interface ProductQuantities {
  "Nike Sport": number;
  "Nike Air": number;
}

interface ProductPrices {
  "Nike Sport": number;
  "Nike Air": number;
}

const VpnDetection: FC = () => {
  const [quantities, setQuantities] = useState<ProductQuantities>({
    "Nike Sport": 1,
    "Nike Air": 2,
  });
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

  const prices: ProductPrices = {
    "Nike Sport": 19.99,
    "Nike Air": 39.99,
  };

  const productImages: Record<ProductName, string> = {
    "Nike Sport": `https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco/79d2cede-230e-4742-8254-f39d80b9cfe5/GSW+MNK+DF+SWGMN+JSY+HWC+24.png`, // Replace with the correct Nike Sport image URL
    "Nike Air": `https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco/a0694e6b-b6b3-479c-851d-d64af01d1784/MEM+MNK+DF+SWGMN+JSY+HWC+24.png`, // Replace with the correct Nike Air image URL
  };

  const handleQuantityChange = (product: ProductName, delta: number): void => {
    setQuantities((prev) => ({
      ...prev,
      [product]: Math.max(0, prev[product] + delta),
    }));
  };

  const subtotal: number = Object.entries(quantities).reduce(
    (sum, [product, quantity]) =>
      sum + prices[product as ProductName] * quantity,
    0
  );
  const tax: number = subtotal * 0.1;
  const total: number = subtotal + tax;

  const handleRefresh = (): void => {
    setIsRefreshing(true);
    setTimeout(() => {
      setQuantities({
        "Nike Sport": 1,
        "Nike Air": 2,
      });
      setIsRefreshing(false);
    }, 1000); // Simulate a refresh delay
  };

  return (
    <div className="pro-container">
      <div className="fingerprint-container">
        <div className="fingerprint-ID">
          fingerprintId : k5pL1JnOOCo8THVwXDZIi0yOL7QU0C4dHwdWn6Rz1EA=
        </div>
      </div>
      <div className="section1">
        <div className="header">
          <div>
            <h1 className="H-title">VPN Detection</h1>
            <p className="subtitle">
              VPN Detection identifies users spoofing their location with VPNs,
              allowing you to block access to regional discounts or restricted
              content and prevent location-based fraud.
            </p>
          </div>
          <button
            className="refresh-btn"
            onClick={handleRefresh}
            type="button"
            disabled={isRefreshing}
          >
            {isRefreshing ? (
              <div className="spinner"></div> // Spinner element
            ) : (
              "Refresh"
            )}
          </button>
        </div>

        <div className="instructions">
          <h2>How to use this demo</h2>
          <ul>
            <li>
              Click Activate regional pricing in the checkout form below.
              Assuming your VPN is off, you will get a location-based discount.
            </li>
            <li>Turn on your VPN and pick an exit node different from your true location.</li>
            <li>
              Try activating the discount again. You will not get the discount
              while using a VPN.
            </li>
          </ul>
        </div>

        <div className="product-list">
          {(Object.entries(quantities) as [ProductName, number][]).map(
            ([product, quantity]) => (
              <div key={product} className="product-item">
                <div className="product-info">
                  <div className="product-image">
                    <img src={productImages[product]} alt={product} />
                  </div>
                  <div>
                    <h3>{product}</h3>
                    <p>Price: £{prices[product].toFixed(2)}</p>
                  </div>
                </div>
                <div className="quantity-controls">
                  <button
                    className="quantity-btn"
                    onClick={() => handleQuantityChange(product, -1)}
                    type="button"
                    aria-label="Decrease quantity"
                  >
                    -
                  </button>
                  <span>{quantity}</span>
                  <button
                    className="quantity-btn"
                    onClick={() => handleQuantityChange(product, 1)}
                    type="button"
                    aria-label="Increase quantity"
                  >
                    +
                  </button>
                </div>
              </div>
            )
          )}
        </div>

        <div className="totals">
          <p>Subtotal: £{subtotal.toFixed(2)}</p>
          <p>Tax (10%): £{tax.toFixed(2)}</p>
          <p className="total">Total: £{total.toFixed(2)}</p>
        </div>
      </div>

      <div className="section1">
        <div className="promo-section">
          <h3 className="promo-title">
            We noticed that you are from <span className="country-flag">🇮🇳 India</span>. 
            We are offering purchasing power parity pricing.
          </h3>
          <button className="activate-btn" type="button">
            Activate 70% off with regional pricing
          </button>
        </div>
      </div>
    </div>
  );
};

export default VpnDetection;
