import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';
import { HelmetProvider } from 'react-helmet-async'; // Import HelmetProvider
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga4';

// Google Analytics Tracking ID
const GA_TRACKING_ID = 'G-XB230962XM';

const RootComponent = () => {
  useEffect(() => {
    // Initialize Google Analytics with react-gtag
    ReactGA.initialize(GA_TRACKING_ID);

    // Trigger a manual page view event for the initial page load
    ReactGA.event('page_view', {
      page_path: window.location.pathname + window.location.search,
      page_title: document.title,
    });
  }, []);

  return (
    <HelmetProvider>
      <App />
    </HelmetProvider>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RootComponent />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
