import { useEffect, useRef } from "react";
import "../styles/description.css";
import eggGloabl from "../assets/images/eggGlobal.png";
import upiintentflow from "../assets/images/upiintentflow.png";
import upicollectfloww from "../assets/images/upicollectfloww.png";
import payouts from "../assets/images/payouts.png";
import simplified from "../assets/images/simplified.svg";
import wideAdoption from "../assets/images/wideAdoption.svg";
import instantInsight from "../assets/images/instantInsight.svg";
import crossDevice from "../assets/images/crossDevice.svg";
import ecommerse from "../assets/images/eCommerceB.svg";
import bankingB from "../assets/images/bankingB.svg";
import onlineB from "../assets/images/onlineGamingB.svg";
import streamingB from "../assets/images/streamingServiceB.svg";
import travelB from "../assets/images/travelB.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export const Payments = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <><Helmet> <meta charSet="utf-8" />
      <title>Payments | Fstac - Get in Touch</title>
      <meta name="Explore seamless device integration and secure payment solutions. Enhance user experience with innovative technology, fast transactions, mobile compatibility, and reliable data encryption. Optimize your digital journey today!" />
      <link rel="canonical" href="https://www.fstac.tech/payments" /></Helmet><div className="singlePage">
        <div className="container ">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-12 d-flex justify-content-center">
              <div className="container1">
                <div className="outer-circle">
                  <div className="inner-circle">
                    <div className="content">
                      <div className="contentHeading circle_header">Payments</div>
                      <div className="description">
                        <p>
                        Unified Payments Interface (UPI) is a fast, secure, and efficient real-time payment system that facilitates instant bank-to-bank transfers through mobile devices. UPI simplifies digital transactions by allowing users to link multiple bank accounts to a single platform, enhancing convenience.
                         <br></br>It supports both peer-to-peer and merchant payments, making it a versatile solution for everyday transactions. With low transaction fees, robust security features like multi-factor authentication, and wide adoption, UPI is transforming digital payments in India, promoting financial inclusion and a cashless economy.
                        </p>
                      </div>
                    </div>
                    <div className="outerCircleIcon1">
                      <img src={eggGloabl} alt="" />
                    </div>
                    <div className="outerCircleIcon2">
                      <img src={eggGloabl} alt="" />
                    </div>
                  </div>
                  <div className="outerCircleIcon">
                    <img src={eggGloabl} alt="" />
                  </div>
                </div>
                <div className="outerBox"></div>
                <div className="content_mobileView">
                  <div>
                    <button>Payments</button>
                  </div>
                  <h3>Payments</h3>
                  <div className="contentHeading circle_header">
                    {/* Device & Browser Intelligence */}
                    <p>
                        Unified Payments Interface (UPI) is a fast, secure, and efficient real-time payment system that facilitates instant bank-to-bank transfers through mobile devices. UPI simplifies digital transactions by allowing users to link multiple bank accounts to a single platform, enhancing convenience.
                         <br></br>It supports both peer-to-peer and merchant payments, making it a versatile solution for everyday transactions. With low transaction fees, robust security features like multi-factor authentication, and wide adoption, UPI is transforming digital payments in India, promoting financial inclusion and a cashless economy.
                        </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container howItWork" style={{ position: "relative", marginTop:"10px" }}>
          <div className="fraudContainer">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-10 d-flex justify-content-center">
              <div className="box-content" style={{ width: "100%", textAlign: "center" }}>
                <div className="contentHowitWork">
                  <div className="itwork">Use Cases</div>
                  <p>
                  Unified Payments Interface (UPI) is an advanced real-time payment system that facilitates instant, seamless bank-to-bank transfers through mobile devices. UPI enables users to send and receive money directly between bank accounts, without the need for intermediaries.
                   <br></br>The system supports a variety of payment methods, including virtual payment addresses (VPAs), QR codes, and direct account details, making it a versatile solution for digital transactions. Security is a key feature of UPI, with multi-factor authentication and encryption ensuring safe and secure transactions. UPI has been widely adopted across India, offering users a reliable, efficient way to manage their finances directly from their smartphones.
                  <br></br>
                  The true strength of UPI lies in its interoperability and convenience. It allows users to link multiple bank accounts to a single mobile application, simplifying the process of managing different accounts in one place. With low transaction costs and rapid payment processing, 
                  <br></br>UPI encourages a shift towards cashless transactions, contributing significantly to financial inclusion. As UPI continues to expand, it is transforming the digital payment ecosystem in India, making digital financial services more accessible to millions of people, especially in rural areas. Its adoption by both consumers and businesses alike positions UPI as a key driver of India's push toward a cashless economy.
                  </p>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>

        {/* Floating carousel section */}
        <div className="container-fluid floatingCarousel">
          <Slider {...settings} className="payment-slider">
            <div className="sliderItemContainer">
              <div className="sliderItem" style={{ backgroundColor: "#e3dfff", height:"505px" }}>
                <img src={upiintentflow} alt="UPI Intent Flow" />
                <h6>UPI Intent Flow</h6>
                <p>UPI Intent Flow enables seamless payments by redirecting users to UPI apps for secure, real-time transactions directly from their mobile devices.</p>
                <Link to="/upiIntentFlow">
                  <button>Learn More</button>
                </Link>
              </div>
            </div>
            <div className="sliderItemContainer">
              <div className="sliderItem" style={{ backgroundColor: "#eeffdb" }}>
                <img src={upicollectfloww} alt="UPI Collect Flow" />
                <h6>UPI Collect Flow</h6>
                <p>UPI Collect Flow allows merchants to send payment requests, enabling customers to authorize transactions instantly via UPI-enabled apps.</p>
                <Link to="/upiCollectFlow">
                  <button>Learn More</button>
                </Link>
              </div>
            </div>
            <div className="sliderItemContainer">
              <div className="sliderItem" style={{ backgroundColor: "#b8e1ff" }}>
                <img src={payouts} alt="Payouts" />
                <h6>Payouts</h6>
                <p>Payouts enable businesses to send automated, secure payments to vendors, partners, or employees through methods like UPI, NEFT, or IMPS.</p>
                <Link to="/payouts">
                  <button>Learn More</button>
                </Link>
              </div>
            </div>
          </Slider>
        </div>
      </div></>
  );
};
