import React from 'react';
import seoimg from "../assets/images/riskfraud.png";
import "../styles/seo.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { hover } from '@testing-library/user-event/dist/hover';

export const Seo = () => {
  const navigate = useNavigate();
  return (
    <div className="seo-container">
      <h2 className="seo-heading">
        Leading Provider of Fraud Detection and Payment Security Solutions
      </h2>
      <img src={seoimg} alt="SEO Image" className="seo-image" />
      <p className="seo-paragraph">
        Fstac is a leading provider in the fintech space. Our cutting-edge products include solutions for fraud detection, fraud prevention, behavioral intelligence, device intelligence, and payment security.
        <br></br>
        <br></br>
        Our mission is to create a platform that integrates the best of fraud monitoring, risk management, and seamless payment flows, enabling businesses to operate securely in a digital-first world. 
    
        Fstac was founded with a mission to revolutionize the fintech space by providing cutting-edge solutions for fraud prevention, behavioral intelligence, and payment security.
        <br></br>
        <br></br>
        We also offer customer KYC solutions, IP intelligence, browser intelligence, and much more. Talk to our experts today to understand how we can help you secure your business while allowing you to focus on everything else.
      </p>
      {/* <div className="text-center">
            {location.pathname !== "/contact-us" && (
              <button
                type="button"
                className="contactus btn"
                onClick={() => navigate("/contact-us")}
                style={{backgroundColor:"#175fc9", color:"#fff"}}
              
              >
                Talk to us today!
                
              </button>
            )}
          </div> */}
          <div className="text-center">
  {location.pathname !== "/contact-us" && (
    <button
      type="button"
      className="contactus btn"
      onClick={() => navigate("/contact-us")}
      style={{
        backgroundColor: "#175fc9",
        color: "#fff",
        border: "none",
        transition: "all 0.3s ease",
      }}
      onMouseEnter={(e) => {
        const target = e.target as HTMLButtonElement;
        target.style.backgroundColor = "#fff";
        target.style.color = "#175fc9";
      }}
      onMouseLeave={(e) => {
        const target = e.target as HTMLButtonElement;
        target.style.backgroundColor = "#175fc9";
        target.style.color = "#fff";
      }}
    >
      Talk to us today!
    </button>
  )}
</div>

    </div>
  );
};
