import "../styles/contact.css";
import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ThankYouModal } from "./thankyou";
import logo from "../assets/images/logo.svg";
import { Helmet } from "react-helmet";

interface ContactFormValues {
  firstName: string;
  lastName: string;
  email: string;
  contactNumber: string;
  interest: string[];
  description: string;
  termsAccepted: boolean;
}

// Define the validation schema using Yup
const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "First name must be at least 2 characters.")
    .max(50, "First name can't exceed 50 characters.")
    .matches(/^[A-Za-z]+$/, "First name must not contain numbers or symbols.")
    .required("First name is required."),
  lastName: Yup.string()
    .min(2, "Last name must be at least 2 characters.")
    .max(50, "Last name can't exceed 50 characters.")
    .matches(/^[A-Za-z]+$/, "Last name must not contain numbers or symbols.")
    .required("Last name is required.")
    .notOneOf([Yup.ref("firstName")], "First name and last name cannot be the same."),
  email: Yup.string()
    .email("Invalid email format.")
    .matches(/^[^@\s]+@fstac\.tech$/, "Invalid email address!")
    .required("Email is required."),
  contactNumber: Yup.string()
    .matches(/^[+0-9]{7,15}$/, "Contact number must include valid digits and may include a country code.")
    .required("Contact number is required."),
  interest: Yup.array().min(1, "Please select at least one option."),
  description: Yup.string()
    .max(500, "Description must not exceed 500 characters.")
    .optional(),
  termsAccepted: Yup.boolean().oneOf(
    [true],
    "You must accept the terms and conditions."
  ),
});

export const Contact: React.FC = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const initialValues: ContactFormValues = {
    firstName: "",
    lastName: "",
    email: "",
    contactNumber: "",
    interest: [],
    description: "",
    termsAccepted: false,
  };

  const handleSubmit = async (values: ContactFormValues) => {
    const payload = { ...values };

    try {
      const response = await fetch("https://prod.fstac.tech/id/website/serviceleads", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        setModalOpen(true);
      } else {
        console.error("Failed to submit form.");
      }
    } catch (error) {
      console.error("Error occurred while submitting form:", error);
    }
  };

  const closeModal = (resetForm: () => void) => {
    setModalOpen(false);
    resetForm();
  };

  return (
    <div id="contactUs">
      <Helmet>
        <title>Contact Us | Fstac</title>
        <meta
          name="description"
          content="Contact FSTAC for KYC, payment gateway, identity verification, and fraud prevention services. Reach out today to secure your future."
        />
        <link rel="canonical" href="https://www.fstac.tech/contact" />
      </Helmet>
      <div className="container">
        <div className="contactUs">
          <div className="row justify-content-center align-items-center pb-5">
            <div className="col-lg-6">
              <div className="contactHeading">
                <h5 className="text-center">Contact Us</h5>
                <h2 className="text-center">Get in Touch</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-9">
              <div className="contactUsForm">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ resetForm, isValid, dirty, setFieldValue, values }) => (
                    <Form className="p-5">
                      <div className="form-group">
                        <label htmlFor="firstName">First Name
                        <span className="required">*</span>
                        </label>
                        <Field
                          type="text"
                          id="firstName"
                          name="firstName"
                          className="form-control"
                          placeholder="Enter your first name"
                        />
                        <ErrorMessage
                          name="firstName"
                          component="div"
                          className="error"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="lastName">Last Name
                        <span className="required">*</span>
                        </label>
                        <Field
                          type="text"
                          id="lastName"
                          name="lastName"
                          className="form-control"
                          placeholder="Enter your last name"
                        />
                        <ErrorMessage
                          name="lastName"
                          component="div"
                          className="error"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="email">Company Email
                        <span className="required">*</span>
                        </label>
                        <Field
                          type="email"
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Enter company email"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="error"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="contactNumber">Contact Number
                        <span className="required">*</span>
                        </label>
                        <Field
                          type="text"
                          id="contactNumber"
                          name="contactNumber"
                          className="form-control"
                          placeholder="Enter your contact number"
                        />
                        <ErrorMessage
                          name="contactNumber"
                          component="div"
                          className="error"
                        />
                      </div>
                      <div className="form-group">
                        <label>What are you interested in?
                        <span className="required">*</span>
                        </label>
                        <div>
                          <Field
                            type="checkbox"
                            name="interest"
                            value="KYC Services"
                            className="form-check-input"
                            onChange={() => {
                              const newInterest = [...values.interest];
                              if (newInterest.includes("KYC Services")) {
                                newInterest.splice(newInterest.indexOf("KYC Services"), 1);
                              } else {
                                newInterest.push("KYC Services");
                              }
                              setFieldValue("interest", newInterest);
                            }}
                            checked={values.interest.includes("KYC Services")}
                          />
                          <label className="form-check-label" htmlFor="KYC Services">
                            KYC Services
                          </label>
                        </div>
                        <div>
                          <Field
                            type="checkbox"
                            name="interest"
                            value="Payment Gateway Services"
                            className="form-check-input"
                            onChange={() => {
                              const newInterest = [...values.interest];
                              if (newInterest.includes("Payment Gateway Services")) {
                                newInterest.splice(newInterest.indexOf("Payment Gateway Services"), 1);
                              } else {
                                newInterest.push("Payment Gateway Services");
                              }
                              setFieldValue("interest", newInterest);
                            }}
                            checked={values.interest.includes("Payment Gateway Services")}
                          />
                          <label className="form-check-label" htmlFor="Payment Gateway Services">
                            Payment Gateway Services
                          </label>
                        </div>
                        <div>
                          <Field
                            type="checkbox"
                            name="interest"
                            value="Identity Verification Services"
                            className="form-check-input"
                            onChange={() => {
                              const newInterest = [...values.interest];
                              if (newInterest.includes("Identity Verification Services")) {
                                newInterest.splice(newInterest.indexOf("Identity Verification Services"), 1);
                              } else {
                                newInterest.push("Identity Verification Services");
                              }
                              setFieldValue("interest", newInterest);
                            }}
                            checked={values.interest.includes("Identity Verification Services")}
                          />
                          <label className="form-check-label" htmlFor="Identity Verification Services">
                            Identity Verification Services
                          </label>
                        </div>
                        <div>
                          <Field
                            type="checkbox"
                            name="interest"
                            value="Fraud Prevention Services"
                            className="form-check-input"
                            onChange={() => {
                              const newInterest = [...values.interest];
                              if (newInterest.includes("Fraud Prevention Services")) {
                                newInterest.splice(newInterest.indexOf("Fraud Prevention Services"), 1);
                              } else {
                                newInterest.push("Fraud Prevention Services");
                              }
                              setFieldValue("interest", newInterest);
                            }}
                            checked={values.interest.includes("Fraud Prevention Services")}
                          />
                          <label className="form-check-label" htmlFor="Fraud Prevention Services">
                            Fraud Prevention Services
                          </label>
                        </div>
                        <ErrorMessage name="interest" component="div" className="error" />
                      </div>

                      <div className="form-group">
                        <label htmlFor="description">Brief Description</label>
                        <Field
                          as="textarea"
                          id="description"
                          name="description"
                          className="form-control"
                          placeholder="Brief description of your requirements (optional, max 500 characters)"
                          maxLength={500}
                        />
                        <ErrorMessage
                          name="description"
                          component="div"
                          className="error"
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          <Field
                            type="checkbox"
                            name="termsAccepted"
                            className="mr-2"
                          />
                          By submitting this form, you agree to our{" "}
                          <a href="/termAndCondition" className="tnclink">
                            Terms & Conditions
                          </a>
                          .
                        </label>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={!(isValid && dirty)}
                      >
                        Submit
                      </button>
                      <ThankYouModal
                        isOpen={isModalOpen}
                        onClose={() => closeModal(resetForm)}
                      />
                    </Form>
                  )}
                </Formik>
              </div>
              <div className="contactInfo mt-5">
                <h4>Contact Us</h4>
                <img src={logo} alt="Fstac" />
                <p>
                  <strong>Address:</strong> Shri Krishna Temple Rd, Indira Nagar
                  1st Stage, Stage 1, Indiranagar, Bengaluru, Karnataka 560038
                </p>
                <p>
                  <strong>Email:</strong>{" "}
                  <a href="mailto:sales@fstac.tech">sales@fstac.tech</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
